import './SelectInput.scss';

import React, { PureComponent } from 'react';
import ReactSelect from 'react-select';
import VirtualizedSelect from 'react-virtualized-select';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { List, Set } from 'immutable';
import { isObject, omit } from 'lodash';
import PropTypes from 'prop-types';
import { colors } from 'theme/constants';

import Avatar from 'components/Avatar';
import Box from 'components/Box';
import Text from 'components/Text';

import cxHelpers from 'util/className';
import { toI18n } from 'util/i18n';

export const INPUT_SIZES = ['narrow', 'medium'];

export const THEME_TYPES = [
  'secondary',
  'minimal',
  'mui',
  'mui-standalone',
  'mui-dark',
  'mui-dark-bold',
  'onboarding',
  'onboarding-red',
  'bordered',
];

export const TYPES = ['avatarUsers'];

@cxHelpers('SelectInput')
export default class SelectInput extends PureComponent {
  static propTypes = {
    size: PropTypes.oneOf(INPUT_SIZES),
    theme: PropTypes.oneOf(THEME_TYPES),
    creatable: PropTypes.bool,
    newOptionText: PropTypes.func,
    options: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)]),
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onInputChange: PropTypes.func,
    getRef: PropTypes.func,
    multi: PropTypes.bool,
    selectAll: PropTypes.bool,
    selectAllOption: PropTypes.string,
    type: PropTypes.oneOf(TYPES),
    virtualized: PropTypes.bool,
    closeOnSelect: PropTypes.bool,
    fullPayload: PropTypes.bool,
    hideArrow: PropTypes.bool,
    hideClear: PropTypes.bool,
    style: PropTypes.object,
    tabIndex: PropTypes.string,
    // Pass-through: https://github.com/JedWatson/react-select#usage
    isCustomValueApplied: PropTypes.bool,
    setIsCustomValueApplied: PropTypes.func,
    customValue: PropTypes.string,
    formikFieldHelpers: PropTypes.object,
    errorBorder: PropTypes.bool,
    disabled: PropTypes.bool,
    searchable: PropTypes.bool,
    inputProps: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      options: this.formatOptions(props.options),
    };
  }

  // TODO: https://joinhomebase.atlassian.net/browse/HIRING-441
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.options !== this.props.options) {
      this.setState({ options: this.formatOptions(nextProps.options) });
    }
  }

  componentDidUpdate() {
    if (
      this.props.isCustomValueApplied &&
      this.props.customValue &&
      this.props.formikFieldHelpers
    ) {
      this.props.formikFieldHelpers.setValue(this.props.customValue);
      this.props.setIsCustomValueApplied(false);
    }
  }

  formatOptions = options => {
    if (!options) {
      return [];
    }
    if (options instanceof List) {
      options = options.toJS();
    }

    // Supports an array/List of values, which are transformed to the option objects React-Select
    // expects in this block.
    options = options.map(value =>
      isObject(value) ? value : { label: value, value }
    );

    if (this.props.selectAll && options.length > 0) {
      options.unshift({
        label: this.props.selectAllOption || toI18n('all'),
        value: 'all',
      });
    }

    return options;
  };

  handleOpen = () => {
    this.setState({ open: true });
    if (this.props.onOpen) {
      this.props.onOpen();
    }
  };

  handleClose = () => {
    this.setState({ open: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  handleChange = payload => {
    let finalPayload;

    if (!payload) {
      finalPayload = this.props.multi ? [] : '';
    } else if (payload.constructor === Array) {
      // For multi-select
      if (payload.find(option => option.value === 'all')) {
        payload = this.props.options;
      }

      finalPayload = payload.map(option => option.value);
    } else {
      finalPayload = this.props.fullPayload ? payload : payload.value;
    }

    // Create a fake event so it works with Formik
    const event = {
      target: {
        value: finalPayload,
        name: this.props.name,
      },
    };

    this.props.onChange(finalPayload, event);
  };

  handleInputChange = value => {
    if (this.props.onInputChange) {
      this.props.onInputChange(value);
    }
  };

  getCreatableValue = value => {
    if (!value || this.props.multi) {
      return value;
    }

    // If value is represented in the current options, just return that option.
    const currentOption = this.props.options.find(
      option => option.value === value
    );
    if (currentOption) {
      return currentOption;
    }

    // If value is truthy but not contained in the options, it must be new.
    // Pass as an option object to satisfy react-select's Creatable value api.
    // Ref: https://github.com/JedWatson/react-select/issues/828
    return { value, label: value };
  };

  handleRef = input => {
    this.input = input;
    if (this.props.getRef) {
      this.props.getRef(input);
    }
  };

  handleNewOptionClick = option => {
    if (this.props.onNewOptionClick) {
      this.props.onNewOptionClick(option);
    }
    this.handleChange(option);

    // Solves a known issue in which the input doesn't close when using `onNewOptionClick`
    // https://stackoverflow.com/questions/44549186/how-to-close-menu-onnewoptionclick
    this.input.select.closeMenu();
  };

  renderAvatarUserContent = (
    // eslint-disable-next-line react/prop-types
    { value, label, isPlaceholder },
    boxProps = {}
  ) => {
    if (value === 'all') {
      return (
        <Box row vcenter phxs {...boxProps}>
          {label}
        </Box>
      );
    }

    const { name, avatar } = JSON.parse(label);
    const text = <Text color={boxProps.color}>{name}</Text>;

    return avatar ? (
      <Box
        row
        vcenter
        phxs={isPlaceholder}
        plm={!isPlaceholder}
        prxs={!isPlaceholder}
        {...boxProps}
      >
        <Avatar url={avatar} />
        <Box mlxxs>{text}</Box>
      </Box>
    ) : (
      <Box
        row
        vcenter
        phxs={isPlaceholder}
        plm={!isPlaceholder}
        prxs={!isPlaceholder}
        {...boxProps}
      >
        <Text color={boxProps.color}>{name}</Text>
      </Box>
    );
  };

  renderAvatarValueContent = ({ value, label }, boxProps = {}) =>
    this.renderAvatarUserContent({ value, label, placeholder: true }, boxProps);

  renderAvatarUserOption = ({
    option,
    key,
    selectValue,
    focusOption,
    focusedOption,
    style,
    valueArray,
  }) => {
    const values = valueArray.map(item => item.value);

    return this.renderAvatarUserContent(option, {
      color: values.includes(option.value) ? 'purpleDark' : 'black',
      bg: focusedOption.value === option.value ? 'grayLight' : 'white',
      key,
      onClick: () => selectValue(option),
      onMouseEnter: () => focusOption(option),
      cursor: 'pointer',
      className: this.cxEl('avatar-user'),
      style,
    });
  };

  render() {
    const {
      size,
      creatable,
      value,
      multi,
      theme,
      virtualized,
      async,
      hideArrow,
      hideClear,
      style,
      tabIndex,
      inputProps = {},
      ...props
    } = this.props;

    const selectProps = {
      ...omit(props, ['className', 'errorBorder']),
      onChange: this.handleChange,
      onOpen: this.handleOpen,
      onClose: this.handleClose,
      options: this.state.options,
      onInputChange: this.handleInputChange,
      ref: this.handleRef,
      tabIndex,
      value,
      multi,
      async,
      inputProps,
    };

    const avatarUsers = props.type === 'avatarUsers';

    if (multi) {
      selectProps.value = value instanceof Set ? value.toArray() : value;
      selectProps.closeOnSelect = props.closeOnSelect || false;
    }

    if (creatable) {
      selectProps.value = this.getCreatableValue(selectProps.value);
      selectProps.promptTextCreator = this.props.newOptionText;
    }

    if (creatable && !multi) {
      // onNewOptionClick doesn't return the array of values
      // normally returned in the multi onChange handler, so
      // onNewOptionClick isn't supported in our current
      // implementation for when multi = true.
      selectProps.onNewOptionClick = this.handleNewOptionClick;
    }

    if (props.name) {
      selectProps.inputProps = { name: props.name, ...selectProps.inputProps };
    }

    if (avatarUsers) {
      selectProps.valueRenderer = this.renderAvatarValueContent;
      selectProps.optionRenderer = this.renderAvatarUserOption;
    }

    let Component;

    if (creatable) {
      Component = ReactSelect.Creatable;
    } else if (async) {
      Component = ReactSelect.Async;
    } else if (virtualized || avatarUsers) {
      props.selectComponent = Component;
      Component = VirtualizedSelect;
    } else {
      Component = ReactSelect;

      if (theme === 'mui-dark') {
        selectProps.arrowRenderer = ({ isOpen }) => {
          if (isOpen) {
            return (
              <span style={{ fontSize: 12 }}>
                <FontAwesomeIcon color={colors.blue} icon={faChevronUp} />
              </span>
            );
          }

          return (
            <span style={{ fontSize: 12 }}>
              <FontAwesomeIcon color={colors.blue} icon={faChevronDown} />
            </span>
          );
        };
      }
    }

    return (
      <div
        className={this.cx({
          [size]: size,
          'is-open': this.state.open,
          [theme]: theme,
          'hide-arrow': hideArrow,
          'hide-clear': hideClear,
          errorborder: this.props.errorBorder,
        })}
        style={style}
      >
        <Component {...selectProps} />
      </div>
    );
  }
}
