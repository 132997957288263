import { trackUxEvent } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
} from 'util/tracking_constants';

import { REMEMBERED_TIME_CLOCK_TYPE } from './constants';

export const openHubTimeClock = trackingProperties => {
  trackUxEvent({
    productArea: PRODUCT_AREAS.TIMESHEETS,
    eventCategory: EVENT_CATEGORIES.GET_A_TIME_CLOCK,
    eventAction: EVENT_ACTIONS.WEB_TIME_CLOCK_LAUNCH_CLICKED,
    properties: trackingProperties,
  });
  window.Homebase.HubLogin.showPopup();
};

export const getSavedTimeClockType = () =>
  localStorage.getItem(REMEMBERED_TIME_CLOCK_TYPE);

export const saveTimeClockType = type =>
  localStorage.setItem(REMEMBERED_TIME_CLOCK_TYPE, type);
