import React from 'react';

import { NBK_TRACKING } from 'features/earlyLife/NewBusinessKit/constants';

import {
  EVENT_ACTIONS,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';
import { useExtendUx, useTrackUxOnMount, withUxRoot } from 'util/uxEvents';

export interface NBKDialogWrapperProps {
  children?: React.ReactNode;
  eventCategory: string;
  duringBusinessHours?: boolean;
}

const NBKDialogWrapper = ({
  children,
  eventCategory,
  duringBusinessHours,
}: NBKDialogWrapperProps) => {
  const extendUxEvent = useExtendUx();

  useTrackUxOnMount(EVENT_ACTIONS.MODAL_VIEWED, TRACK_ACTION_TYPES.VIEW);

  extendUxEvent({
    eventCategory,
    modal_source: NBK_TRACKING.MODAL_SOURCE,
    modal_version: NBK_TRACKING.MODAL_VERSION,
    during_business_hours: duringBusinessHours,
  });

  return <>{children}</>;
};

export default withUxRoot({
  productArea: PRODUCT_AREAS.GLOBAL,
})(NBKDialogWrapper);
