/* Disabling max-len and prettier to make it easier
   to maintain sort order of constants.  No need to wrap
   longer ones on multiple lines making it harder to sort
   the lines.
 */
/* eslint-disable max-len,prettier/prettier */
export const PRODUCT_AREAS = {
  ADMIN_BILLING_PAGE: 'admin_billing_page',
  APPLICATION_QUESTIONS: 'application_questions',
  BACKGROUND_CHECKS: 'background_checks',
  BILLING: 'billing',
  BILLING_PAGE: 'billing_page',
  BREAK_COMPLAINCE_SETTINGS: 'Break & Complaince settings',
  BREAK_ENFORCEMENT: 'Break enforcements',
  CASH_OUT: 'cash_out',
  CLOVER_WEB: 'clover_web',
  GROWTH: 'growth',
  CLOVER_WEB_DASHBOARD: 'clover_web_dashboard',
  CLOVER_WEB_SIGN_UP: 'clover_web_sign_up',
  CLOVER_WEB_SIGN_UP_EMBEDDED: 'clover_web_sign_up_embedded',
  COMMAND_BAR: 'command_bar',
  COMPANY_TIMESHEETS: 'company_timesheets',
  DASHBOARD: 'dashboard',
  DOCUMENTS: 'documents',
  GEOFENCE_OVERLAY: 'geofence_overlay',
  GEOFENCE_SOFTPAYWALL: 'geofence_softpaywall',
  GHOST_PACKAGING: 'ghost_packaging',
  GLOBAL: 'global',
  HEALTH_AND_SAFETY: 'health_and_safety',
  HELP_CENTER: 'help_center',
  HELP_PAGE: 'help_page',
  HIRING: 'hiring',
  HIRING_APPLICANTS: 'hiring_applicants',
  HIRING_CAREER_PAGE: 'hiring_career_page',
  HIRING_DASHBOARD: 'hiring_dashboard',
  HIRING_DASHBOARD_V_1: 'hiring_dashboard_v_1',
  HIRING_INTERVIEWS: 'hiring_interviews',
  HR_PRO: 'hr_pro',
  INTEGRATION: 'integration',
  JOB_POST_FLOW: 'job_post_flow',
  LAUNCH_WEEK: 'launch_week',
  MANAGE_APPLICANTS: 'manage_applicants',
  MESSAGING: 'messaging',
  MESSENGER: 'messenger',
  MOBILE_INSTAGRAM: 'mobile_instagram',
  MONETIZATION: 'monetization',
  NAVIGATION: 'navigation',
  NEW_HIRE_ONBOARDING: 'new_hire_onboarding',
  NEW_HIRE_PACKET: 'new_hire_packet',
  ONBOARDING: 'onboarding',
  ONBOARDING_GUIDED_PREVIEW: 'onboarding_guided_preview',
  ONBOARDING_SPLASH_SCREEN: 'onboarding_splash_screen',
  OVERLAY: 'overlay',
  PAYING_BASE: 'paying_base',
  PAYROLL: 'payroll',
  PAYROLL_DASHBOARD: 'payroll dashboard',
  PAYROLL_SELF_SERVE: 'payroll_self_serve',
  PAYWALL: 'paywall',
  PQL: 'PQL',
  PTO: 'pto',
  QUICK_START_GUIDE: 'quick_start_guide',
  QUICK_START_GUIDE_V2: 'quick_start_guide_v2',
  REFERRALS: 'referrals',
  REPORTS: 'reports',
  ROLES: 'roles',
  SCHEDULE: 'schedule',
  SCHEDULE_BUILDER: 'schedule_builder',
  SETTINGS: 'settings',
  SHIFT_PAY_EMPLOYER_MARKETING: 'finserv_web_app',
  SIGNATURE_BLOCK: 'signature_block',
  SIGN_IN: 'sign_in',
  SIGN_UP: 'sign_up',
  SIGN_UP_EXTENDED_QUESTIONS: 'sign_up_extended_questions',
  SINGLE_DOCUMENT_PACKAGE: 'single_document_packet',
  TASK_MANAGER: 'task_manager',
  TEAM: 'team',
  TEAM_ANNOUNCEMENTS: 'team_announcements',
  TIERS_DOWNGRADE: 'tiers_downgrade',
  TIERS_PAGE: 'tiers_page',
  TIMESHEETS: 'timesheets',
  TIP_MANAGER: 'tip_manager',
  TIME_CLOCK_SETTINGS: 'time_clock_settings',
  USER_WEB_TIMECLOCK: 'user_web_timeclock',
  WEB_DASHBOARD: 'web_dashboard',
  WEB_DASHBOARD_V_3: 'web_dashboard_v_3',
  WELCOME_TEXT_CALLBACK: 'welcome_text_callback',
};

export const EVENT_CATEGORIES = {
  A1_EMAIL_DRIP1_EMAIL_1: 'a1_email_drip1_email_1',
  A1_EMAIL_DRIP1_EMAIL_2: 'a1_email_drip1_email_2',
  A1_EMAIL_DRIP2_EMAIL_1: 'a1_email_drip2_email_1',
  A1_EMAIL_DRIP2_EMAIL_2: 'a1_email_drip2_email_2',
  ACKNOWLEDGED_WAIVED_BREAK: 'Manager acknowledged waived break',
  ADD_APPLICANTS_MANUALLY: 'add_applicants_manually',
  ADD_DEPARTMENT: 'add_department',
  ADD_SINGLE_PAYROLL_LOCATION_FLOW: 'add_single_payroll_location_flow',
  ADD_TEAM_DRAWER: 'add_team_drawer',
  ADD_TEAM_PAYROLL_FLOW: 'add_team_payroll_flow',
  ADD_TEAM_SUCCESS: 'add_team_success',
  ADMIN_BILLING_PAGE: 'admin_billing_page',
  ADVANCED_COMPLIANCE_VERSION: 'advanced_compliance_version',
  API_ACCESS: 'api_access',
  AUTO_ARCHIVE_MODAL: 'auto_archive_modal',
  AUTO_CLOCK_OUT: 'auto_clock_out',
  AUTO_SCHEDULING: 'auto_scheduling',
  AVAILABILITY_APPROVAL: 'availability_approval',
  BILLING_MODAL: 'billing_modal',
  BILLING_PAGE: 'billing_page',
  BILLING_VERIFIED: 'billing_verified',
  BLOCKED_PAYROLL_NSF: 'Blocked Payroll - NSF',
  BOOST_PURCHASE: 'boost_purchase',
  BREAK: 'Break',
  BREAK_ENFORCEMENT: 'break_enforcement',
  BREAK_PENALTIES: 'break_penalties',
  BREAK_SETTINGS_MODAL: 'break_settings_modal',
  BULK_ACTIONS_DRAWER: 'bulk_actions_drawer',
  CALLBACK_CONFIRMATION_SCREEN: 'callback_confirmation_screen',
  CASH_OUT_PAGE: 'cash_out_page',
  CERTIFICATES: 'certificates',
  CERTIFICATES_REPACKAGING_1: 'certificates_repackaging_1',
  CHANNEL_LIST: 'channel_list',
  CHECKOUT: 'checkout',
  CLEAR_ALL_FILTER: 'clear_all_filter',
  CLOCKED_IN: 'clocked_in',
  CLOCKED_IN_BREAK: 'clocked_in_break',
  CLOCKED_OUT: 'clocked_out',
  CLOCKED_OUT_BREAK: 'clocked_out_break',
  CLOCK_OUT_REMINDER: 'clock_out_reminder',
  CLOVER_ONBOARDING: 'clover_onboarding',
  CLOVER_WELCOME_MODAL: 'clover_welcome_modal',
  COMMON_OVERTIMES: 'common_overtimes',
  CONTACT_INFO: 'contact_info',
  COMMAND_BAR: 'command_bar',
  CONVERSATION: 'conversation',
  CONVERSATION_MENU: 'conversation_menu',
  COPY_WEEK_MODAL: 'copy_week_modal',
  COUNTDOWN_TIMER: 'countdown_timer',
  CREATE_APPLICATION_QUESTIONS_PAGE: 'create_application_questions_page',
  DASHBOARD: 'dashboard',
  DASHBOARD_BANNER_1_PAYROLL_LOVE_QUIZ: 'dashboard_banner_1_payroll_love_quiz',
  DASHBOARD_BANNER_2_PAYROLL_LOVE_QUIZ_FOLLOW_UP_1:
    'dashboard_banner_2_payroll_love_quiz_follow_up_1',
  DASHBOARD_BANNER_3_NEW_CUSTOMER_3_MONTHS_FREE:
    'dashboard_banner_3_new_customer_3_months_free',
  DASHBOARD_BANNER_4_NEW_CUSTOMER_6_MONTHS_FREE:
    'dashboard_banner_4_new_customer_6_months_free',
  DASHBOARD_BANNER_5_ABANDON_CART_3_MONTHS_FREE:
    'dashboard_banner_5_abandon_cart_3_months_free',
  DASHBOARD_BANNER_6_ABANDON_CART_6_MONTHS_FREE:
    'dashboard_banner_6_abandon_cart_6_months_free',
  DATE_PICKER: 'date_picker',
  DASHBOARD_COMPANY: 'dashboard_company',
  DASHBOARD_GET_MOBILE_APP_CARD_MY_WEEK: 'get_mobile_app_card_week',
  DASHBOARD_LOCATION: 'dashboard_location',
  DASHBOARD_MANAGER: 'dashboard_manager',
  DASHBOARD_MY_WEEK: 'dashboard_my_week',
  DASHBOARD_PAYROLL_DETAILS_MISSING: 'dashboard_payroll_details_missing',
  DASHBOARD_PAYROLL_HISTORY: 'dashboard_payroll_history',
  DASHBOARD_SETUP_PAYROLL_AD: 'dashboard_setup_payroll_ad',
  DASHBOARD_STANDARD_PAYROLL: 'dashboard_standard_payroll',
  DAILY_REVIEW: 'daily_review',
  DELETE_ROLE: 'delete_role',
  DEPARTMENTS: 'departments',
  DEPARTMENTS_ROLES: 'departments_roles',
  DEPARTMENT_SCHEDULING: 'department_scheduling',
  DIRECT_DEPOSIT_FORM: 'direct_deposit_form',
  DISTANCE_FILTER: 'distance_filter',
  DOCUMENTS: 'documents',
  DOCUMENT_MANAGEMENT: 'document_management',
  DOWNGRADE_MODAL: 'downgrade_modal',
  DOWNGRADE_SURVEY: 'downgrade_survey',
  DOWNGRADE_DRAWER: 'downgrade_drawer',
  ENFORCEMENT_DRAWER: 'enforcement_drawer',
  DOWNLOAD_COMPANY_TIMESHEETS: 'download_company_timesheets',
  DOWNLOAD_FLYER: 'download_flyer',
  DOWNLOAD_TIMESHEETS_MODAL: 'download_timesheets_modal',
  EDIT_APPLICATION_QUESTIONS_PAGE: 'edit_application_questions_page',
  EDIT_PAYROLL_RUN_MODAL: 'edit_payroll_run_modal',
  EDIT_AUTOPAYROLL_RUN_MODAL: 'edit_autopayroll_run_modal',
  EMPLOYEE_CHOICE: 'employee_choice',
  EMPLOYEE_CLOCK_OUT_EDITS: 'employee_clock_out_edits',
  EMPTY_STATE: 'empty_state',
  EMPTY_STATE_PAGE: 'empty_state_page',
  EMPLOYEE_CAP: 'employee_cap',
  END_OF_INTEGRATION_MODAL: 'end_of_integration_modal',
  END_OF_TRIAL_VARIANT_1: 'end_of_trial_variant_1',
  END_OF_TRIAL_MODAL: 'end_of_trial_modal',
  ENFORCEMENT_CAPS_PAYWALLS_MODAL: 'employee_caps_paywalls_modal',
  ENTERPRISE: 'enterprise',
  ESSENTIALS: 'essentials',
  EVENTS: 'events',
  EXPIRED_CREDIT_CARDS_BANNER: 'expired_credit_cards_banner',
  EXPORT_COMPANY_TIMESHEETS: 'export_company_timesheets',
  EXPORT_TIMESHEETS_MODAL: 'export_timesheets_modal',
  FAQ: 'faq',
  FINAL_PAGE: 'final_page',
  FIRST_PAYROLL_RUN: 'first_payroll_run',
  FORGOT_PASSWORD: 'forgot_password',
  FULL_TIMESHEETS: 'full_timesheets',
  GEOFENCE_SETTING: 'geofence_setting',
  GET_A_TIME_CLOCK: 'get_a_time_clock',
  GET_HELP_BUTTON: 'get_help_button',
  GET_HELP_PANEL_CALLBACK_FORM: 'get_help_panel_callback_form',
  GET_MOBILE_APP_CARD_MANAGER: 'get_mobile_app_card_manager',
  GET_MOBILE_APP_MODAL: 'get_mobile_app_modal',
  GHOST_PACKAGING_PAYWALL_MTC: 'ghost_pkg_paywall_mtc',
  GM: 'gm',
  GUIDED_PREVIEW_PART_ONE: 'guided_preview_part_one',
  GUIDED_PREVIEW_PART_TWO: 'guided_preview_part_two',
  GUIDED_PREVIEW_PART_THREE: 'guided_preview_part_three',
  GUIDED_PREVIEW_START: 'guided_preview_start',
  GROWTH_STRIDE_HEALTH_ER_FLOW: 'growth_stride_health_er_flow',
  HB_DASHBOARD_PAYROLL_RECOMMENDATION: 'hb_dashboard_payroll_recommendation',
  HEALTH_AND_SAFETY: 'health_and_safety',
  HELP_CENTER_CALL_CARD_L60: 'help_center_call_card_l60',
  HELP_CENTER_CALL_CARD_MAIN_LINE: 'help_center_call_card_main_line',
  HELP_CENTER_CALL_CARD_M60: 'help_center_call_card_m60',
  HELP_CENTER_NO_CALL_CARD_BASIC_90: 'help_center_no_call_card_basic_90',
  HELP_PAGE: 'help_page',
  HIDE_SCHEDULE_FROM_EMPLOYEES: 'hide_schedule_from_employees',
  HIRING_APPLICANTS: 'hiring_applicants',
  HIRING_CAREER_PAGE: 'hiring_career_page',
  HIRING_INTERVIEWS: 'hiring_interviews',
  HIRING_FTU_HEADER: 'hiring_ftu_header',
  HIRING_JOBS: 'hiring_jobs',
  HIRING_JOB_BOARD: 'hiring_job_board',
  HIRING_JOB_POST: 'hiring_job_post',
  HIRING_JOB_POST_SUCCESS: 'hiring_job_post_success',
  HISTORICAL_TIMESHEETS: 'historical_timesheets',
  HOMEBASE_NATIVE_JOB: 'homebase_native_job',
  HOMEBASE_PAYROLL_MODAL: 'homebase_payroll_modal',
  HR_PRO: 'hr_pro',
  HOURLY_LABOR_COSTS: 'hourly_labor_costs',
  HOURS_PROVIDED: '10k_milestone',
  INELIGIBLE_VIEW: 'ineligible_view',
  INVITE_TEAMMATE_MODAL: 'invite_teammate_modal',
  INVOICED_COMPANIES: 'invoiced_companies',
  I_9_FORM: 'i_9_form',
  JOB_APPLICANTS: 'job_applicants',
  JOB_BOOST: 'job_boost',
  JOB_DESCRIPTION_PAGE_WITH_ROLE_AND_LOCATION_SELECTOR:
    'job_description_page_with_role_and_location_selector',
  JOB_NOTES: 'job_notes',
  JOB_POST_CARD: 'job_post_card',
  JOB_POST_SUCCESS_PAGE: 'job_post_success_page',
  JUMPSTART_PAST_WEEK_DRAWER: 'jumpstart_past_week_drawer',
  JUMPSTART_PAST_WEEK_DRAWER_COPY: 'jumpstart_past_week_drawer_copy',
  JUMPSTART_TEMPLATE_DRAWER: 'jumpstart_template_drawer',
  LABOR_COST_SUMMARY: 'labor_cost_summary',
  LABOR_BY_TYPE: 'labor_by_type',
  LANDING_PAGE: 'landing_page',
  LATE_PAYROLL_RUN: 'late_payroll_run',
  LAUNCH_WEEK: 'launch_week',
  LAUNCH_WEEK_STORY: 'launch_week_story',
  LAUNCH_WEEK_SHARE: 'launch_week_share',
  LEFT_NAVIGATION: 'left_navigation',
  LOCATION: 'location',
  LOCATION_FILTER: 'location_filter',
  MANAGE_PLAN_PAGE: 'manage_plan_page',
  MANAGE_PLANS_FOCUS_VIEW_PAGE: 'manage_plans_focus_view_page',
  MANAGER_ACCESS_PAYWALL: 'manager_access_paywall',
  MANAGERS: 'managers',
  MANAGER_LOG: 'manager_log',
  MANAGER_NOTIFICATIONS: 'manager_notifications',
  MESSAGE: 'message',
  MESSAGE_DRAWER: 'message_drawer',
  MESSAGING_READ_RECEIPTS: 'messaging_read_receipts',
  MISSED_PR_TEAM_PAID_FORM: 'missed_pr_team_paid_form',
  MISSED_PAYROLL_DEADLINE: 'missed_payroll_deadline',
  MISSED_PAYROLL_PAYDAY: 'missed_payroll_payday',
  MOBILE_WEB_INTERSTITIAL: 'mobile_web_interstitial',
  MODAL_CALLBACK_FORM: 'modal_callback_form',
  MONETIZATION_CTA: 'monetization_cta',
  MTC_TRIAL_PROMO_MODAL: 'mtc_trial_promo_modal',
  MULTILOCATION_CARD_UPDATE_REMINDER_MODAL:
    'multilocation_card_update_reminder_modal',
  MULTILOCATION_ENFORCEMENT: 'multilocation_enforcement',
  MY_AVAILABILITY: 'my_availability',
  NAV: 'nav',
  NAV_PROFILE_DROPDOWN: 'nav_profile_dropdown',
  NBK_DOWNGRADE: 'nbk_downgrade',
  NBK_DOWNGRADE_NON_BIZ_HOURS: 'nbk_downgrade_non_biz_hours',
  NBK_DOWNGRADE_REQUEST_SUPPORT: 'nbk_downgrade_request_support',
  NBK_DOWNGRADE_REQUEST_SUPPORT_SUCCESS:
    'nbk_downgrade_request_support_success',
  NEEDS_REVIEW: 'needs_review',
  NEW_HIRE_ONBOARDING: 'new_hire_onboarding',
  NEW_HIRE_ONBOARDING_SETTINGS: 'new_hire_onboarding_settings',
  NEW_LOCATION_MODAL: 'new_location_modal',
  NEW_SAMPLE_TIME_CARD: 'new_sample_time_card',
  NONPAYING: 'nonpaying',
  NOT_NOW: 'not_now',
  NY_BREAK_RULE: 'ny_break_rule',
  OLD_SAMPLE_TIME_CARD: 'old_sample_time_card',
  ONBOARDING: 'onboarding',
  ONBOARDING_SPLASH_SCREEN: 'onboarding_splash_screen',
  ONBOARDING_START_MODAL: 'onboarding_start_modal',
  ON_TIME: 'on_time',
  OPEN_SHIFT: 'open_shift',
  OPTIONAL_BREAKS: 'optional_breaks',
  ORDER_SUMMARY_MODAL: 'order_summary_modal',
  OVERTIMES: 'overtimes',
  OVERTIME_SETTINGS_MODAL: 'overtime_settings_modal',
  OVERVIEW: 'overview',
  PACKET_CONFIG: 'packet_config',
  PACKET_INTRO: 'packet_intro',
  PAST_DUE_SUBSCRIPTIONS_BANNER: 'past_due_subscriptions_banner',
  PAST_DUE_SUBSCRIPTIONS_DIALOG: 'past_due_subscriptions_dialog',
  PAST_DUE_SUBSCRIPTIONS_WITHOUT_BILLING_PERMISSION_DIALOG:
    'past_due_subscriptions_without_billing_permission_dialog',
  PAYING: 'paying',
  PAYING_BASE: 'paying_base',
  PAYROLL: 'payroll',
  PAYROLL_SUBMIT: 'payroll_submit',
  PAYROLL_COMPLETED: 'payroll_completed',
  PAYROLL_DASHBOARD: 'payroll_dashboard',
  PAYROLL_EOY_2023_BANNER: 'payroll_eoy_2023_banner',
  PAYROLL_HELP_OTHER_CARD_L60: 'payroll_help_other_card_l60',
  PAYROLL_HELP_OTHER_CARD_M60: 'payroll_help_other_card_m60',
  PAYROLL_HISTORY_MODAL: 'payroll_history_modal',
  PAYROLL_TEAM_MEMBER_DRAWER: 'payroll_team_member_drawer',
  PAYROLL_DASHBOARD_STRUCTURED_SWITCHER_REMINDER_COMPONENT:
    'payroll_dashboard_structured_switcher_reminder_component',
  PAYROLL_DASHBOARD_W2_1099: 'dashboard_w2_1099',
  PAYROLL_IMPLEMENTATION_PROVIDER_ACCOUNT_ACCESS_COMPONENT:
    'payroll_implementation_provider_account_access_component',
  PAYROLL_NSF_PREDICTION_RESOLUTION: 'nsf_prediction_resolution',
  DASHBOARD_AUTOPAYROLL: 'dashboard_autopayroll',
  PAYROLL_RUN_WORKFLOW_SUBMISSION_MODAL_AUTOPAYROLL:
    'payroll_run_workflow_submission_confirmation',
  PAYROLL_RUN_WIDGET: 'payroll_run_widget',
  PAYROLL_RUN_WORKFLOW_HOURS_AND_TIME_OFF:
    'payroll_run_workflow_hours_and_time_off',
  PAYROLL_RUN_WORKFLOW_NET_PAY: 'payroll_run_workflow_net_pay',
  PAYROLL_RUN_WORKFLOW_REVIEW_AND_SUBMIT:
    'payroll_run_workflow_review_and_submit',
  PAYROLL_REPORTS: 'payroll_reports',
  PAYROLL_SETUP: 'payroll_setup',
  PAYROLL_SUMMARY: 'payroll_summary',
  PAYROLL_PROMO_INITIAL: 'payroll_promo_initial',
  PAYROLL_PROMO_ELIGIBILITY: 'payroll_promo_eligibility',
  PAYROLL_PROMO_OFFER: 'payroll_promo_offer',
  PAYROLL_SUMMARY_DOWNLOAD_MODAL: 'payroll_summary_download_modal',
  PAYROLL_ACTION_NEEDED: 'homebase_payroll_action_needed',
  PAYROLL_READY_TO_RUN_BANNER: 'ready_to_run_banner',
  PAYROLL_ZERO_STATE: 'payroll_zero_state',
  PAY_PERIOD_MENU: 'pay_period_menu',
  PAY_PERIOD_SETTINGS_MODAL: 'pay_period_settings_modal',
  PLUS: 'plus',
  POS_CONNECTION_SETTINGS_MODAL: 'pos_connection_settings_modal',
  PREVENT_EARLY: 'prevent_early',
  PROMOTION_HOMEBASE_DASHBOARD_BANNER_7_THANKSGIVING_2022_3_MONTHS_FREE:
    'promotion_homebase_dashboard_banner_7_thanksgiving_2022_3_months_free',
  PENDING_STATE_PAGE: 'pending_state_page',
  PTO_BALANCES_WIDGET: 'pto_balances_widget',
  PTO_DASHBOARD: 'pto_dashboard',
  PTO_POLICIES_WIDGET: 'pto_policies_widget',
  PTO_POLICY_DETAILS: 'pto_policy_details',
  PTO_POLICY_ENROLL_EMPLOYEES: 'pto_policy_enroll_employees',
  PTO_POLICY_OPTIONAL_SETTINGS: 'pto_policy_optional_settings',
  PTO_POLICY_STARTING_BALANCES: 'pto_policy_starting_balances',
  PTO_REQUESTS_WIDGET: 'pto_requests_widget',
  PTO_V_2_VERSION: 'pto_v_2_version',
  PUBLIC_API: 'public_api',
  PUBLISH_DRAWER_REMINDER: 'publish_drawer_reminder',
  PUBLISH_HISTORY: 'publish_history',
  PURCHASE_FUNNEL: 'purchase_funnel',
  PURCHASE_FUNNEL_DISCOVERABILITY_GEOFENCE:
    'purchase_funnel_discoverability_geofence',
  PURCHASE_FLOW: 'purchase_flow',
  QUALITY_FILTER: 'quality_filter',
  QUICKBOOKS_TIMESHEETS_EXPORT: 'quickbooks_timesheets_export',
  QUICK_LINKS: 'quick_links',
  QUICK_START_GUIDE_V2: 'quick_start_guide_v2',
  RECOMMENDATIONS: 'recommendations',
  REFERRALS: 'referrals',
  REPORT: 'report',
  REPORTS: 'reports',
  REPORTS_CUSTOM_DATE_RANGE: 'reports_custom_date_range',
  REQUEST_CALLBACK_MODAL: 'request_callback_modal',
  REQUEST_CALLBACK_SUCCESS_MODAL: 'request_callback_success_modal',
  RETURN_TO_PARTNER: 'return_to_partner',
  RETRIEVE_COMPANY_TIMESHEETS_DATE_RANGE:
    'retrieve_company_timesheets_date_range',
  ROLE_FILTER: 'role_filter',
  ROLE_WAGES: 'role_wages',
  ROSTER: 'roster',
  ROUND_CLOCK_INS: 'round_clock_ins',
  SALARY: 'salary',
  SALES_FORECAST: 'sales_forecast',
  SALES_RESET_TIME: 'sales_reset_time',
  SALES_SUMMARY: 'sales_summary',
  SCHEDULEDVSACTUALCLOCKINS: 'scheduledvsactualclockins',
  SCHEDULED_VS_ACTUAL_CLOCK_INS: 'scheduled_vs_actual_clock_ins',
  SCHEDULED_VS_ACTUAL_HOURS: 'scheduled_vs_actual_hours',
  SCHEDULE_BUILDER: 'schedule_builder',
  SCHEDULE_BUILDER_SYNC_SHIFTS: 'schedule_builder_sync_shifts',
  SCHEDULE_BUILDER_ADD_EVENT_MODAL: 'schedule_builder_add_event_modal',
  SCHEDULE_BUILDER_AUTO_SCHEDULE_INFO_MODAL:
    'schedule_builder_auto_schedule_info_modal',
  SCHEDULE_BUILDER_AUTO_SCHEDULE_MODAL: 'schedule_builder_auto_schedule_modal',
  SCHEDULE_BUILDER_BETA_OPT_IN_MODAL: 'Schedule Builder - Beta Opt In Modal',
  SCHEDULE_BUILDER_CLEAR_DEPARTMENT_SCHEDULE_MODAL:
    'schedule_builder_clear_department_schedule_modal',
  SCHEDULE_BUILDER_CLEAR_SCHEDULE_MODAL:
    'schedule_builder_clear_schedule_modal',
  SCHEDULE_BUILDER_CONFLICT_MODAL: 'schedule_builder_conflict_modal',
  SCHEDULE_BUILDER_COPY_SHIFTS_MODAL: 'schedule_builder_copy_shifts_modal',
  SCHEDULE_BUILDER_CUSTOM_SORT_MODAL: 'schedule_builder_custom_sort_modal',
  SCHEDULE_BUILDER_DELETE_CONFIRMATION_MODAL:
    'schedule_builder_delete_confirmation_modal',
  SCHEDULE_BUILDER_EDIT_DEPT_ROLES: 'schedule_builder_edit_depts_roles',
  SCHEDULE_BUILDER_EVENT_DELETE_MODAL: 'schedule_builder_event_delete_modal',
  SCHEDULE_BUILDER_EXPANDED_FORECAST: 'schedule_builder_expanded_forecast',
  SCHEDULE_BUILDER_BETA_FEEDBACK_MODAL: 'schedule_builder_beta_feedback_modal',
  SCHEDULE_BUILDER_BETA_OPT_OUT_MODAL: 'schedule_builder_beta_opt_out_modal',
  SCHEDULE_BUILDER_JUMPSTART_DRAWER: 'schedule_builder_jumpstart_drawer',
  SCHEDULE_BUILDER_MIGRATION: 'schedule_builder_migration',
  SCHEDULE_BUILDER_MIGRATION_TOOL_TIP: 'schedule_builder_migration_tool_tip',
  SCHEDULE_BUILDER_MANAGE_TEMPLATES_DRAWER:
    'schedule_builder_manage_templates_drawer',
  SCHEDULE_BUILDER_MANAGE_YOUR_EXPERIENCE:
    'schedule_builder_manage_your_experience_modal',
  SCHEDULE_BUILDER_PRINT_DRAWER: 'schedule_builder_print_drawer',
  SCHEDULE_BUILDER_PUBLISH_DRAWER: 'schedule_builder_publish_drawer',
  SCHEDULE_BUILDER_PUBLISH_HISTORY_MODAL:
    'schedule_builder_publish_history_modal',
  SCHEDULE_BUILDER_REPEAT_SCHEDULE_DRAWER:
    'schedule_builder_repeat_schedule_drawer',
  SCHEDULE_BUILDER_REVERT_SCHEDULE_MODAL:
    'schedule_builder_revert_schedule_modal',
  SCHEDULE_BUILDER_SHIFT_APPROVAL_MODAL:
    'schedule_builder_shift_approval_modal',
  SCHEDULE_BUILDER_SHIFT_MODAL: 'schedule_builder_shift_modal',
  SCHEDULE_BUILDER_TEMPLATE_DRAWER: 'schedule_builder_template_drawer',
  SCHEDULE_BUILDER_TIME_OFF_BLACKOUT_MODAL:
    'schedule_builder_time_off_blackout_modal',
  SCHEDULE_BUILDER_TIME_OFF_DELETE_MODAL:
    'schedule_builder_time_off_delete_modal',
  SCHEDULE_BUILDER_TIME_OFF_POPPER: 'schedule_builder_time_off_popper',
  SCHEDULE_BUILDER_TOOLS_DROPDOWN: 'schedule_builder_tools_dropdown',
  SCHEDULE_BUILDER_WEEK_VIEW: 'schedule_builder_week_view',
  SCHEDULE_START_DAY_MODAL: 'schedule_start_day_modal',
  SCHEDULING: 'scheduling',
  SECURITY: 'security',
  SELECT_UNSCHEDULED_ROLES: 'select_unscheduled_roles',
  SET_PASSWORD: 'set_password',
  SET_PASSWORD_CTA: 'set_password_cta',
  SET_UP_PAYROLL_MODAL: 'set_up_payroll_modal',
  SETTINGS_EDIT_PASSWORD_MODAL: 'settings_edit_password_modal',
  SETTINGS_MFA_EDIT_MODAL: 'settings_mfa_edit_modal',
  SETTINGS_MFA_ENABLE_MODAL: 'settings_mfa_enable_modal',
  SETTINGS_MFA_DISABLE_MODAL: 'settings_mfa_disable_modal',
  SETTINGS_PAYROLL_AD: 'settings_payroll_ad',
  SIGN_UP: 'sign_up',
  SIGN_UP_FLOW: 'signup_flow',
  SHIFT_CARD_DETAILS: 'shift_card_details',
  SHIFT_COPY_MODE: 'shift_copy_mode',
  SHIFT_COVER: 'shift_cover',
  SHIFT_FEEDBACK: 'shift_feedback',
  SHIFT_HOVER_VIEW: 'shift_hover_view',
  SHIFT_PAY_EMPLOYER_MARKETING: 'finserv_employer_info',
  SHIFT_NOTE: 'shift_note',
  SHIFT_NOTE_METERING: 'shift_note_metering',
  SHIFT_NOTE_TEXT_FIELD: 'shift_note_text_field',
  SHIFT_TRADE: 'shift_trade',
  SHOPIFY_DOWNGRADE_BANNER: 'shopify_downgrade_banner',
  SIGNATURE_BLOCK: 'signature_block',
  SIGN_IN: 'sign_in',
  SIGN_IN_PAGE: 'sign_in_page',
  SMS_NOTIFY_SCHEDULE: 'sms_notify_schedule',
  SOURCE_FILTER: 'source_filter',
  STATE_FORM: 'state_form',
  STATUS_FILTER: 'status_filter',
  STEADY_STATE_PAGE: 'steady_state_page',
  STORAGE_COMPLIANCE_PAYWALL: 'storage_compliance_paywall',
  SUB_MENU: 'sub_menu',
  SYNDICATION_PAGE: 'syndication_page',
  TASK_MANAGER: 'task_manager',
  TEAM_ANNOUNCEMENTS_CARD: 'team_announcements_card',
  TEAM_AVAILABILITY: 'team_availability',
  TEAM_MEMBER_PROFILE: 'team_member_profile',
  TEAM_MESSENGER: 'team_messenger',
  TEAM_PERMISSIONS: 'team_permissions',
  TEAM_PERMISSIONS_VERSION: 'team_permissions_version',
  TEAM_REQUEST: 'team_request',
  TEAM_STATUS: 'team_status',
  THIRD_PARTY_PROVIDER_BANNER: '3rd_party_provider_banner',
  THRESHOLD_SHIFT_LATE: 'threshold_shift_late',
  TIERS_PAGE: 'tiers_page',
  TIMECARD_DETAIL: 'timecard_detail',
  TIMECARD_DETAILS: 'timecard_details',
  TIMECLOCK_CHOICE_DRAWER_DISMISS: 'timeclock_choice_drawer_dismiss',
  TIMESHEETS_DAILY_REVIEW_EMPTY_STATE: 'timesheets_daily_review_empty_state',
  TIMESHEETS_PAY_PERIOD_REVIEW_EMPTY_STATE:
    'timesheets_pay_period_review_empty_state',
  TIMESHEETS_PAYROLL_SUMMARY_INDIVIDUAL:
    'timesheets_payroll_summary_individual',
  TIMESHEETS_SUB_TABLES: 'timesheets_sub_tables',
  TIMESHEETS_TABLE_HEADER: 'timesheets_table_header',
  TIMESHEETS_TOOLS: 'timesheets_tools',
  TIMESHEET_SUMMARY_COPY: 'timesheet_summary_copy',
  TIMESHEETS: 'timesheets',
  TIME_CLOCK_OPTIONS: 'time_clock_options',
  TIME_OFFS: 'time_offs',
  TIME_OFFS_LIMIT: 'time_offs_limit',
  TIME_OFF_AND_PTO: 'time_off_and_pto',
  TIME_OFF_BLACKOUT_PERIOD: 'time_off_blackout_period',
  TIME_OFF_REQUEST_DAY_MIN: 'time_off_request_day_min',
  TIME_TRACKING: 'time_tracking',
  TIP_POOLING_TOOLTIP: 'tip_pooling_tooltip',
  TIP_MANAGER_CHECKOUT_DRAWER: 'tip_manager_checkout_drawer',
  TIPS_RECEIVED: 'tips_received',
  TIP_OUTS: 'tip_outs',
  TRIAL_INDICATOR: 'trial_indicator',
  TRUNCATE_EXCESS_PAID_BREAKS: 'truncate_excess_paid_breaks',
  UPCOMING: 'upcoming',
  UPDATE_TEAM_DRAWER: 'update_team_drawer',
  VIEW: 'view',
  VIEW_EDIT_HISTORY: 'Manager views edit history',
  WAGEFORECAST: 'wageforecast',
  WATCH_VIDEO_LINK_MODAL: 'watch_video_link_modal',
  WEEK_AVAILABILITIES_FILTER: 'week_availabilities_filter',
  WELCOME_TEXT_CALLBACK_SUCCESS: 'welcome_text_callback_success',
  WELCOME_TEXT_CALLBACK_EXPIRED_LINK: 'welcome_text_callback_expired_link',
  WELCOME_TEXT_CALLBACK_OUTSIDE_BUSINESS_HOURS:
    'welcome_text_callback_outside_business_hours',
  WHITELISTING_PAGE: 'whitelisting_page',
  W_4_FORM: 'w_4_form',
  W_9_FORM: 'w_9_form',
  YOUR_ACCOUNT: 'your_account',
  ZERO_STATE_PAGE: 'zero_state_page',
};

export const EVENT_ACTIONS = {
  TIMECARD_CLICKED: 'Timecard Clicked',
  ADD_AVAILABILITY_CLICKED: 'Add Availability Clicked',
  ADD_BREAK_CLICKED_V3: 'Add break clicked_v3',
  ADD_DOCUMENT_CLICKED: 'Add Document Clicked',
  ADD_DROPDOWN_CLICKED: 'Add Dropdown Clicked',
  ADD_EMPLOYEES_CLICKED: 'Add Employees Clicked',
  ADD_EMPLOYEE_SELECTED: 'Add Employee Selected',
  ADD_EVENT_CLICKED: 'Add Event Clicked',
  ADD_EVENT_CTA_CLICKED: 'Add Event CTA Clicked',
  ADD_EVENT_ERROR_MESSAGE_SHOWN: 'Add Event Error Message Shown',
  ADD_EVENT_MODAL_CLOSED: 'Add Event Modal Closed',
  ADD_EVENT_MODAL_SHOWN: 'Add Event Modal Shown',
  ADD_EVENT_SELECTED: 'Add Event Selected',
  ADD_EVENT_SUCCESS_ALERT_SHOWN: 'Add Event Success Alert Shown',
  ADD_EVENT_SUCCESS_BANNER: 'Add Event Success Banner Shown',
  ADD_NEW_DEPARTMENT_CLICKED: 'Add New Department Clicked',
  ADD_HOURS_AND_BREAKS_AS_SCHEDULED_CLICKED:
    'Add hours and breaks as scheduled clicked',
  ADD_NEW_LOCATION_CLICKED: 'Add New Location Clicked',
  ADD_PAYROLL_PROVIDER_CLICKED: 'Add Payroll Provider Clicked',
  ADD_REASON_FOR_A_NO_SHOW_CLICKED_V3: 'Add a reason for a no-show clicked_v3',
  ADD_SHIFT_CLICKED: 'Add Shift Clicked',
  ADD_TEAM_DRAWER_SHOWN: 'Add Team Drawer Shown',
  ADD_TIME_OFF_BLACKOUT_CTA_CLICKED: 'Add Time Off Blackout CTA Clicked',
  ADD_TIME_OFF_SELECTED: 'Add Time Off Selected',
  ADD_EARNINGS_WITH_RECURRING_POTENTIAL:
    'Add Earnings with Recurring Potential',
  ALERT_CLOSED: 'Alert Closed',
  ALERT_ICON_CLICKED: 'Alert Icon Clicked',
  ALERT_VIEWED: 'Alert Viewed',
  ALERTS_AND_PERMISSIONS_SHOWN: 'Alerts and Permissions Shown',
  ALL_ACCESS_PASS_CLICKED: 'All Access Pass Clicked',
  API_ACCESS_SHOWN: 'API Access Shown',
  APPLY_CTA_CLICKED: 'Apply CTA Clicked',
  APPLY_TEMPLATE_CLICKED: 'Apply Template Clicked',
  APPLY_TEMPLATE_CTA_CLICKED: 'Apply Template CTA Clicked',
  APP_STORE_CLICKED: 'App Store Clicked',
  AUTO_SCHEDULE_INFO_CTA_CLICKED: 'Auto Schedule Info CTA Clicked',
  AUTO_SCHEDULE_INFO_MODAL_CLOSED: 'Auto Schedule Info Modal Closed',
  AUTO_SCHEDULE_INFO_MODAL_SHOWN: 'Auto Schedule Info Modal Shown',
  AVAILABILITY_REQUEST_APPROVED: 'Availability Request Approved',
  AVAILABILITY_REQUEST_DECLINED: 'Availability Request Declined',
  BACK_CTA_CLICKED: 'Back CTA Clicked',
  BACK_CLICKED: 'Back Clicked',
  BANNER_CLICKED: 'Banner Clicked',
  BANNER_CLOSED: 'Banner Closed',
  BANNER_VIEWED: 'Banner Viewed',
  BANNER_SHOWN: 'Banner Shown',
  BASELINE_CLOSE_CLICKED: 'Baseline Close Clicked',
  BASELINE_DOWNLOAD_CLICKED: 'Baseline Download Clicked',
  BASELINE_SHOWN: 'Baseline Shown',
  BASIC_INFO_SHOWN: 'Basic Info Shown',
  BETA_FEEDBACK_MODAL_SHOWN: 'Beta Feedback Modal Shown',
  BOTTOM_DRAWER_VIEWED: 'Bottom Drawer Viewed',
  BREAK_AND_COMPLIANCE_SHOWN: 'Break and Compliance Shown',
  BREAK_DETAILS_EXPANDED_V3: 'Break details expanded_v3',
  BULK_ADD_ADD_EMPLOYEES_CLICKED: 'Bulk Add Add Employees Clicked',
  BULK_ADD_CHOICES_SHOWN: 'Bulk Add Choices Shown',
  BULK_ADD_CLICKED: 'Bulk Add Clicked',
  BULK_ADD_SPREADSHEET_SHOWN: 'Bulk Add Spreadsheet Shown',
  BULK_ADD_WAGES_SETTINGS_SHOWN: 'Bulk Add Wages Settings Shown',
  BULK_TERMINATE_CHOOSE_EMPLOYEES_SHOWN:
    'Bulk Terminate Choose Employees Shown',
  BUTTON_CLICKED: 'Button Clicked',
  CALL_CLICKED_V3: 'Call clicked_v3',
  CANCEL_SAVE_CLICKED: 'Cancel Save Clicked',
  CARD_CLICKED: 'Card Clicked',
  CARET_CLICKED: 'Caret Clicked',
  CHECK_ALL_IN_ONE_CLICKED: 'Check All-in-One clicked',
  CHECKBOX_CLICKED: 'Checkbox Clicked',
  CHECKBOX_SELECTED: 'Checkbox Selected',
  CHECKBOX_UNSELECTED: 'Checkbox Unselected',
  CHECK_OUT_PAYROLL_CLICKED: 'Check Out Payroll Clicked',
  CHECK_OUT_PAYROLL_SHOWN: 'Check Out Payroll Shown',
  CHIP_CLICKED: 'Chip Clicked',
  CLAIM_SHIFT_CLICKED: 'Claim Shift Clicked',
  CLEAR_CLICKED: 'Clear Clicked',
  CLEAR_ENTIRE_SCHEDULE_SELECTED: 'Clear Entire Schedule Selected',
  CLEAR_UNPUBLISHED_CHANGES_SELECTED: 'Clear Unpublished Changes Selected',
  CLICK_CTA: 'click_cta',
  CLICKED_SCHEDULE_DEMO: 'Clicked Schedule Demo',
  CLICKED_TRIAL: 'Clicked Trial',
  CLICKED_UPGRADE_DOCUMENTS: 'Clicked Upgrade Documents',
  CLICKED_UPGRADE_HR_PRO: 'Clicked Upgrade HR Pro',
  CLICKED_UPGRADE_NEW_HIRE_ONBOARDING: 'Clicked Upgrade New Hire Onboarding',
  CLOSE_CLICKED: 'Close Clicked',
  COMMAND_BAR_LOADED: 'Command Bar Loaded',
  COMMON_SHIFT_CLICKED: 'Common Shift Clicked',
  COMPONENT_VIEWED: 'Component Viewed',
  COMPANY_DASHBOARD_SHOWN: 'Company Dashboard Shown',
  CONFIRM_SELECTION_CLICKED: 'Confirm Selection Clicked',
  CONFLICTS_TOGGLE_BUTTON_CLICKED: 'Conflicts Toggle Button Clicked',
  CONFLICT_MODAL_CLOSED: 'Conflict Modal Closed',
  CONFLICT_MODAL_OPENED: 'Conflict Modal Opened',
  CONTINUE_CLICKED: 'Continue Clicked',
  CONTINUE_SETUP_CLICKED: 'Continue Setup Clicked',
  CONTINUE_SETUP_SHOWN: 'Continue Setup Shown',
  COPY_EVENT_CLCKED: 'Copy Event Clicked',
  COPY_MODE_EXITED: 'Copy Mode Exited',
  COPY_SHIFTS_CANCEL_CLICKED: 'Copy Shifts Cancel Clicked',
  COPY_SHIFTS_CONFIRMATION_CLICKED: 'Copy Shifts Confirmation Clicked',
  COPY_SHIFTS_DROPDOWN_CLICKED: 'Copy Shifts Dropdown Clicked',
  COPY_SHIFTS_MODAL_CLOSED: 'Copy Shifts Modal Closed',
  COPY_SHIFTS_MODAL_SHOWN: 'Copy Shifts Modal Shown',
  COPY_SHIFTS_WEEK_SELECTED: 'Copy Shifts Week Selected',
  COPY_SHIFT_CLICKED: 'Copy Shift Clicked',
  CREATE_TEMPLATE_CLICKED: 'Create Template Clicked',
  CREDIT_CARD_VERIFIED: 'Credit Card Verified',
  CTA_CLICKED: 'cta_clicked',
  DATE_ARROW_CLICKED: 'Date Arrow Clicked',
  DATE_PERIOD_OPTION_CLICKED: 'Date Period Option Clicked',
  DATE_PICKER_CLICKED: 'Date Picker Clicked',
  DATE_SELECTION_CLICKED: 'Date Selection Clicked',
  DEBUG_CHECKOUT_STARTED: 'Debug Checkout Started', // DO NOT REUSE - FOR TEMPORARY DEBUGGING PURPOSES ONLY
  DEBUG_CHECKOUT_COMPLETED: 'Debug Checkout Completed', // DO NOT REUSE - FOR TEMPORARY DEBUGGING PURPOSES ONLY
  DELETE_CANCEL_CLICKED: 'Delete Cancel Clicked',
  DELETE_CLICKED: 'Delete Clicked',
  DELETE_COMPANY_CLICKED: 'Delete Company Clicked',
  DELETE_CONFIRM_CLICKED: 'Delete Confirm Clicked',
  DELETE_CONFIRMATION_MODAL_SHOWN: 'Delete Confirmation Modal Shown',
  DELETE_DEPARTMENT_CLICKED: 'Delete Department Clicked',
  DELETE_EVENT_CLICKED: 'Delete Event Clicked',
  DELETE_SHIFT_CLICKED: 'Delete Shift Clicked',
  DELETE_TEMPLATE_CANCEL_CLICKED: 'Delete Template Cancel Clicked',
  DELETE_TEMPLATE_CLICKED: 'Delete Template Clicked',
  DELETE_TEMPLATE_CONFIRM_CLICKED: 'Delete Template Confirm Clicked',
  DELETE_TIMECARD_CLICKED_V3: 'Delete timecard clicked_v3',
  DELETE_TIMECARD_BREAK_CLICKED_V3: 'Delete timecard break clicked_v3',
  DISABLE_CLICKED: 'Disable Clicked',
  DISMISS_BREAK_CLICKED_V3: 'Dismiss break clicked_v3',
  DISMISS_CLICKED: 'Dismiss Clicked',
  DIALOG_VIEWED: 'Dialog Viewed',
  DIALOG_CTA_CLICKED: 'Dialog CTA Clicked',
  DIALOG_CLOSED: 'Dialog Closed',
  DOCUMENTS_TAB_CLICKED: 'Documents Tab Clicked',
  DONE_CLICKED: 'Done Clicked',
  DONE_CTA_CLICKED: 'Done CTA Clicked',
  DOWNGRADE_SCHEDULED: 'Downgrade Scheduled',
  DOWNLOAD_ALL_PAY_STATEMENTS_CLICKED: 'Download All Pay Statements Clicked',
  DOWNLOAD_CLICKED: 'Download Clicked',
  DOWNLOAD_FORMATTED_CLICKED: 'Download Formatted Clicked',
  DOWNLOAD_COMPANY_TIMESHEETS_SUMMARY_CLICKED:
    'Download Company Timesheets Summary Clicked',
  DOWNLOAD_COMPANY_TIMESHEETS_BY_LOCATION_CLICKED:
    'Download Company Timesheets By Location Clicked',
  DOWNLOAD_MESSAGE_LOG_CLICKED: 'Download Message Log Clicked',
  DRAWER_CLOSED: 'Drawer Closed',
  DRAWER_SHOWN: 'Drawer Shown',
  DROPDOWN_CLICKED: 'Dropdown Clicked',
  EDIT_CLICKED: 'Edit Clicked',
  EDIT_CLICKED_V3: 'Edit clicked_v3',
  EDIT_COLUMNS_CLICKED: 'Edit Columns Clicked',
  EDIT_DEPARTMENT_NAME_CLICKED: 'Edit Department Name Clicked',
  EDIT_EVENT_CLICKED: 'Edit Event Clicked',
  EDIT_HISTORY_CLICKED_V3: 'Edit history clicked_v3',
  EDIT_QUESTIONS_SHOWN: 'Edit Questions Shown',
  EDIT_SHIFT_CLICKED: 'Edit Shift Clicked',
  EDIT_TEMPLATE_NAME_CHANGED: 'Edit Template Name Changed',
  EDIT_TEMPLATE_NAME_CLICKED: 'Edit Template Name Clicked',
  EMPLOYEE_ORDER_CHANGED: 'Employee Order Changed',
  EMPLOYEE_SEARCH_CLICKED: 'Employee Search Clicked',
  EMPLOYEE_TOTALS_CLICKED: 'Employee Totals Clicked',
  EMPLOYEE_AVATAR_CLICKED: 'Employee Avatar Clicked',
  ENABLE_CLICKED: 'Enable Clicked',
  END_HIRING_VIDEO: 'end_hiring_video',
  ENFORCEMENT_DRAWER_REMOVE_TEAM_MEMBERS_CLICKED:
    'Enforcement Drawer Remove Team Members Clicked',
  ENFORCEMENT_STAY_ON_PLAN_CLICKED: 'Enforcement Drawer Stay On Plan Clicked',
  ENTER_TAX_ID_CLICKED: 'Enter Tax ID clicked',
  ERROR_MESSAGE_SHOWN: 'Error Message Shown',
  EVENT_DATE_FOCUSED: 'Event Date Focused',
  EVENT_DESCRIPTION_FOCUSED: 'Event Description Focused',
  EVENTS_TRADES_SHOWN: 'Events and Trades Shown',
  EVENTS_QUOTA_LIMIT_REACHED: 'Events Quota Limit Reached',
  EVENT_TITLE_FOCUSED: 'Event Title Focused',
  EXISTING_TEMPLATE_CLICKED: 'Existing Template Clicked',
  EXITING_TEMPLATE_CLICKED: 'Existing Template Clicked',
  EXPORT: 'export',
  EXPORT_CLICKED: 'Export Clicked',
  EXPORT_COMPANY_TIMESHEETS_CLICKED: 'Export Company Timesheets Clicked',
  EXPORT_REPORT_CLICKED: 'Export Report Clicked',
  FAB_BUTTON_CLICKED: 'Fab Button Clicked',
  FIELD_FOCUSED: 'Field Focused',
  FINAL_PAGE_SHOWN: 'Final Page Shown',
  FIND_JOB_APPLICANTS_CLICKED: 'Find job applicants clicked',
  FIRST_RUN_PREVIEW_SHOWN: 'First Run Preview Shown',
  FLOW_RENDERED: 'Flow Rendered',
  FORM_SHOWN: 'Form Shown',
  FORWARD_CLICKED: 'Forward Clicked',
  GET_A_TIME_CLOCK_SHOWN: 'Get a Time Clock Shown',
  GET_APP_CLICKED: 'Get App Clicked',
  GET_APP_DISMISSED: 'Get App Dismissed',
  GET_IT_LATER_CLICKED: 'Get It Later Clicked',
  GET_MOBILE_APP_CLICKED: 'Get Mobile App Clicked',
  GET_MOBILE_APP_MODAL_SHOWN: 'Get Mobile App Modal Shown',
  GET_MOBILE_APP_MODAL_SUBMITTED: 'Get Mobile App Modal Submitted',
  GET_PLUS_PLAN_CLICKED: 'Get Plus Plan Clicked',
  GET_STARTED_CLICKED: 'Get Started Clicked',
  HEALTH_TAB_CLICKED: 'Health Tab Clicked',
  HIDE_AVAILABILITIES_CLICKED: 'Hide Availabilities Clicked',
  HIDE_EVENTS_CLICKED: 'Hide Events Clicked',
  HIDE_HOURS_FORECAST_CLICKED: 'Hide Hours Forecast Clicked',
  HIDE_PEOPLE_FORECAST_CLICKED: 'Hide People Forecast Clicked',
  HIDE_SCHEDULE_EMPLOYEES_CLICKED: 'Hide Schedule Employees Clicked',
  HIDE_TIME_OFFS_CLICKED: 'Hide Time-Offs Clicked',
  HIDE_WAGES_FORECAST_CLICKED: 'Hide Wages Forecast Clicked',
  HIRING_SURVEY_NEW_EMPLOYEE_ADDED: 'Hiring Survey New Employee Added',
  HISTORY_ITEM_COUNT_CLICKED: 'History Item Count Clicked',
  HOMEBASE_TAB_CLICKED: 'Homebase Tab Clicked',
  HOTSPOT_CLICKED: 'Hotspot Clicked',
  ICON_CLICKED: 'Icon Clicked',
  ICON_BUTTON_CLICKED: 'Icon Button Clicked',
  INCEPTION_CLICKED: 'Inception Clicked',
  INVITE_MODAL_SHOWN: 'Invite Modal Shown',
  INITIAL_ACTIVATION: 'Initial Activation',
  JUMPSTART_PAST_WEEK_SUCCESS_BANNER_SHOWN:
    'Jumpstart - Past Week Success Banner Shown',
  JUMPSTART_TEMPLATE_SUCCESS_BANNER_SHOWN:
    'Jumpstart - Template Success Banner Shown',
  LABOR_TARGET_UPDATED: 'Labor Target Updated',
  LAUNCH_WEEK_AWARD_STARTED: 'Launch Week Award Started',
  LAUNCH_WEEK_AWARD_COMPLETED: 'Launch Week Award Completed',
  LAUNCH_WEEK_SHARE_STARTED: 'Launch Week Share Started',
  LINK_CLICKED: 'Link Clicked',
  LOCATION_CLICKED: 'Location Clicked',
  LOCATION_DROPDOWN_CLICKED: 'Location Dropdown Clicked',
  LOCATIONS_PINS_SHOWN: 'Locations and PINs Shown',
  MAKE_A_DIFFERENT_EDIT_CLICKED_V3: 'Make a different edit clicked_v3',
  MANAGER_VIEW_DASHBOARD_SHOWN: 'Manager View Dashboard Shown',
  MANAGE_PLAN_CLICKED: 'Manage Plan Clicked',
  MANAGE_TEMPLATES_DRAWER_CLOSED: 'Manage Templates Drawer Closed',
  MANAGE_TEMPLATES_DRAWER_OPENED: 'Manage Templates Drawer Opened',
  MANAGE_YOUR_EXPERIENCE_MODAL_SHOWN: 'Manage Your Experience Modal Shown',
  MANAGER_LOG_SHOWN: 'Manager Log Shown',
  MANUALLY_ADD_CLICKED: 'Manually Add Clicked',
  MAX_EMPLOYEES_FOCUSED: 'Max Employees Focused',
  MESSAGE_CLICKED_V3: 'Message clicked_v3',
  MESSAGES_TAB_CLICKED: 'Messages Tab Clicked',
  MISSING_CONTACT_INFO_CLICKED: 'Missing Contact Info Clicked',
  MOBILE_TIME_CLOCK_TEXT_ME_LINK_CLICKED:
    'Mobile Time Clock Text Me Link Clicked',
  MODAL_SHOWN: 'Modal Shown',
  MODAL_VIEWED: 'Modal Viewed',
  MODAL_CLICKED: 'Modal Clicked',
  MOVE_TO_OPEN_SHIFTS_CLICKED: 'Move To Open Shifts Clicked',
  MY_WEEK_DASHBOARD_SHOWN: 'My Week Dashboard Shown',
  NEW_CLICKED: 'New Clicked',
  NEW_HIRE_PACKET: 'New Hire Packet',
  NEW_LOCATION_ADDED: 'New Location Added',
  NEXT_MONTH_ARROW_CLICKED: 'Next Month Arrow Clicked',
  NOT_READY_TO_SIGN_UP_CLICKED: 'Not Ready to Sign Up Clicked',
  NOTES_HISTORY_CLICKED_V3: 'Notes history clicked_v3',
  NOTIFICATIONS_DROPDOWN_CLICKED: 'Notifications Dropdown Clicked',
  NOTIFICATIONS_OPTIONS_SELECTED: 'Notifications Option Selected',
  NOTIFICATIONS_SHOWN: 'Notifications Shown',
  NOTIFY_MANAGER_CLICKED: 'Notify Manager Clicked',
  OPEN_SHIFT_CLAIM_APPROVED: 'Open Shift Claim Approved',
  OPT_OUT_MODAL_SHOWN: 'Opt Out Modal Shown',
  OPT_IN_MODAL_SHOWN: 'Opt In Modal Shown',
  OPTIONS_MENU: 'Options Menu',
  ORDER_CHANGED: 'Order Changed',
  OTHER_INTERVAL_SELECTED: 'Other Interval Selected',
  OVERFLOW_CLICKED: 'Overflow Clicked',
  OVERLAY_CLICKED: 'Overlay Clicked',
  OVERTIME_SHOWN: 'Overtime Shown',
  OWNER_SIGN_UP: 'Owner Sign Up',
  PAGE_VIEWED: 'Page Viewed',
  PARTNER_ADD_CLICKED: 'Partner Add Clicked',
  PARTNER_PAYROLL_IMPORT_CLICKED: 'Partner Payroll Import Clicked',
  PARTNER_POS_IMPORT_CLICKED: 'Partner POS Import Clicked',
  PAST_WEEK_OPTION_CLICKED: 'Past Week Option Clicked',
  PAUSE_HIRING_VIDEO: 'pause_hiring_video',
  PAY_PERIOD_CLICKED: 'Pay Period Clicked',
  PAYROLL_SHOWN: 'Payroll Shown',
  PAYWALL_SHOWN: 'Paywall Shown',
  PLAN_AND_BILLING_SHOWN: 'Plan and Billing Shown',
  PLAY_HIRING_VIDEO: 'play_hiring_video',
  POS_CONNECTION_SHOWN: 'POS Connection Shown',
  POST_A_JOB_CLICKED: 'post_a_job_clicked',
  PQL_ADD_DEPARTMENT: 'PQL_AddDepartment',
  PRE_DETERMINED_INTERVAL_SELECTED: 'Pre-determined Interval Selected',
  PREVIOUS_MONTH_ARROW_CLICKED: 'Previous Month Arrow Clicked',
  PRINT_CLICKED: 'Print Clicked',
  PRINT_DRAWER_SHOWN: 'Print Drawer Shown',
  PRINT_OPTION_CLICKED: 'Print Option Clicked',
  PROFILE_CLICKED: 'Profile Clicked',
  PROFILE_SHOWN: 'Profile Shown',
  PUBLISH_BUTTON_CLICKED: 'Publish Button Clicked',
  PUBLISH_CLICKED: 'Publish Clicked',
  PUBLISH_CLICKED_BETA: 'Publish Clicked Beta',
  PUBLISH_HISTORY_MODAL_CLOSED: 'Publish History Modal Closed',
  PUBLISH_HISTORY_MODAL_SHOWN: 'Publish History Modal Shown',
  PUBLISH_HISTORY_SELECTED: 'Publish History Selected',
  PUBLISH_SCHEDULE_DRAWER_CLOSED: 'Publish Schedule Drawer Closed',
  PUBLISH_SCHEDULE_DRAWER_SHOWN: 'Publish Schedule Drawer Shown',
  PUBLISH_SUCCESS_DRAWER_CLOSED: 'Publish Success Drawer Closed',
  PUBLISH_SUCCESS_DRAWER_SHOWN: 'Publish Success Drawer Shown',
  RADIO_BUTTON_CLICKED: 'Radio Button Clicked',
  REATTEMPT_WITHDRAWAL_CLICKED: 'Re-attempt Withdrawal Clicked',
  RECOMMENDATION_CLICKED: 'Recommendation Clicked',
  REPEAT_CANCEL_CLICKED: 'Repeat Cancel Clicked',
  REPEAT_INTERVAL_DROPDOWN_CLICKED: 'Repeat Interval Dropdown Clicked',
  REPEAT_INTERVAL_SELECTED: 'Repeat Interval Selected',
  REPEAT_SCHEDULE_CLICKED: 'Repeat Schedule Clicked',
  REPEAT_SCHEDULE_CTA_CLICKED: 'Repeat Schedule CTA Clicked',
  REPEAT_SCHEDULE_DRAWER_CLOSED: 'Repeat Schedule Drawer Closed',
  REPEAT_SCHEDULE_DRAWER_SHOWN: 'Repeat Schedule Drawer Shown',
  REPEAT_SCHEDULE_SUCCESS_BANNER_SHOWN: 'Repeat Schedule Success Banner Shown',
  REPEAT_SCHEDULE_VIEW_SHOWN: 'Repeat Schedule View Shown',
  REQUEST_COVER_CLICKED: 'Request Cover Clicked',
  REQUEST_TIME_OFF_CLICKED: 'Request Time Off Clicked',
  RESEND_SCHEDULE_NOTIFICATIONS_SELECTED:
    'Resend Schedule Notifications Selected',
  RESOLVE_CLICKED: 'Resolve Clicked',
  RETRIEVE_COMPANY_TIMESHEETS_DATE_RANGE_CLICKED:
    'Retrieve Company Timesheets Date Range Clicked',
  RIGHT_PANEL_VIEWED: 'Right panel Viewed',
  SAVE_AND_ADD_ANOTHER_CLICKED: 'Save and Add Another Clicked',
  SAVE_AND_CLOSE_CLICKED: 'Save and Close Clicked',
  SAVE_AVAILABILITY_CLICKED: 'Save Availability Clicked',
  SAVE_EVENT_CLICKED: 'Save Event Clicked',
  SAVE_EVENT_CTA_CLICKED: 'Save Event Clicked',
  SAVE_SHIFT_CLICKED: 'Save Shift Clicked',
  SAVE_WITH_CONFLICTS_CLICKED: 'Save With Conflicts Clicked',
  SCHEDULE_ENFORCEMENT_SHOWN: 'Schedule Enforcement Shown',
  SCHEDULE_PUBLISHED: 'Schedule Published',
  SCHEDULE_TAB_CLICKED: 'Schedule Tab Clicked',
  SECTION_VIEWED: 'Section Viewed',
  SEND_PACKET_CLICKED: 'Send Packet Clicked',
  SET_PASSWORD_CLICKED: 'Set Password Clicked',
  SET_PASSWORD_SHOWN: 'Set Password Shown',
  SET_UP_BUTTON_CLICKED: 'Set Up Button Clicked',
  SET_UP_PAYROLL_CLICKED: 'Set Up Payroll Clicked',
  SETTINGS_CLICKED: 'Settings Clicked',
  SETTINGS_TAB_CLICKED: 'Settings Tab Clicked',
  SHIFT_APPROVAL_MODAL_SHOWN: 'Shift Approval Modal Shown',
  SHIFT_CHANGED: 'Shift Changed',
  SHIFT_COVER_REQUEST_APPROVED: 'Shift Cover Request Approved',
  SHIFT_COVER_REQUEST_DECLINED: 'Shift Cover Request Declined',
  SHIFT_MODAL_CLOSED: 'Shift Modal Closed',
  SHIFT_MODAL_VIEWED: 'Shift Modal Viewed',
  SHIFT_NOTES_LINK_CLICKED: 'Shift Notes Link Clicked',
  SHIFT_NOTES_QUOTA_LIMIT_REACHED: 'Shift Notes Quota Limit Reached',
  SHIFT_PASTED: 'Shift Pasted',
  SHIFT_TRADE_REQUEST_APPROVED: 'Shift Trade Request Approved',
  SHIFT_TRADE_REQUEST_DECLINED: 'Shift Trade Request Declined',
  SHOW_AVAILABILITIES_CLICKED: 'Show Availabilities Clicked',
  SHOW_EVENTS_CLICKED: 'Show Events Clicked',
  SHOW_HOURS_FORECAST_CLICKED: 'Show Hours Forecast Clicked',
  SHOW_PEOPLE_FORECAST_CLICKED: 'Show People Forecast Clicked',
  SHOW_SCHEDULE_EMPLOYEES_CLICKED: 'Show Schedule Employees Clicked',
  SHOW_TIME_OFFS_CLICKED: 'Show Time-Offs Clicked',
  SHOW_WAGES_FORECAST_CLICKED: 'Show Wages Forecast Clicked',
  SHOWN: 'Shown',
  SIDE_DRAWER_VIEWED: 'Side Drawer Viewed',
  SIGNUP_1D1_CONVERSION: 'signup_1d1_conversion',
  SIGNUP_2D7_CONVERSION: 'signup_2d7_conversion',
  SIGNUP_2D30_CONVERSION: 'signup_2d30_conversion',
  SIGN_IN_SUCCESS: 'Sign In Success',
  SIGNATURE_CLICK: 'Signature Click',
  SMS_CHECKBOX_CLICKED: 'SMS Checkbox Clicked',
  SORT_BY_CUSTOM_SORT_CLICKED: 'Sort By Custom Sort Clicked',
  SORT_BY_FIRST_NAME_CLICKED: 'Sort By First Name Clicked',
  SORT_BY_LAST_NAME_CLICKED: 'Sort By Last Name Clicked',
  START_FREE_TRIAL: 'Start Free Trial Clicked',
  STEP_CLICKED: 'Step Clicked',
  STEP_REACHED: 'Step Reached',
  SWITCH_CLICKED: 'Switch Clicked',
  SWITCHER_CLICKED: 'Switcher Clicked',
  SWITCHER_DROPDOWN_CLICKED: 'Switcher Dropdown Clicked',
  TAB_CLICKED: 'tab_clicked',
  TAB_SHOWN: 'tab_shown',
  TABLET_TIME_CLOCK_EMAIL_ME_LINK_CLICKED:
    'Tablet Time Clock Email Me Link Clicked',
  TEAM_MEMBER_CLICKED: 'Team Member Clicked',
  TEAM_MESSENGER_SHOWN: 'Team Messenger Shown',
  TEAM_PERMISSIONS_SHOWN: 'Team Permissions Shown',
  TEAM_TAB_CLICKED: 'Team Tab Clicked',
  TEMPLATES_SELECTED: 'Templates Selected',
  TEMPLATE_DRAWER_CLOSED: 'Template Drawer Closed',
  TEMPLATE_DRAWER_OPENED: 'Template Drawer Opened',
  TEMPLATE_DROPDOWN_CLICKED: 'Template Dropdown Clicked',
  TEMPLATE_OPTION_CLICKED: 'Template Option Clicked',
  TEMPLATE_SELECTED: 'Template Selected',
  TIME_CLOCK_OPTIONS_SHOWN: 'Time Clock Options Shown',
  TIME_OFF_BLACKOUT_CLICKED: 'Time Off Blackout Clicked',
  TIME_OFF_BLACKOUT_MODAL_CLOSED: 'Time Off Blackout Modal Closed',
  TIME_OFF_BLACKOUT_MODAL_SHOWN: 'Time Off Blackout Modal Shown',
  TIME_OFF_CLICKED: 'Time Off Clicked',
  TIME_OFF_END_DATE_FOCUSED: 'Time Off End Date Focused',
  TIME_OFF_POPPER_SHOWN: 'Time Off Popper Shown',
  TIME_OFF_PTO_SHOWN: 'Time Off and PTO Shown',
  TIME_OFF_START_DATE_FOCUSED: 'Time Off Start Date Focused',
  TIMECARD_NOTE_ADDED_V3: 'Timecard note added_v3',
  TIMESHEET_SUMMARY_PAYROLL_LINK_CLICKED:
    'Timesheet Summary Payroll Link Clicked',
  TIMESHEETS_TAB_CLICKED: 'Timesheets Tab Clicked',
  TIMESHEETS_TAB_SHOWN: 'Timesheets Tab Shown',
  TIP_POOLING_VIEWED: 'Tip pooling settings viewed',
  TIP_SETTINGS_VIEWED: 'Tip settings viewed',
  TODAY_BUTTON_CLICKED: 'Today Button Clicked',
  TOGGLE_CLICKED: 'Toggle Clicked',
  TOOLS_DROPDOWN_CLICKED: 'Tools Dropdown Clicked',
  TOOLTIP_VIEWED: 'Tooltip Viewed',
  TOOLTIP_DISMISSED: 'Tooltip Dismissed',
  TRADE_SHIFT_CLICKED: 'Trade Shift Clicked',
  UPGRADE_BUTTON_FROM_EDIT_HISTORY_V3: 'Upgrade button from Edit History_v3',
  UPGRADE_BUTTON_FROM_NOTES_PAYWALL_CLICKED_V3:
    'Upgrade button from Notes paywall clicked_v3',
  UPGRADE_BUTTON_FROM_PHOTO_GPS_PAYWALL_CLICKED_V3:
    'Upgrade button from Photo & GPS paywall clicked_v3',
  UPGRADE_SUCCESSFUL: 'Upgrade Successful',
  UPLOAD_PAPER_COPY_CLICKED: 'Upload Paper Copy Clicked',
  VALIDATION_ERROR_SHOW: 'Validation Error Shown',
  VIDEO_CLICKED: 'Video Clicked',
  VIDEOASK_INTERACTION: 'VideoAsk Interaction',
  VIDEOASK_SHOWN: 'VideoAsk Shown',
  VIEW: 'view',
  VIEW_ALL_TIMESHEETS_CLICKED: 'View All Timesheets Clicked',
  VIEW_DOCUMENT_CLICKED: 'View Document Clicked',
  VIEW_HIRING_DASHBOARD: 'View Hiring Dashboard',
  VIEW_PAYROLL_CLICKED: 'View Payroll Clicked',
  VIEW_REQUESTS_CLICKED: 'View Requests Clicked',
  VIEW_SAMPLE_TIME_CARD_CLICKED: 'View Sample Time Card Clicked',
  VIEWED: 'Viewed',
  WEB_TIME_CLOCK_LAUNCH_CLICKED: 'Web Time Clock Launch Clicked',
  WHITE_BACKGROUND_AND_BLACK_TEXT_CLICKED:
    'White Background and Black Text Clicked',
  WIRE_FUNDS_CLICKED: 'Wire Funds Clicked',
  WITH_COLORED_BORDERS_CLICKED: 'With Colored Borders Clicked',
  WITH_FULL_COLOR_BACKGROUND_CLICKED: 'With Full Color Background Clicked',
  ZERO_STATE_SHOWN: 'Zero State Shown',
};

// Consult data team before adding any more track action types here
export const TRACK_ACTION_TYPES = {
  CLICK: 'click',
  VIEW: 'view',
  FOCUS: 'focus',
  BLUR: 'blur',
  HOVER: 'hover',
  DRAG: 'drag', // covers drag & drop as one action
};

export const DEPRECATED_GROWTH_EVENT_ACTIONS = {
  SAVE: 'Save',
};

export const PG_TRACK_EVENTS = {
  RETRIAL_ONBOARDING_REMINDER_MODAL: 'retrial_onboarding_reminder_modal',
  RETRIAL_ONBOARDING_ENROLLMENT: 'retrial_onboarding_enrollment',
  SHIFT_NOTE_DISCOVERABILITY: 'shift_note_discoverability',
  IN_TRIAL_UPGRADE_BANNER_DISMISS: 'in_trial_upgrade_banner_dismiss',
  SOFT_PAYWALL_GEOFENCE: 'soft_paywall_geofence',
  GEOFENCE_CLOCKIN_TOOLTIP: 'geofence_clockin_tooltip',
  GEOFENCE_OVERLAY: 'geofence_overlay',
  SCHEDULE_SMS_TOOLTIP_DISMISSED: 'schedule_sms_tooltip_dismissed',
};

export const TRACK_EVENTS = {
  ADD_LOCATION: 'add_location',
  PUBLISH_SCHEDULE_RETRIAL_MODAL: 'publish_schedule_retrial_modal',
  ADD_EMPLOYEE_RETRIAL_MODAL: 'add_employee_retrial_modal',
  TIERS_PAGE: 'tiers_page',
  UPSELL_INTERRUPT_TIMESHEET_EXPORT: 'upsell_interrupt_timesheet_export',
  ZIP_RECRUITER: 'zip_recruiter',
  CS_CONTACT: 'cs_contact',
  TIMESHEETS_FOOTER_ALERT: 'timesheets_footer_alert',
  DASHBOARD_PRODUCT_USAGE_WIDGET: 'dashboard_product_usage_widget',
  DASHBOARD_PRODUCT_USAGE_WIDGET_DISMISSED:
    'dashboard_product_usage_widget_dismissed',
  MONETIZATION_UPSELL_ON_TIMESHEET_COMPLIANCE:
    'monetization_upsell_on_timesheet_compliance',
  TEAM_ANNOUNCEMENTS: 'Team Announcements',
  HIRING_BOOST_POPUP: 'hiring_boost_popup',
  UPSELL_WIDGET_ON_TIMESHEETS_PAGE: 'upsell_widget_on_timesheets_page',
  UPSELL_INTERRUPT_PUBLISH_SCHEDULE: 'upsell_interrupt_publish_schedule',
  RETRIAL_ONBOARDING: 'retrial_onboarding',
  JOB_POST_SUCCESS: 'job_post_success',
  HR_PRO: 'hr_pro',
  USER_WEB_TIMECLOCK: 'user_web_timeclock',
  HIRING_DASHBOARD: 'hiring_dashboard',
  HIRING_DASHBOARD_V1: 'hiring_dashboard_v1',
  HIRING_JOB_POST_FLOW: 'Job Post Flow',
  APPLICATION_QUESTIONS: 'Application Questions',
  HIRING_MANAGE_APPLICANTS: 'Manage Applicants',
};

export const TRACK_SOURCES = {
  BANNER: 'banner',
  MODAL: 'modal',
  SETTINGS: 'settings',
  DROPDOWN: 'dropdown',
  MARKETING_SITE: 'from_marketing_site',
  ADD_SCREEN: 'add_screen',
  LOCATION_SUCCESS: 'location_success',
  DEPARTMENTS: 'departments',
};

export const TRACK_ACTIONS = {
  CLICKED: 'clicked',
  DISMISS: 'dismiss',
  SUCCESS: 'success',
  DEPARTMENT_SUCCESS: 'department_sucess',
  LOCATION_SUCCESS: 'location_sucess',
  AD_CLICKED: 'ad_clicked',
  AD_VIEWED: 'ad_viewed',
  CLICKED_ANNUAL_BILLING: 'clicked_annual_billing',
  CLICKED_CLOSED: 'clicked_closed',
  CLICKED_CTA: 'clicked_cta',
  CLICKED_GET_MORE_APPLICANTS_BUTTON: 'clicked_get_more_applicants_button',
  CLICKED_HOMEBASE_ESSENTIALS_PLAN: 'clicked_homebase_essentials_plan',
  CLICKED_LEARN_MORE: 'clicked_learn_more',
  CLICKED_LEARN_MORE_ON_TIMESHEET_COMPLIANCE:
    'clicked_learn_more_on_timesheet_compliance',
  CLICKED_MAKE_APPOINTMENT: 'clicked_make_appointment',
  CLICKED_CRAIGSLIST_SINGLE_TRANSACTION:
    'clicked_craigslist_single_transaction',
  CLICKED_UPGRADE: 'clicked_upgrade',
  CLICKED_UPGRADE_NOW: 'clicked_upgrade_now',
  CLICKED_ZIP_RECRUITER_SINGLE_TRANSACTION:
    'clicked_zip_recruiter_single_transaction',
  CLICKED_VIEW_STATUS_ON_INDEED: 'clicked_view_status_on_indeed',
  VIEW: 'view',
  VIEWED_HIRING_DASHBOARD: 'viewed_hiring_dashboard',
  VIEWED_PAGE: 'viewed_page',
  VIEWED_UPSELL: 'viewed_upsell',
  VIEWED: 'viewed',
  CLICKED_ADD_LOCATION: 'clicked_add_location',
  CLICKED_STEP_ONE_CONTINUE: 'clicked_step_one_continue',
  CLICKED_STEP_TWO_ESSENTIALS: 'clicked_step_two_essentials',
  CLICKED_STEP_TWO_PLUS: 'clicked_step_two_plus',
  CLICKED_STEP_TWO_ENTERPRISE: 'clicked_step_two_enterprise',
  CLICKED_STEP_FOUR_CONTINUE: 'clicked_step_four_continue',
  CLICKED_SEE_ALL_FEATURES_INCLUDED_IN_PLUS:
    'clicked_see_all_features_included_in_plus',
  CLICKED_REMIND_ME_LATER: 'clicked_remind_me_later',
  CLICKED_NEED_HELP: 'clicked_need_help',
  CLICKED_DONT_NEED_SCHEDULING: 'clicked_dont_need_scheduling',
  APPOINTMENT: 'appointment',
  CHAT: 'chat',
  ADD: 'add',
  WORKSITE: 'worksite',
  LOCATION: 'location',
  CLICKED_BACK: 'clicked_back',
  VIEW_MODAL: 'view_modal',
  CLICKED_VIEW_JOB_POST_HERE: 'clicked_view_job_post_here',
  CLICKED_VIEW_MY_JOBS: 'clicked_view_my_jobs',
  CLICKED_DOWNLOAD_QR_SIGN: 'clicked_download_qr_sign',
  SHARE_ON_TEAM_MESSENGER: 'share_on_team_messenger',
  SSO_CARD_CLICKED: 'sso_card_clicked',
  LAUNCHED: 'launched',
  CLOSED: 'closed',
  CLICKED_CONTINUE_SYNDICATION_PAGE: 'Clicks Continue',
  VIEWED_SYNDICATION_PAGE: 'Viewed Syndication Page',
  CLICKED_OPTION_SYNDICATION_PAGE: 'Select/Deselects Syndication Options',
  VIEWED_APPLICATION_QUESTIONS_PAGE: 'Viewed',
  CLICKED_ADD_APPLICATION_QUESTIONS_PAGE: 'Clicked Add',
  CLICKED_REMOVE_APPLICATION_QUESTIONS_PAGE: 'Clicked Remove',
  CLICKED_SKIP_APPLICATION_QUESTIONS_PAGE: 'Clicked Skip',
  CLICKED_SUBMIT_APPLICATION_QUESTIONS_PAGE: 'Clicked Submit',
  CLICKED_CANCEL: 'Clicked Cancel',
  CLICKED_SETTINGS: 'Clicked Settings',
  CLICKED_ADD_TIME_OFF: 'Clicked Add Time Off',
  CLICKED_APPROVE_TIME_OFF: 'Clicked Approve Time Off',
  CLICKED_DECLINE_TIME_OFF: 'Clicked Decline Time Off',
  CLICKED_CREATE_PTO_POLICY: 'Clicked Create Policy',
  CLICKED_EDIT_PTO_POLICY: 'Clicked Edit Policy',
  CLICKED_DELETE_PTO_POLICY: 'Clicked Delete Policy',
};

export const TRACK_PROPERTIES = {
  APPLICANTS_LEARN_MORE: 'applicants_learn_more',
  EXPORT: 'export',
  HIRING_JOB_POST: 'job_post',
  HIRING_GET_MORE_APPLICANTS: 'get_more_applicants',
  RETRIAL_MODAL: 'retrial_modal',
  DELETE: 'delete',
  EMPTY_CELL: 'empty_cell',
  EVENT: 'event',
  RETRIAL_MODAL_2: 'retrial_modal_2',
  CONFIRMATION_MODAL: 'confirmation_modal',
  REOFFER_RETRIAL_MODAL: 'reoffer_retrial_modal',
  SEND_FEEDBACK: 'send_feedback',
  SHIFT: 'shift',
  TEXT_EMPLOYEE_SCHEDULES: 'text_employee_schedules',
  TIME_OFF_MANAGEMENT: 'time_off_management',
  PREVENT_EARLY_CLOCK_INS: 'prevent_early_clock_ins',
  GPS_TIME_CLOCK: 'gps_time_clock',
  TIMECARD_STORAGE: 'timecard_storage',
  PAYROLL_BANNER_DISMISSED: 'Payroll Banner Dismissed',
  PAYROLL_BANNER_REMIND_ME_TOMORROW: 'Payroll Banner Remind me tomorrow',
  PAYROLL_BANNER_GO_TO_PAYROLL: 'Go to Payroll',
  PAYROLL_BANNER_ADD_TO_CALENDAR: 'Payroll_add_to_calendar',
  PAYROLL_BANNER_ADD_TO_ICAL: 'Payroll_add_to_calendar_ical',
  PAYROLL_BANNER_ADD_TO_GOOGLE: 'Payroll_add_to_calendar_google',
  PAYROLL_BANNER_ADD_TO_OUTLOOK: 'Payroll_add_to_calendar_outlook',
};

export const TRACK_CATEGORY = {
  PAYING: 'paying',
  NON_PAYING: 'non-paying',
  VIA_NAV_ICON: 'via nav icon',
  VIA_WEB_TIMECLOCK: 'via /web_timeclock',
  NONE: 'none',
  CLOCKED_IN: 'clocked in',
  CLOCKED_OUT: 'clocked out',
  CLOCKED_IN_BREAK: 'clocked in break',
  CLOCKED_OUT_BREAK: 'clocked out break',
  ROLE_CARD: 'role_card',
  ROLE_CARD_CUSTOM: 'role_card_custom',
  WATCH_VIDEO_LINK_MODAL: 'watch_video_link_modal',
  TEAM_ANNOUNCEMENTS_CARD: 'Team Announcements Card',
  HIRING_SYNDICATION_PAGE: 'Syndication Page',
  APPLICATION_QUESTIONS_PAGE: 'Create Application Questions Page',
  EDIT_APPLICATION_QUESTIONS_PAGE: 'Edit Application Questions Page',
  WHITELISTING_PAGE: 'Whitelisting Page',
};

export const TRACK_STARTING_PATHS = {
  DASHBOARD: 'dashboard',
  DASHBOARD_V1: 'dashboard v1',
  DASHBOARD_V3: 'dashboard v3',
  TIMESHEETS: 'timesheets',
  ALERTS: 'alerts',
  TEAM: 'team',
  TEAM_AVAILABILITY: 'team availability',
  SCHEDULE: 'schedule',
  VIEW_APPLICANTS: 'view applicants',
  DOCUMENTS: 'documents',
  TIME_OFFS: 'time off',
};

export const URL_STARTING_PATHS = {
  '/team': TRACK_STARTING_PATHS.TEAM,
  '/availabilities': TRACK_STARTING_PATHS.TEAM_AVAILABILITY,
  '/schedule_builder': TRACK_STARTING_PATHS.SCHEDULE,
  '/hiring/job_requests/:id/manage_applicants':
    TRACK_STARTING_PATHS.VIEW_APPLICANTS,
  '/documents': TRACK_STARTING_PATHS.DOCUMENTS,
  '/dashboard/location': TRACK_STARTING_PATHS.DASHBOARD,
};

export const LINKED_IN_EVENT_IDS = {
  owner_signup: 6144114,
};

export const ELEMENTS = {
  ADD_NOTES_BUTTON: 'add_notes_button',
  BOOST_MODAL: 'boost_modal',
  BOOST_NOW_BUTTON: 'boost_now_button',
  CANCEL_BUTTON: 'cancel_button',
  CASHOUT_JOB_DESCRIPTION_CHECKBOX: 'cashout_job_description_checkbox',
  CREATE_YOUR_OWN_CARD: 'create_your_own_card',
  DECLINE_BUTTON: 'decline_button',
  DECLINE_FIRST_TIME_INFORMATIONAL: 'decline_first_time_informational',
  DECLINE_REASON_BUTTON: 'decline_reason_button',
  DECLINE_REASON_MODAL: 'decline_reason_modal',
  DISCOVER_NOW_BUTTON: 'discover_now_button',
  DONT_SHOW_AGAIN_BUTTON: 'dont_show_again_button',
  ENTER_NEW_CREDIT_CARD_LINK: 'enter_new_credit_card_link',
  GET_MORE_APPLICANTS_BUTTON: 'get_more_applicants_button',
  GOT_IT_BUTTON: 'got_it_button',
  HIRING_DASHBOARD_LINK: 'hiring_dashboard_link',
  HOMEBASE_JOB_BOARD_LINK: 'homebase_job_board_link',
  INTERVIEW_BUTTON: 'interview_button',
  INTERVIEW_DATE_TIME: 'interview_date_time',
  INTERVIEW_FIRST_TIME_INFORMATIONAL: 'interview_first_time_informational',
  INTERVIEW_SCHEDULING_TYPE: 'interview_scheduling_type',
  JOB_ALERTS_LOCATION_OPTIN: 'job_alerts_location_optin',
  JOB_ALERTS_ROLE_OPTIN: 'job_alerts_role_optin',
  JOB_CARD_TITLE: 'job_card_title',
  LAUNCH_WEEK_VIEW_AWARD: 'view_award',
  LAUNCH_WEEK_UNLOCK_AWARD: 'unlock_award',
  LAUNCH_WEEK_DO_IT_LATER: 'do_it_later',
  LAUNCH_WEEK_STEP_LABELS: {
    OAM_NAME: 'oam_name',
    AMAZING_WORKPLACE: 'amazing_workplace',
    STOOD_OUT: 'stood_out',
    HIGHLIGHTS: 'highlights',
    SERVE_COMMUNITY: 'serve_community',
    HOURS_OF_WORK: 'hours_of_work',
    NUMBER_OF_SHIFTS: 'number_of_shifts',
    MADE_WORK_FUN: 'made_work_fun',
    NUMBER_OF_MESSAGES: 'number_of_messages',
    WORK_FLEXIBLE: 'work_flexible',
    HOW_YOU_DO_IT: 'how_you_do_it',
    TOP_LOCAL_WORKPLACE: 'top_local_workplace',
    HOLD_ON: 'hold_on',
    JOHN_VIDEO: 'john_video',
  },
  LAUNCH_WEEK_SHARE_YOUR_AWARD: 'share_your_award',
  LEARN_MORE_LINK: 'learn_more_link',
  MAYBE_LATER_BUTTON: 'maybe_later_button',
  NEXT_BUTTON: 'next_button',
  OLD_PREMIUM_BOOST_NOT_AVAILABLE_MODAL:
    'old_premium_boost_not_available_modal',
  PREMIUM_BOOST_NOT_AVAILABLE_MODAL: 'premium_boost_not_available_modal',
  REACTIVATE_BUTTON: 'reactivate_button',
  REQUEST_ADD_APPLICANT_BUTTON: 'request_add_applicant_button',
  ROLE_CARD: 'role_card',
  SCHEDULE_INTERVIEW_BUTTON: 'schedule_interview_button',
  STATUS_DROPDOWN: 'status_dropdown',
  SUBMIT_BUTTON: 'submit_button',
  THUMBS_DOWN_BUTTON: 'thumbs_down_button',
  THUMBS_UP_BUTTON: 'thumbs_up_button',
  UNPASS_THUMBS_DOWN_BUTTON: 'unpass_thumbs_down_button',
  WEB_TO_APP_CTA: 'web_to_app_cta',
  WHATS_NEW_BUTTON: 'whats_new_button',
};

export const PARTNERS = {
  BOOST_CRAIGSLIST: 'boost_craigslist',
  BOOST_ZIP_RECRUITER: 'boost_ziprecruiter',
};

export const MODAL_TYPES = {
  EXIT_CONFIRMATION: 'exit_confirmation',
};
