import { createSelector } from 'reselect';

import {
  getCanManageTier,
  getCurrentUserIsManagerial,
} from 'selectors/session';

import {
  selectCurrentLocationTier,
  selectIsPastDueAfterDeadline,
  selectPreSetPastDueDialogInitialViewKey,
} from 'features/biller/selectors';
import { selectShouldShowEnforcementDialogOnNavigation } from 'features/enforcement/EnforcementBanner/selectors';
import {
  selectDisplayDialog,
  selectDisplayMultilocationEnforcementDialog,
} from 'features/enforcement/selectors';

import { getInitialViewKey } from './getInitialViewKey';

// ACL between selectors composition and getInitialViewKey
// Those arguments from createSelector are positional, touch with care
export const selectInitialPastDueDialogViewKey = createSelector(
  selectDisplayMultilocationEnforcementDialog,
  selectShouldShowEnforcementDialogOnNavigation,
  getCanManageTier,
  selectPreSetPastDueDialogInitialViewKey,
  selectCurrentLocationTier,
  selectIsPastDueAfterDeadline,
  selectDisplayDialog,
  getCurrentUserIsManagerial,
  (
    displayMultilocationEnforcementDialog,
    shouldShowEnforcementDialogOnNavigation,
    canManageTier,
    preSetInitialViewKey,
    currentLocationTier,
    isPastDueAfterDeadline,
    displayDialog,
    isCurrentUserManagerial
  ) =>
    getInitialViewKey({
      displayDialog,
      canManageTier,
      currentLocationTier,
      preSetInitialViewKey,
      isPastDueAfterDeadline,
      isCurrentUserManagerial,
      displayMultilocationEnforcementDialog,
      shouldShowEnforcementDialogOnNavigation,
    })
);
