import React, { useCallback, useEffect } from 'react';
import { postJSON } from 'api/fetch';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button/Button';
import PhoneField from 'fe-design-base/molecules/PhoneField';

import { isCtcCtaDisabled } from 'features/monetization/util';

import CalendlyModal from 'components/CalendlyModal/CalendlyModal';
import { useCalendlyModalHandlers } from 'components/CalendlyModal/hooks';

import * as flashNotice from 'util/flashNotice';
import { toI18n } from 'util/i18n';
import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

import { CLICK_TO_CALL_VIEW_KEY as VIEW_KEY } from '../constants';

import CalendlyLink, { CALENDLY_URL } from './CalendlyLink';
import ModalViewTracker from './ModalViewTracker';
import { ViewType, ViewTypeProps } from './types';

const WithinBusinessHoursRequest: ViewType = ({
  dirty,
  values,
  isValid,
  talkdeskId,
  isSubmitting,
  setDialogTitle,
  isPrimaryLoading,
  setCurrentViewKey,
  setIsPrimaryLoading,
}: ViewTypeProps) => {
  const { calendlyModalIsOpen, handleCalendlyClick, handleCalendlyClose } =
    useCalendlyModalHandlers();

  const viewName = VIEW_KEY.within_business_hours_request;
  const onSuccessViewKey = VIEW_KEY.within_business_hours_success;
  const buttonText = toI18n(
    'monetization.click_to_call.within_business_hours.request.cta'
  );

  const trackUx = useTrackUx({
    button_text: buttonText,
  } as never);

  useEffect(() => {
    setDialogTitle(
      toI18n('monetization.click_to_call.within_business_hours.request.title')
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClickPrimary = useCallback(() => {
    trackUx(EVENT_ACTIONS.BUTTON_CLICKED, TRACK_ACTION_TYPES.CLICK);
    setIsPrimaryLoading(true);

    const payload = {
      phone: values.phone,
      source: 'click_to_call',
      talkdesk_id: talkdeskId,
    };

    postJSON('/monetization/click_to_call/request_support', payload)
      .then(() => setCurrentViewKey(onSuccessViewKey))
      .catch(() => flashNotice.error(toI18n('errors.generic')))
      .finally(() => setIsPrimaryLoading(false));
  }, [
    trackUx,
    talkdeskId,
    values.phone,
    onSuccessViewKey,
    setCurrentViewKey,
    setIsPrimaryLoading,
  ]);

  return (
    <ModalViewTracker viewName={viewName}>
      <Box mb={24}>
        <Text
          variant="body"
          i18n="monetization.click_to_call.within_business_hours.request.subtitle"
        />
      </Box>
      <Box mb={10}>
        <Text variant="bodyBold">
          {toI18n(
            'monetization.click_to_call.within_business_hours.request.where_should_we_call_you'
          )}{' '}
          <Text color="error300" inline>
            *
          </Text>
        </Text>
        <Box mt={20}>
          <PhoneField
            name="phone"
            width="340px"
            uxElement="phone_number"
            placeholder="e.g. (628) 204-3443"
          />
        </Box>
      </Box>
      <CalendlyLink onClickLink={handleCalendlyClick} />
      <Box hright>
        <Button
          isLoading={isPrimaryLoading}
          onClick={handleOnClickPrimary}
          disabled={isCtcCtaDisabled({ dirty, isValid, isSubmitting, values })}
        >
          {buttonText}
        </Button>
      </Box>
      <CalendlyModal
        url={CALENDLY_URL}
        open={calendlyModalIsOpen}
        handleCloseModal={handleCalendlyClose}
      />
    </ModalViewTracker>
  );
};

export default WithinBusinessHoursRequest;
