import React, { useCallback } from 'react';
import useId from '@mui/material/utils/useId';
import FormField, { FormFieldProps } from 'fe-design-base/atoms/FormField';
import Select, { SelectProps } from 'fe-design-base/atoms/Select';
import { Field, FormikValues, useFormikContext } from 'formik';

export interface SelectFieldProps
  extends Omit<FormFieldProps, 'children'>,
    SelectProps {}

const SelectField = ({
  name,
  label,
  inputId,
  width,
  fullWidth,
  labelPosition,
  labelWidth,
  shrink,
  disabled,
  hasAsterisk,
  helperText,
  successText,
  ...selectProps
}: SelectFieldProps) => {
  const fieldId = useId();
  const { touched, errors, setFieldValue } = useFormikContext<FormikValues>();
  const error = touched?.[name] && errors?.[name] && !disabled;

  const handleCancel = useCallback(
    // TODO: DB https://joinhomebase.atlassian.net/browse/FE-2199
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    initialValue => {
      setFieldValue(name, initialValue);
    },
    [name, setFieldValue]
  );

  return (
    <FormField
      name={name}
      inputId={inputId || fieldId}
      width={width}
      fullWidth={fullWidth}
      label={label}
      labelPosition={labelPosition}
      labelWidth={labelWidth}
      shrink={shrink}
      disabled={disabled}
      uxElement={selectProps.uxElement}
      hasAsterisk={hasAsterisk}
      helperText={helperText}
      successText={successText}
    >
      <Field
        as={Select}
        name={name}
        label={label}
        id={inputId || fieldId}
        disabled={disabled}
        error={error}
        onCancel={handleCancel}
        {...selectProps}
      />
    </FormField>
  );
};

export default SelectField;
