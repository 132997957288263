import React, { useEffect } from 'react';

import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUxHandler } from 'util/uxEvents';

interface ModalViewTrackerProps {
  children: React.ReactNode;
  viewName: string;
}

type ModalViewTrackerType = React.FC<ModalViewTrackerProps>;

const ModalViewTracker: ModalViewTrackerType = ({ children, viewName }) => {
  const handlePageViewTracking = useTrackUxHandler(
    EVENT_ACTIONS.PAGE_VIEWED,
    TRACK_ACTION_TYPES.VIEW,
    { view_name: viewName, event_name: 'Click to Call' }
  );

  useEffect(() => {
    handlePageViewTracking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default ModalViewTracker;
