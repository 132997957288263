import './Table.scss';

import React, { PureComponent } from 'react';
import { Column, Table as ReactVirtualizedTable } from 'react-virtualized';
import PropTypes from 'prop-types';

import cxHelpers from 'util/className';

export const SIMPLE_TABLE_HEADER_HEIGHT = 50;
export const DEFAULT_TABLE_HEADER_HEIGHT = 30;

@cxHelpers('Table')
export default class Table extends PureComponent {
  static propTypes = {
    getRef: PropTypes.func,
    simple: PropTypes.bool,
    autoHeight: PropTypes.bool,
    white: PropTypes.bool,
    padFirstColumn: PropTypes.bool,
  };

  rowClassName = ({ index }) => {
    const { simple, white } = this.props;
    return this.cxEl(index > -1 ? 'row' : 'header', { simple, white });
  };

  render() {
    const { simple, autoHeight, white, getRef, padFirstColumn, ...props } =
      this.props;

    let headerHeight;
    if (autoHeight) {
      headerHeight = null;
    } else {
      headerHeight = simple
        ? SIMPLE_TABLE_HEADER_HEIGHT
        : DEFAULT_TABLE_HEADER_HEIGHT;
    }

    return (
      <ReactVirtualizedTable
        rowClassName={this.rowClassName}
        headerClassName={this.cxEl('header-cell', { simple })}
        headerHeight={headerHeight}
        {...props}
        ref={getRef}
        className={this.cx({
          simple,
          white,
          'pad-first-column': padFirstColumn,
        })}
      />
    );
  }
}

export { Column };
