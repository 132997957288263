import React, { useCallback, useEffect } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button/Button';
import Link from 'fe-design-base/molecules/Link';

import { toI18n } from 'util/i18n';
import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

import { CLICK_TO_CALL_VIEW_KEY as VIEW_KEY } from '../constants';

import ModalViewTracker from './ModalViewTracker';
import { ViewType, ViewTypeProps } from './types';

const formatPhoneNumber = (phoneNumber?: string | null) => {
  if (!phoneNumber) return '';
  const areaCode = phoneNumber.slice(0, 3);
  const firstPart = phoneNumber.slice(3, 6);
  const secondPart = phoneNumber.slice(6, 10);
  return `${areaCode}-${firstPart}-${secondPart}`;
};

const WithinBusinessHoursSuccess: ViewType = ({
  values,
  setReload,
  setDialogTitle,
  setCurrentViewKey,
  closeClickToCallDialog,
}: ViewTypeProps) => {
  const viewName = VIEW_KEY.within_business_hours_success;
  const buttonText = toI18n(
    'monetization.click_to_call.within_business_hours.success.missed_a_call.link'
  );
  const trackUx = useTrackUx({
    button_text: buttonText,
  } as never);

  useEffect(() => {
    setDialogTitle(
      toI18n('monetization.click_to_call.within_business_hours.success.title')
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClickPrimary = useCallback(() => {
    closeClickToCallDialog();
  }, [closeClickToCallDialog]);

  const handleOnClickLink = useCallback(() => {
    trackUx(EVENT_ACTIONS.LINK_CLICKED, TRACK_ACTION_TYPES.CLICK);
    setReload(true);
    setDialogTitle('');
    setCurrentViewKey(VIEW_KEY.within_business_hours_request);
  }, [setCurrentViewKey, setDialogTitle, setReload, trackUx]);

  return (
    <ModalViewTracker viewName={viewName}>
      <Box mb={24} data-testid="subtitle">
        <Text variant="body" asHTML>
          {toI18n(
            'monetization.click_to_call.within_business_hours.success.subtitle',
            {
              props: { phone: formatPhoneNumber(values.phone) },
            }
          )}
        </Text>
      </Box>
      <Box mb={24}>
        <Text variant="body">
          {toI18n(
            'monetization.click_to_call.within_business_hours.success.missed_a_call.text'
          )}{' '}
          <Link endIcon="ArrowRight" onClick={handleOnClickLink}>
            {buttonText}
          </Link>
        </Text>
      </Box>
      <Box hright>
        <Button onClick={handleOnClickPrimary}>
          {toI18n(
            'monetization.click_to_call.within_business_hours.success.cta'
          )}
        </Button>
      </Box>
    </ModalViewTracker>
  );
};

export default WithinBusinessHoursSuccess;
