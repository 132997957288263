import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import { padStart } from 'lodash';

const CountDownDigits = ({ label, value }) => {
  const formattedDuration = duration => padStart(duration.toString(), 2, '0');

  return (
    <Box flex alignItemsCenter gap={2}>
      <Box w={27}>
        <Text variant="heading3">{formattedDuration(value)}</Text>
      </Box>
      <Text i18n={`switch_to_payroll_banner.countdown_timer.${label}`} />
    </Box>
  );
};

export default CountDownDigits;
