import { createSlice } from '@reduxjs/toolkit';
import { fromJS } from 'immutable';

import { SLICE_NAME } from './constants';

export const initialState = fromJS({
  sidePanelType: null,
  initiatedFrom: null,
});

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    openSidePanel: (
      state,
      { payload: { sidePanelType, initiatedFrom = null } }
    ) =>
      state
        .set('sidePanelType', sidePanelType)
        .set('initiatedFrom', initiatedFrom),
    closeSidePanel: state =>
      state.set('sidePanelType', null).set('initiatedFrom', null),
  },
});

export const { actions, reducer } = slice;
