import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as navigationRoutes from 'api/navigation';
import PropTypes from 'prop-types';

import * as ftuActions from 'actions/ftu';

import * as ftuSelectors from 'selectors/ftu';

import Box from 'components/Box';
import Button from 'components/clickables/Button';
import withCurrentRoute from 'components/hocs/withCurrentRoute';
import OverlayModal from 'components/modals/OverlayModal';
import { Paragraph } from 'components/Text';

import cxHelpers from 'util/className';

@withCurrentRoute
@connect(
  (state, props) => ({
    isOpen: ftuSelectors.getShowPayAnywhereTimesheetsModal(state, props),
  }),
  {
    onClose: ftuActions.closePayAnywhereTimesheetsModal,
  }
)
@cxHelpers('PayAnywhereTimesheetsModal')
export default class PayAnywhereTimesheetsModal extends PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
  };

  handleClose = () => {
    this.props.onClose();
  };

  handleCloseAndLink = () => {
    this.props.onClose();
    window.Homebase.RailsReactBridge.navigateToReactView('/web_timeclock');
  };

  modalActive = () =>
    this.props.currentRoute === navigationRoutes.timesheetsRoute();

  render() {
    if (!this.modalActive()) {
      return <div />;
    }
    return (
      <OverlayModal
        fullHeight
        isOpen={this.props.isOpen}
        onRequestClose={this.handleClose}
        className={this.cx(null, 'bootstrap')}
        preventScroll
        ariaHideApp={false}
      >
        <Box
          pam
          column
          hcenter
          vcenter
          tcenter
          mha
          maxw={600}
          mt={150}
          borderbox
        >
          <Paragraph
            fs30
            fw700
            white
            i18n="ftu.pay_anywhere.timesheets.welcome"
          />
          <Paragraph
            fs18
            mbs
            white
            i18n="ftu.pay_anywhere.timesheets.from_here"
          />
          <Paragraph
            fs18
            mbm
            white
            i18n="ftu.pay_anywhere.timesheets.quick_tour"
          />
          <Box mvm w="100%">
            <Button
              fullWidth
              size="large"
              theme="primary"
              i18n="ftu.pay_anywhere.timesheets.ok"
              onClick={this.handleClose}
            />
          </Box>
          <Button
            theme="link"
            i18n="ftu.pay_anywhere.timesheets.cta"
            onClick={this.handleCloseAndLink}
          />
        </Box>
      </OverlayModal>
    );
  }
}
