import React from 'react';
import Text from 'fe-design-base/atoms/Text';

import Footer from '../components/Footer';
import Header from '../components/Header';
import PastDueDialogBox from '../components/PastDueDialogBox';

import { GracePeriodViewProps } from './types';

export const GracePeriodView = ({
  deadline,
  onClickPrimary,
  primaryCtaText,
  currentTierName,
  primaryUxElement,
}: GracePeriodViewProps) => (
  <PastDueDialogBox>
    <Header
      titleI18n="past_due_subscription.dialog.grace_period.title"
      iconColor="orange300"
      iconName="Warning"
    />
    <Text
      variant="body"
      color="mono900"
      i18n="past_due_subscription.dialog.grace_period.description"
      i18nProps={{
        deadline,
        currentTierName,
      }}
      asHTML
    />
    <Footer
      onClickPrimary={onClickPrimary}
      primaryCtaText={primaryCtaText}
      primaryUxElement={primaryUxElement}
    />
  </PastDueDialogBox>
);

export default GracePeriodView;
