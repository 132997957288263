import moment from 'moment';

import {
  BASIC_TIER_MAX_PAYROLL_DAYS,
  DATE_TIME_FORMAT,
  MAX_PAYROLL_MONTH_SELECT,
  MAX_VAL_LENGTH,
} from 'features/timesheets/constants';

import { df, momentInZone } from 'util/dateTime';
import { toI18n } from 'util/i18n';

export const updateMomentDate = (originalTime, newTime) => {
  if (!moment.isMoment(originalTime) || !moment.isMoment(newTime)) {
    return originalTime;
  }

  const updatedTime = originalTime.clone();
  updatedTime.date(newTime.date());
  updatedTime.month(newTime.month());
  updatedTime.year(newTime.year());
  return updatedTime;
};

export const toMoment = string => moment(string, DATE_TIME_FORMAT);
export const fromMoment = time => time.format(DATE_TIME_FORMAT);

export const overnightShiftDateAdjustment = (shiftStartAt, time) => {
  if (shiftStartAt && time) {
    const shiftStartTime = toMoment(shiftStartAt);
    const dateTime = toMoment(time);

    if (shiftStartTime > dateTime) {
      const newDate = shiftStartTime.clone().add(1, 'day');
      const updatedTime = updateMomentDate(dateTime, newDate);
      return fromMoment(updatedTime);
    }
  }
  return time;
};

export const duration = (minutes, args = {}) => {
  const options = {
    withSpace: true,
    hoursFormat: 'hr',
    minutesFormat: 'min',
    ...args,
  };

  const spaceChar = options.withSpace ? ' ' : ''; // In some areas we need to show 9hrs 16min without spaces

  if (minutes < 60) {
    return `${parseInt(minutes, 10)}${spaceChar}${options.minutesFormat}`;
  }
  const hours = parseInt(minutes / 60, 10);
  const mins = Math.round(minutes % 60);

  return `${hours}${spaceChar}${options.hoursFormat} ${mins}${spaceChar}${options.minutesFormat}`;
};

const PAID = toI18n('timecard_modal.timebreak_row.paid');
const UNPAID = toI18n('timecard_modal.timebreak_row.unpaid');

export const mandatedBreakText = timebreak => {
  const break_duration = duration(
    timebreak.get('break_duration') || timebreak.get('duration')
  );
  const translation = timebreak.get('paid') ? PAID : UNPAID;
  return `${break_duration} ${translation}`;
};

/* moment returns 0 for the first month, adding +1
to simply make this semantically correct */
const noOfMonths = (startDate, endDate) =>
  moment(endDate).diff(moment(startDate), 'months') + 1;

export const exceedsMonthLimit = (startDate, endDate) =>
  noOfMonths(startDate, endDate) > MAX_PAYROLL_MONTH_SELECT;

const noOfDays = (startDate, endDate = moment()) =>
  moment(endDate).diff(moment(startDate), 'days');

export const exceedsBasicTierMaxPayrollDays = startDate =>
  noOfDays(startDate) > BASIC_TIER_MAX_PAYROLL_DAYS;

export const parseDate = dateString => moment(dateString, df('parsable_date'));

export const calculateLaborPercentage = (sales, costs) => {
  if (!sales && !costs) {
    return null;
  }

  const laborValue = parseFloat(
    (costs || 0).toString().replace(/[^0-9.]/g, '')
  );
  const salesValue = parseFloat(
    (sales || 0).toString().replace(/[^0-9.]/g, '')
  );

  if (salesValue === 0) {
    return `100%`;
  }
  return `${((laborValue / salesValue) * 100).toFixed(2)}%`;
};

export const showPayrollSettingsModal = () => {
  new window.HomeBase.Views.Timesheets.PayrollSettingsPopupView({
    onSaveReloadReactTimesheets: true,
  }).render();
};

export const isAfterToday = dateString =>
  momentInZone() < momentInZone(dateString);

export const filterApprovableRowIds = rowGroup =>
  rowGroup.map(row =>
    isAfterToday(row.get('date')) || !row.get('timecard') ? null : row.get('id')
  );

export const stringToBeTruncated = (
  value,
  column_key,
  maxValLength = MAX_VAL_LENGTH
) =>
  column_key !== 'timecard' &&
  typeof value === 'string' &&
  value.length > maxValLength;

export const getTimesheetsTabValueFromUrl = url => {
  if (url.includes('pay_period_review')) return 'pay_period_review';
  return 'daily_review';
};

export const getHasTimeCards = tableData => {
  const rowsLengths = tableData.map(data => data.rows.length);
  return rowsLengths.some(length => length > 0);
};
