import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import { browserHistory } from 'util/router';

const withReduxProvider =
  WrappedComponent =>
  ({ store }) =>
    (
      <Provider store={store}>
        <Router history={browserHistory}>
          <WrappedComponent />
        </Router>
      </Provider>
    );

export default withReduxProvider;
