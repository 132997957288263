import React from 'react';
import { connect } from 'react-redux';
import Dialog from 'fe-design-base/organisms/Dialog';

import { getCurrentLocationTierName } from 'selectors/session';

import { actions } from 'features/biller';
import {
  selectCurrentLocationTier,
  selectIsSubscribedAnnually,
  selectLocationBiller,
  selectLocationId,
  selectPastDueDeadline,
  selectPreviousTierName,
  selectShouldShowPastDueSubscriptionDialog,
} from 'features/biller/selectors';
import {
  actions as billerActions,
  changeToNewTier,
} from 'features/biller/slice';
import { selectedBillingCycle } from 'features/managePlan/util';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';
import { EVENT_CATEGORIES, PRODUCT_AREAS } from 'util/tracking_constants';
import {
  useExtendUxPartition,
  withUxPartition,
  withUxRoot,
} from 'util/uxEvents';

import {
  selectDaysLeftBeforeDowngrade,
  selectIsPastDueAfterDeadline,
} from '../../biller/selectors';
import {
  dismissPastDueSubscriptionModal as getDismissPastDueSubscriptionModal,
  noChange as getNoChange,
} from '../actions';

import AfterGracePeriodNonBillingPermissionsView from './views/AfterGracePeriodNonBillingPermissionsView';
import AfterGracePeriodView from './views/AfterGracePeriodView';
import EnforcedLocationView from './views/EnforcedLocationView';
import GracePeriodView from './views/GracePeriodView';
import NonBillingPermissionsView from './views/NonBillingPermissionsView';
import RestoredToPaidView from './views/RestoredToPaidView';
import StayOnBasicView from './views/StayOnBasicView';
import { PAST_DUE_DIALOG_VIEW_KEY } from './constants';
import { selectInitialPastDueDialogViewKey } from './selectInitialPastDueDialogViewKey';
import { PastDueSubscriptionDialogProps } from './types';
import { usePastDueSubscriptionDialog } from './usePastDueSubscriptionDialog';

export const VIEW = {
  [PAST_DUE_DIALOG_VIEW_KEY.grace_period]: GracePeriodView,
  [PAST_DUE_DIALOG_VIEW_KEY.stay_on_basic]: StayOnBasicView,
  [PAST_DUE_DIALOG_VIEW_KEY.restored_to_paid]: RestoredToPaidView,
  [PAST_DUE_DIALOG_VIEW_KEY.enforced_location]: EnforcedLocationView,
  [PAST_DUE_DIALOG_VIEW_KEY.after_grace_period]: AfterGracePeriodView,
  [PAST_DUE_DIALOG_VIEW_KEY.grace_period_non_billing]:
    NonBillingPermissionsView,
  [PAST_DUE_DIALOG_VIEW_KEY.after_grace_period_non_billing]:
    AfterGracePeriodNonBillingPermissionsView,
};

const { cx } = cxHelpers('PastDueSubscriptionDialog');
const primaryCtaText = toI18n('past_due_subscription.primary_cta');
const afterGracePeriodPrimaryCtaText = toI18n(
  'past_due_subscription.primary_cta'
);
const primaryUxElement = 'update-card-cta';

export const PastDueSubscriptionDialog = ({
  biller,
  deadline,
  noChange,
  previousTierName,
  currentLocationId,
  onChangeToNewTier,
  closePastDueDialog,
  currentLocationTier,
  isSubscribedAnnually,
  onChangePaymentMethod,
  isPastDueAfterDeadline,
  setDecidedToStayOnBasic,
  daysLeftBeforeDowngrade,
  initialPastDueDialogViewKey,
  setIsBillingModalSourcePastDue,
  dismissPastDueSubscriptionModal,
}: PastDueSubscriptionDialogProps) => {
  const {
    isLoading,
    CurrentView,
    setIsLoading,
    hideCloseIcon,
    currentViewKey,
    currentTierName,
    setCurrentViewKey,
    handleClickOnClose,
    handleChangePaymentMethod,
    sanitizedPreviousTierName,
  } = usePastDueSubscriptionDialog({
    VIEW,
    biller,
    previousTierName,
    onChangeToNewTier,
    currentLocationId,
    closePastDueDialog,
    currentLocationTier,
    selectedBillingCycle,
    isSubscribedAnnually,
    onChangePaymentMethod,
    isPastDueAfterDeadline,
    initialPastDueDialogViewKey,
    setIsBillingModalSourcePastDue,
    dismissPastDueSubscriptionModal,
  });

  const extendUxPartition = useExtendUxPartition();

  if (currentViewKey === PAST_DUE_DIALOG_VIEW_KEY.hide) {
    return null;
  }

  extendUxPartition({
    deadline,
    current_view_key: currentViewKey,
    current_tier_name: currentTierName,
    is_subscribed_annually: isSubscribedAnnually,
    previous_tier_name: sanitizedPreviousTierName,
    is_past_due_after_deadline: isPastDueAfterDeadline,
    days_left_before_downgrade: daysLeftBeforeDowngrade,
  });

  return (
    <Dialog
      open
      noPadding
      className={cx()}
      disableBackdropClick
      uxElement="past-due-dialog"
      onClose={handleClickOnClose}
      hideCloseIcon={hideCloseIcon}
    >
      {() => (
        <CurrentView
          noChange={noChange}
          deadline={deadline}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          currentTierName={currentTierName}
          primaryUxElement={primaryUxElement}
          setCurrentViewKey={setCurrentViewKey}
          closePastDueDialog={closePastDueDialog}
          onClickPrimary={handleChangePaymentMethod}
          previousTierName={sanitizedPreviousTierName}
          setDecidedToStayOnBasic={setDecidedToStayOnBasic}
          dismissPastDueSubscriptionModal={dismissPastDueSubscriptionModal}
          primaryCtaText={
            isPastDueAfterDeadline
              ? afterGracePeriodPrimaryCtaText
              : primaryCtaText
          }
        />
      )}
    </Dialog>
  );
};

export default connect(
  state => ({
    biller: selectLocationBiller(state),
    deadline: selectPastDueDeadline(state),
    currentLocationId: selectLocationId(state),
    previousTierName: selectPreviousTierName(state),
    currentLocationTier: selectCurrentLocationTier(state),
    isSubscribedAnnually: selectIsSubscribedAnnually(state),
    currentLocationTierName: getCurrentLocationTierName(state),
    isPastDueAfterDeadline: selectIsPastDueAfterDeadline(state),
    daysLeftBeforeDowngrade: selectDaysLeftBeforeDowngrade(state),
    initialPastDueDialogViewKey: selectInitialPastDueDialogViewKey(state),
    shouldShowPastDueSubscriptionDialog:
      selectShouldShowPastDueSubscriptionDialog(state),
  }),
  {
    noChange: getNoChange,
    onChangeToNewTier: changeToNewTier,
    onChangePaymentMethod: actions.changePaymentMethod,
    closePastDueDialog: billerActions.closePastDueDialog,
    dismissPastDueSubscriptionModal: getDismissPastDueSubscriptionModal,
    setIsBillingModalSourcePastDue:
      billerActions.setIsBillingModalSourcePastDue,
    setDecidedToStayOnBasic: billerActions.setDecidedToStayOnBasic,
  }
)(
  withUxRoot({
    productArea: PRODUCT_AREAS.MONETIZATION,
    eventCategory: EVENT_CATEGORIES.PAST_DUE_SUBSCRIPTIONS_DIALOG,
  })(withUxPartition({})(PastDueSubscriptionDialog))
);
