import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import Button from 'fe-design-base/molecules/Button';

import { setModalPromoCode } from 'actions/modals';
import * as modalActions from 'actions/modals';

import * as navSelectors from 'selectors/nav';
import {
  getCurrentCompanyIsEligibleForDsu,
  getEarlyLifeDiaryStudyExperienceVersion,
  getRolloutEnabled,
} from 'selectors/session';

import { changeToNewTier } from 'features/biller/slice';
import {
  openCalendlyModalCbAction,
  openCheckoutModalCbAction,
  showClickToCallDialogCbAction,
  signOutFromDsuCbAction,
  startDsuSessionCbAction,
} from 'features/commandBar/commandBarCallbackActions';
import { SESSION_STORAGE_KEYS } from 'features/onboarding/constants';
import { hidePayrollCountdownBannerLocalStorageKey } from 'features/payroll/constants';

import { CalendlyModal } from 'components/CalendlyModal/CalendlyModal';
import { useCalendlyModalHandlers } from 'components/CalendlyModal/hooks';

import { useRegisterCommandBarCallbackAction } from 'util/commandBar';
import {
  clearObjectInLocalStorage,
  getObjectFromLocalStorage,
} from 'util/localStorageWrapper';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';
import { useTrackUx, withUxRoot } from 'util/uxEvents';
const getUserData = navSelectors.buildItemDataSelector('avatarMenu');
import { deleteJSONWithoutParsing } from 'api/fetch';
import { useIsEmbedded } from 'hooks/useIsEmbedded';

export const CommandBar = ({
  children,
  userData,
  isEligibleForDsu,
  onChangeToNewTier,
  onSetModalPromoCode,
  showClickToCallDialog,
  monetizationCommandBarEnabled,
  earlyLifeDiaryStudyExperienceVersion,
}) => {
  const { calendlyModalIsOpen, handleCalendlyClick, handleCalendlyClose } =
    useCalendlyModalHandlers();
  const [calendlyUrl, setCalendlyUrl] = useState('');
  const isEmbedded = useIsEmbedded();

  const openCalendlyModal = useCallback(
    ({ url }) => {
      setCalendlyUrl(url);
      handleCalendlyClick();
    },
    [handleCalendlyClick]
  );

  const dsuSessionStarted = getObjectFromLocalStorage(
    'earlylife.dsuSessionStarted'
  );

  const showDsuButtons =
    isEligibleForDsu &&
    earlyLifeDiaryStudyExperienceVersion === 1 &&
    dsuSessionStarted;

  const signOutFromDsu = useCallback(() => {
    window.localStorage.removeItem(hidePayrollCountdownBannerLocalStorageKey);
    window.sessionStorage.removeItem(
      SESSION_STORAGE_KEYS.TIMESHEETS_GUIDED_SETUP_MAYBE_LATER
    );
    clearObjectInLocalStorage('earlylife.dsuSessionStarted');
    deleteJSONWithoutParsing(userData.get('signOutUrl')).catch(
      window.location.reload
    );

    window.location.reload();
  }, [userData]);

  const trackUx = useTrackUx();

  const handleClickEndSession = useCallback(() => {
    trackUx(EVENT_ACTIONS.BUTTON_CLICKED, TRACK_ACTION_TYPES.CLICK, {
      early_life_dsu_experiment_end_session_cta: true,
    });
  }, [trackUx]);

  const handleClickGiveFeedback = useCallback(() => {
    trackUx(EVENT_ACTIONS.BUTTON_CLICKED, TRACK_ACTION_TYPES.CLICK, {
      early_life_dsu_experiment_give_feedback_cta: true,
    });
  }, [trackUx]);

  useRegisterCommandBarCallbackAction(
    'openCheckoutModal',
    openCheckoutModalCbAction({
      onChangeToNewTier,
      setModalPromoCode: onSetModalPromoCode,
    })
  );
  useRegisterCommandBarCallbackAction(
    'openCalendlyModal',
    openCalendlyModalCbAction({
      openCalendlyModal,
    })
  );
  useRegisterCommandBarCallbackAction(
    'showClickToCallDialog',
    showClickToCallDialogCbAction({
      showClickToCallDialog,
    })
  );
  useRegisterCommandBarCallbackAction(
    'signOutFromDsu',
    signOutFromDsuCbAction({ signOutFromDsu })
  );
  useRegisterCommandBarCallbackAction(
    'startDsuSession',
    startDsuSessionCbAction({
      startDsuSession: () => {
        window.location.reload();
        localStorage.setItem('earlylife.dsuSessionStarted', true);
      },
    })
  );

  if (
    isEmbedded ||
    !monetizationCommandBarEnabled ||
    window.location.pathname.includes('/admin')
  ) {
    return children || null;
  }

  return (
    <>
      {children || null}
      {calendlyModalIsOpen && (
        <CalendlyModal
          url={calendlyUrl}
          open={calendlyModalIsOpen}
          handleCloseModal={handleCalendlyClose}
        />
      )}
      {showDsuButtons && (
        <Box fixed flex bottom="24px" right="24px" gap={12} zIndex={999}>
          <Button
            variant="secondaryDestructive"
            onClick={handleClickEndSession}
          >
            End session
          </Button>
          <Button variant="primary" onClick={handleClickGiveFeedback}>
            Give feedback
          </Button>
        </Box>
      )}
    </>
  );
};

export default connect(
  state => ({
    userData: getUserData(state),
    isEligibleForDsu: getCurrentCompanyIsEligibleForDsu(state),
    monetizationCommandBarEnabled: getRolloutEnabled(
      state,
      'monetization_command_bar'
    ),
    earlyLifeDiaryStudyExperienceVersion:
      getEarlyLifeDiaryStudyExperienceVersion(state),
  }),
  {
    onChangeToNewTier: changeToNewTier,
    onSetModalPromoCode: setModalPromoCode,
    showClickToCallDialog: modalActions.showClickToCallDialog,
  }
)(
  withUxRoot({
    productArea: PRODUCT_AREAS.COMMAND_BAR,
    eventCategory: EVENT_CATEGORIES.COMMAND_BAR,
  })(CommandBar)
);
