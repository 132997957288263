import React, { useEffect, useState } from 'react';
import useId from '@mui/material/utils/useId';
import FormField, { FormFieldProps } from 'fe-design-base/atoms/FormField';
import TextInput, { TextInputProps } from 'fe-design-base/atoms/TextInput';
import { Field, FormikValues, useFormikContext } from 'formik';

export type TextFieldProps = Omit<FormFieldProps, 'children'> &
  Omit<TextInputProps, 'value' | 'onChange'> & {
    maxCharsCount?: number;
    trim?: boolean;
  };

const TextField = ({
  name,
  label,
  inputId,
  width,
  fullWidth,
  labelPosition,
  labelWidth,
  shrink,
  disabled,
  helperText,
  hasAsterisk,
  successText,
  onBlur,
  maxCharsCount,
  trim,
  ...inputProps
}: TextFieldProps) => {
  const { touched, errors, values, setFieldValue } =
    useFormikContext<FormikValues>();
  const [inputLength, setInputLength] = useState(values[name]?.length);
  const error = touched?.[name] && errors?.[name] && !disabled;
  const fieldId = useId();
  const fieldValue = values[name];

  useEffect(() => {
    if (maxCharsCount) setInputLength(fieldValue?.length);
  }, [maxCharsCount, fieldValue]);

  useEffect(() => {
    if (trim && fieldValue) setFieldValue(name, fieldValue.trim());
  }, [trim, fieldValue, name, setFieldValue]);

  const charCount = inputLength || 0;

  const textAreaResizable = !!(
    inputProps.textArea &&
    (inputProps.minRows || inputProps.maxRows)
  );

  return (
    <FormField
      label={label}
      name={name}
      inputId={inputId || fieldId}
      width={width}
      fullWidth={fullWidth}
      labelPosition={labelPosition}
      labelWidth={labelWidth}
      shrink={shrink}
      disabled={disabled}
      readOnly={inputProps.readOnly}
      uxElement={inputProps.uxElement}
      helperText={maxCharsCount ? `${charCount}/${maxCharsCount}` : helperText}
      hasAsterisk={hasAsterisk}
      successText={successText}
      isComponentResizable={textAreaResizable}
    >
      <Field
        as={TextInput}
        name={name}
        id={inputId || fieldId}
        disabled={disabled}
        label={label}
        labelPosition={labelPosition}
        error={error}
        // Note: using onBlurCapture to avoid interfering with Formik handleBlur & validation
        onBlurCapture={onBlur}
        {...inputProps}
      />
    </FormField>
  );
};

export default TextField;
