import React, { ReactNode, useMemo } from 'react';
import { FormControlLabel } from '@mui/material';
import Checkbox, { CheckboxProps } from 'fe-design-base/atoms/Checkbox';
import FormHelperText from 'fe-design-base/atoms/FormField/FormHelperText';
import Text from 'fe-design-base/atoms/Text';
import { Field, FormikValues, useFormikContext } from 'formik';

interface CheckboxFieldProps extends CheckboxProps {
  label?: string | ReactNode;
  helperText?: string;
  hasAsterisk?: boolean;
}

const CheckboxField = ({
  label,
  helperText,
  name,
  disabled,
  hasAsterisk,
  value,
  ...checkboxProps
}: CheckboxFieldProps) => {
  const { touched, errors, values } = useFormikContext<FormikValues>();

  // Skip error display for fields sharing a name as part of a multi-checkbox array
  const error =
    !Array.isArray(values[name]) &&
    !disabled &&
    touched[name] &&
    errors?.[name];

  const isRequired = hasAsterisk && !disabled;

  const labelNode = useMemo(
    () =>
      typeof label === 'string' ? (
        <>
          <Text
            variant="body"
            color={disabled ? 'mono500' : 'mono900'}
            className={isRequired ? 'required' : undefined}
          >
            {label}
          </Text>
          <FormHelperText
            name={name}
            disabled={disabled}
            hasError={!!error}
            helperText={helperText}
            className="FDBCheckboxField__form-helper-wrapper"
            absolutelyPositioned={false}
            topStyleAddend={28}
          />
        </>
      ) : (
        label
      ),
    [disabled, error, helperText, isRequired, label, name]
  );

  return (
    <FormControlLabel
      className="LeftInput FDBCheckboxField"
      control={
        <Field
          as={Checkbox}
          error={error}
          name={name}
          label={label}
          checked={
            Array.isArray(values[name])
              ? values[name] && values[name].includes(value)
              : values[name]
          }
          {...(Array.isArray(values[name]) && { value })}
          {...{ ...checkboxProps, disabled }}
        />
      }
      label={labelNode}
    />
  );
};

export default CheckboxField;
