import { List } from 'immutable';
import { createSelector } from 'reselect';

import * as constants from 'features/departments/constants';

import * as entitiesSelectors from './entities';
import * as sessionSelectors from './session';

export const getIsFetching = (state, { id }) =>
  state.getIn(['departments', 'isFetching']).has(id);

export const getIsFetchingDepartmentsData = state =>
  getIsFetching(state, {
    id: constants.REQUEST_KEYS.departmentsData,
  });

const getIsLoaded = entitiesSelectors.createLoadedSelector(
  'departments',
  'DEPARTMENTS'
);
export const getUnsortedDepartments =
  entitiesSelectors.createEntitiesForViewSelector('departments', 'departments');

export const getShowLoading = createSelector(
  getIsLoaded,
  getIsFetchingDepartmentsData,
  (isLoaded, isFetching) => !isLoaded || isFetching
);

export const getFetchDepartmentsDataShouldBailout = createSelector(
  getIsLoaded,
  getIsFetchingDepartmentsData,
  (isLoaded, isFetching) => isLoaded || isFetching
);

export const getDepartments = createSelector(
  getUnsortedDepartments,
  departments =>
    departments
      .sort((depA, depB) => {
        // Put 'Uncategorized' at bottom
        if (depA.get('name') === 'Uncategorized') {
          return 1;
        }
        if (depB.get('name') === 'Uncategorized') {
          return -1;
        }

        // Put newer departments first
        return depA.get('id') > depB.get('id') ? -1 : 1;
      })
      .map(dep =>
        dep.update('roles', roles =>
          roles.sortBy(role => role.get('name').toLowerCase())
        )
      )
);

export const getDefaultDepartmentRoles = createSelector(
  getUnsortedDepartments,
  departments => {
    const department = departments.find(dep => dep.get('default'));
    if (!department) {
      return List();
    }
    return department.get('roles');
  }
);

export const getRolesValueForDepartment = createSelector(
  (state, { department }) => department,
  department => department.get('roles').map(role => role.get('id'))
);

export const getManagersValueForDepartment = createSelector(
  (state, { department }) => department,
  department => department.get('managers').map(user => user.get('id'))
);

export const getRoleOptionsForDepartment = createSelector(
  (state, { department }) => department,
  getDefaultDepartmentRoles,
  (department, defaultDepartmentRoles) => {
    let roles = department.get('roles');

    if (!department.get('default')) {
      roles = roles.concat(defaultDepartmentRoles);
    }

    return roles.map(role => ({
      value: role.get('id'),
      label: role.get('name'),
      in_active_tip_policy: role.get('role_in_active_tip_policy'),
    }));
  }
);

export const getManagerOptionsForDepartment = createSelector(
  (state, { department }) => department,
  sessionSelectors.getCurrentCompanyManagers,
  (department, companyManagers) => {
    const managers = department.get('managers');

    return companyManagers
      .filter(manager => !managers.includes(manager.get('id')))
      .map(manager => ({
        value: manager.get('id'),
        label: manager.get('name'),
      }));
  }
);

export const getRolesWithActiveTipPolicy = createSelector(
  getDepartments,
  departments => {
    const rolesInTipPolicy = [];
    departments.forEach(department => {
      const roles = department
        .get('roles')
        .filter(role => role.get('role_in_active_tip_policy') === true);

      rolesInTipPolicy.push(roles.toArray());
    });
    return rolesInTipPolicy.flat();
  }
);
