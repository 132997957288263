import React, { useCallback, useEffect, useState } from 'react';
import { Box, Chip } from '@mui/material';
import withCx, { CxProps } from 'fe-core/util/withCx';
import Icon, { IconName } from 'fe-design-base/atoms/Icon';

interface ChipClosableProps {
  children: string;
  onButtonClick?: () => void;
  disabled?: boolean;
  iconName?: IconName;
  id?: string;
}

const ChipClosable = ({
  children,
  onButtonClick,
  disabled = false,
  id,
  cx,
  cxEl,
}: ChipClosableProps & CxProps) => {
  const [isPressed, setIsPressed] = useState(false);

  useEffect(() => {
    // used in place of handleMouseUp because in a multiselect
    // the opening of the select menu interrupts the state
    if (disabled) return;

    if (!isPressed) return;

    const handleMouseUpDocument = () => {
      setIsPressed(false);
    };

    document.addEventListener('mouseup', handleMouseUpDocument);

    return () => {
      document.removeEventListener('mouseup', handleMouseUpDocument);
    };
  }, [isPressed, disabled]);

  const handleDelete = useCallback(() => {
    if (disabled || !onButtonClick) return;
    onButtonClick();
  }, [disabled, onButtonClick]);

  const handleMouseDown = useCallback(
    () => !disabled && setIsPressed(true),
    [disabled]
  );

  const handleClick = useCallback(
    // TODO: DB https://joinhomebase.atlassian.net/browse/FE-2199
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    e => {
      if (!disabled && onButtonClick) onButtonClick();
      e.stopPropagation();
    },
    [disabled, onButtonClick]
  );

  return (
    <Chip
      label={children}
      variant="filled"
      className={cx({ primary: true, active: isPressed, disabled })}
      id={id}
      clickable
      onDelete={handleDelete}
      onMouseDown={handleMouseDown}
      deleteIcon={
        <Box
          component="button"
          className={cxEl('button')}
          disabled={disabled}
          sx={{
            width: '24px',
            height: '24px',
            margin: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon size="xsmall" iconName="Clear" onClick={handleClick} />
        </Box>
      }
    />
  );
};

export default withCx<ChipClosableProps>('FDBChipClosable')(ChipClosable);
