import { createTheme, PaletteColor } from '@mui/material';

import baseColors from './colors';

const defaultTheme = createTheme();

const primary = {
  primary100: baseColors.purple100,
  primary300: baseColors.purple300,
  primary400: baseColors.purple400,
  primary500: baseColors.purple500,
  primary700: baseColors.purple700,
  primary900: baseColors.purple900,
};

const secondary = {
  secondary100: baseColors.teal100,
  secondary200: baseColors.teal200,
  secondary300: baseColors.teal300,
  secondary500: baseColors.teal500,
};

const success = {
  success100: baseColors.green100,
  success300: baseColors.green300,
  success500: baseColors.green500,
};

const warning = {
  warning100: baseColors.orange100,
  warning300: baseColors.orange300,
  warning500: baseColors.orange500,
};

const error = {
  error100: baseColors.red100,
  error300: baseColors.red300,
  error500: baseColors.red500,
};

const mono = {
  mono0: baseColors.mono0,
  mono100: baseColors.mono100,
  mono300: baseColors.mono300,
  mono400: baseColors.mono400,
  mono500: baseColors.mono500,
  mono700: baseColors.mono700,
  mono900: baseColors.mono900,
};

const info = {
  info100: baseColors.blue100,
  info300: baseColors.blue300,
  info500: baseColors.blue500,
};

export const paletteCustom: { [key: string]: string } = {
  ...baseColors,
  ...primary,
  ...secondary,
  ...success,
  ...warning,
  ...error,
  ...mono,
  ...info,
};

const makeColor = (name: string, color: string): PaletteColor =>
  defaultTheme.palette.augmentColor({
    color: {
      main: color,
    },
    name,
  });

type PaletteObjectProp = { [key: string]: PaletteColor };
export const palette: PaletteObjectProp = {
  primary100: makeColor('primary100', baseColors.purple100),
  primary300: makeColor('primary300', baseColors.purple300),
  primary400: makeColor('primary400', baseColors.purple400),
  primary500: makeColor('primary500', baseColors.purple500),
  primary700: makeColor('primary700', baseColors.purple700),
  primary900: makeColor('primary900', baseColors.purple900),
  secondary100: makeColor('secondary100', baseColors.teal100),
  secondary200: makeColor('secondary200', baseColors.teal200),
  secondary300: makeColor('secondary300', baseColors.teal300),
  secondary500: makeColor('secondary500', baseColors.teal500),
  success100: makeColor('success100', baseColors.green100),
  success300: makeColor('success300', baseColors.green300),
  success500: makeColor('success500', baseColors.green500),
  warning100: makeColor('warning100', baseColors.orange100),
  warning300: makeColor('warning300', baseColors.orange300),
  warning500: makeColor('warning500', baseColors.orange500),
  error100: makeColor('error100', baseColors.red100),
  error300: makeColor('error300', baseColors.red300),
  error500: makeColor('error500', baseColors.red500),
  mono0: makeColor('mono0', baseColors.mono0),
  mono100: makeColor('mono100', baseColors.mono100),
  mono300: makeColor('mono300', baseColors.mono300),
  mono400: makeColor('mono400', baseColors.mono400),
  mono500: makeColor('mono500', baseColors.mono500),
  mono700: makeColor('mono700', baseColors.mono700),
  mono900: makeColor('mono900', baseColors.mono900),
  info100: makeColor('info100', baseColors.blue100),
  info300: makeColor('info300', baseColors.blue300),
  info500: makeColor('info500', baseColors.blue500),
};
