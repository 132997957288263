import React, { useCallback } from 'react';
import Text from 'fe-design-base/atoms/Text';

import { toI18n } from 'util/i18n';

import Footer from '../components/Footer';
import Header from '../components/Header';
import PastDueDialogBox from '../components/PastDueDialogBox';

import { NonBillingPermissionsViewProps } from './types';

const ctaText = toI18n(
  'past_due_subscription.dialog.non_billing_permission.primary_cta'
);
export const NonBillingPermissionsView = ({
  currentTierName,
  deadline,
  closePastDueDialog,
  dismissPastDueSubscriptionModal,
}: NonBillingPermissionsViewProps) => {
  const handleOnClick = useCallback(() => {
    closePastDueDialog();
    // Store a cache to avoid showing dialog again
    dismissPastDueSubscriptionModal();
  }, [closePastDueDialog, dismissPastDueSubscriptionModal]);

  return (
    <PastDueDialogBox>
      <Header
        iconName="Warning"
        iconColor="orange300"
        titleI18n="past_due_subscription.dialog.non_billing_permission.grace_period.title"
      />
      <Text
        asHTML
        variant="body"
        color="mono900"
        i18nProps={{ currentTierName, deadline }}
        i18n="past_due_subscription.dialog.non_billing_permission.grace_period.description"
      />
      <Footer
        primaryCtaText={ctaText}
        primaryUxElement="got-it-cta"
        onClickPrimary={handleOnClick}
      />
    </PastDueDialogBox>
  );
};

export default NonBillingPermissionsView;
