import { useCallback } from 'react';

import { UseHandleChangePaymentMethodProps } from './types';

export const useHandleChangePaymentMethod = ({
  biller,
  previousTierName,
  currentLocationId,
  onChangeToNewTier,
  closePastDueDialog,
  selectedBillingCycle,
  isSubscribedAnnually,
  onChangePaymentMethod,
  isPastDueAfterDeadline,
  setIsBillingModalSourcePastDue,
}: UseHandleChangePaymentMethodProps) =>
  useCallback(() => {
    setIsBillingModalSourcePastDue(true);

    if (isPastDueAfterDeadline) {
      onChangeToNewTier({
        tierName: previousTierName,
        billingFrequency: selectedBillingCycle(isSubscribedAnnually),
      });
    } else {
      onChangePaymentMethod({
        biller,
        subscriptionType: 'team_app',
        locationId: currentLocationId,
      });
    }
    closePastDueDialog();
  }, [
    biller,
    previousTierName,
    onChangeToNewTier,
    currentLocationId,
    closePastDueDialog,
    isSubscribedAnnually,
    selectedBillingCycle,
    onChangePaymentMethod,
    isPastDueAfterDeadline,
    setIsBillingModalSourcePastDue,
  ]);
