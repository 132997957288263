import React, { useCallback, useMemo } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import moment from 'moment';

import { labelForPlanName } from 'features/monetization/util';

import { toI18n } from 'util/i18n';

import Features from '../components/Features';
import Footer from '../components/Footer';
import Header from '../components/Header';
import PastDueDialogBox from '../components/PastDueDialogBox';

import { RestoredToPaidViewProps } from './types';

const primaryCtaText = toI18n(
  'past_due_subscription.dialog.restored_to_paid.primary_cta'
);

export const RestoredToPaidView = ({
  previousTierName,
  closePastDueDialog,
}: RestoredToPaidViewProps) => {
  const handleOnClick = useCallback(() => {
    closePastDueDialog();
  }, [closePastDueDialog]);

  const features = useMemo(
    () =>
      previousTierName
        ? (Object.values(
            toI18n(
              // eslint-disable-next-line max-len
              `past_due_subscription.dialog.restored_to_paid.features.${previousTierName.toLowerCase()}`
            )
          ) as string[])
        : [],
    [previousTierName]
  );

  return (
    <PastDueDialogBox>
      <Header
        iconName="Complete"
        iconColor="green300"
        titleI18n="past_due_subscription.dialog.restored_to_paid.title"
      />

      <Text
        asHTML
        variant="body"
        color="mono900"
        i18n="past_due_subscription.dialog.restored_to_paid.subtitle"
        i18nProps={{
          currentTierName: labelForPlanName(previousTierName),
          date: moment().format('MMMM D, YYYY'),
        }}
      />

      <Box mb={-12}>
        <Text
          asHTML
          color="mono900"
          variant="heading4"
          i18n="past_due_subscription.dialog.restored_to_paid.subtitle2"
        />
      </Box>

      <Features features={features} />
      <Footer
        onClickPrimary={handleOnClick}
        primaryCtaText={primaryCtaText}
        primaryUxElement="restored-to-paid-got-it-cta"
      />
    </PastDueDialogBox>
  );
};

export default RestoredToPaidView;
