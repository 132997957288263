import { SxProps } from '@mui/material';

export const INPUT_BOX_SX = {
  position: 'relative',
  width: '100%',
  minWidth: 0,
  paddingBottom: '20px',
} as SxProps;

export const TOP_STYLE_ADDEND = -14;
