import { batch } from 'react-redux';
import * as routes from 'api';
import { fetchJSON, postJSON, putJSON } from 'api/fetch';
import { pollWorkersStatus, pollWorkerStatus } from 'api/workerStatus';
import { omit } from 'lodash';
import queryString from 'query-string';

import { hideModal, showModal } from 'actions/modals';
import {
  createMonetizationCtaEvent,
  updateCurrentLocation,
} from 'actions/session';

import { getActiveTipPolicyId, getCanManageTimecards } from 'selectors/session';
import {
  getEndDate,
  getLocationDataFetched,
  getLocationRolesUpdated,
  getStartDate,
  importCreditTips,
} from 'selectors/timesheets';

import { fetchPayrollIneligibleEmployees } from 'features/payroll/DashboardView/payrollDashboardSlice';
import * as constants from 'features/timesheets/constants';

import { df, moment } from 'util/dateTime';
import { error as flashError } from 'util/flashNotice';
import { toI18n } from 'util/i18n';
import {
  createAsyncGetAction,
  createAsyncPostAction,
  createAsyncPutAction,
  withAlerts,
} from 'util/redux';
import { addQuery } from 'util/router';
import { trackMonetizationCtaEventKinesis } from 'util/tracking';
import { TRACK_ACTIONS, TRACK_EVENTS } from 'util/tracking_constants';

export const actionTypes = {
  HIDE_MAKE_BREAKS_OPTIONAL_TOOLTIP: 'HIDE_MAKE_BREAKS_OPTIONAL_TOOLTIP',
  HIDE_TIP_POOLING_TOOLTIP: 'HIDE_TIP_POOLING_TOOLTIP',
  HIDE_ZERO_STATE_DRAWER: 'HIDE_ZERO_STATE_DRAWER',
  UPDATE_LOCATION_ONBOARDING_PROPERTIES_REQUEST:
    'UPDATE_LOCATION_ONBOARDING_PROPERTIES_REQUEST',
  UPDATE_LOCATION_ONBOARDING_PROPERTIES_SUCCESS:
    'UPDATE_LOCATION_ONBOARDING_PROPERTIES_SUCCESS',
  UPDATE_LOCATION_ONBOARDING_PROPERTIES_FAILURE:
    'UPDATE_LOCATION_ONBOARDING_PROPERTIES_FAILURE',
  SEND_DOWNLOAD_APP_SMS_REQUEST: 'SEND_DOWNLOAD_APP_SMS_REQUEST',
  SEND_DOWNLOAD_APP_SMS_SUCCESS: 'SEND_DOWNLOAD_APP_SMS_SUCCESS',
  SEND_DOWNLOAD_APP_SMS_FAILURE: 'SEND_DOWNLOAD_APP_SMS_FAILURE',
  SET_SORT_KEY: 'SET_SORT_KEY',

  SEND_DOWNLOAD_APP_EMAIL_REQUEST: 'SEND_DOWNLOAD_APP_EMAIL_REQUEST',
  SEND_DOWNLOAD_APP_EMAIL_SUCCESS: 'SEND_DOWNLOAD_APP_EMAIL_SUCCESS',
  SEND_DOWNLOAD_APP_EMAIL_FAILURE: 'SEND_DOWNLOAD_APP_EMAIL_FAILURE',
  OPEN_MONETIZATION_UPSELL_EXPORT_MODAL:
    'TIMESHEETS/OPEN_MONETIZATION_UPSELL_EXPORT_MODAL',
  CLOSE_MONETIZATION_UPSELL_EXPORT_MODAL:
    'TIMESHEETS/CLOSE_MONETIZATION_UPSELL_EXPORT_MODAL',

  FETCH_TABLE_ROWS_REQUEST: 'FETCH_TABLE_ROWS_REQUEST',
  FETCH_TABLE_ROWS_SUCCESS: 'FETCH_TABLE_ROWS_SUCCESS',
  FETCH_TABLE_ROWS_FAILURE: 'FETCH_TABLE_ROWS_FAILURE',

  UPDATE_PAYROLL_PERIODS: 'TIMESHEETS/UPDATE_PAYROLL_PERIODS',

  FETCH_INIT_DATA_REQUEST: 'FETCH_INIT_DATA_REQUEST',
  FETCH_INIT_DATA_SUCCESS: 'FETCH_INIT_DATA_SUCCESS',
  FETCH_INIT_DATA_FAILURE: 'FETCH_INIT_DATA_FAILURE',

  FETCH_LOCATION_TOTALS_REQUEST: 'FETCH_LOCATION_TOTALS_REQUEST',
  FETCH_LOCATION_TOTALS_SUCCESS: 'FETCH_LOCATION_TOTALS_SUCCESS',
  FETCH_LOCATION_TOTALS_FAILURE: 'FETCH_LOCATION_TOTALS_FAILURE',

  UPDATE_TOTALS: 'TIMESHEETS/UPDATE_TOTALS',

  FETCH_LOCATION_DATA_REQUEST: 'TIMESHEETS/FETCH_LOCATION_DATA_REQUEST',
  FETCH_LOCATION_DATA_SUCCESS: 'TIMESHEETS/FETCH_LOCATION_DATA_SUCCESS',
  FETCH_LOCATION_DATA_FAILURE: 'TIMESHEETS/FETCH_LOCATION_DATA_FAILURE',

  UPDATE_ROW_REQUEST: 'TIMESHEETS/UPDATE_ROW_REQUEST',
  UPDATE_ROW_SUCCESS: 'TIMESHEETS/UPDATE_ROW_SUCCESS',
  UPDATE_ROW_FAILURE: 'TIMESHEETS/UPDATE_ROW_FAILURE',

  DELETE_ROW: 'TIMESHEETS/DELETE_ROW',
  DELETE_ROWS: 'TIMESHEETS/DELETE_ROWS',

  UPDATE_HIDE_WO_HOURS_REQUEST: 'TIMESHEETS/UPDATE_HIDE_WO_HOURS_REQUEST',
  UPDATE_HIDE_WO_HOURS_SUCCESS: 'TIMESHEETS/UPDATE_HIDE_WO_HOURS_SUCCESS',
  UPDATE_HIDE_WO_HOURS_FAILURE: 'TIMESHEETS/UPDATE_HIDE_WO_HOURS_FAILURE',

  APPROVE_TIMECARD_REQUEST: 'TIMECARD/APPROVE_TIMECARD_REQUEST',
  APPROVE_TIMECARD_SUCCESS: 'TIMECARD/APPROVE_TIMECARD_SUCCESS',
  APPROVE_TIMECARD_FAILURE: 'TIMECARD/APPROVE_TIMECARD_FAILURE',

  UPDATE_SEARCH_PERIOD: 'TIMESHEETS/UPDATE_SEARCH_PERIOD',
  UPDATE_COLUMNS: 'TIMESHEETS/UPDATE_COLUMNS',
  UPDATE_ALL_COLUMNS: 'TIMESHEETS/UPDATE_ALL_COLUMNS',
  REMOVE_ISSUE: 'TIMESHEETS/REMOVE_ISSUE',

  TURN_OFF_MANDATED_BREAK_REQUEST: 'TIMESHEETS/TURN_OFF_MANDATED_BREAK_REQUEST',
  TURN_OFF_MANDATED_BREAK_SUCCESS: 'TIMESHEETS/TURN_OFF_MANDATED_BREAK_SUCCESS',
  TURN_OFF_MANDATED_BREAK_FAILURE: 'TIMESHEETS/TURN_OFF_MANDATED_BREAK_FAILURE',

  DISMISS_MANDATED_BREAK_REQUEST: 'TIMESHEETS/DISMISS_MANDATED_BREAK_REQUEST',
  DISMISS_MANDATED_BREAK_SUCCESS: 'TIMESHEETS/DISMISS_MANDATED_BREAK_SUCCESS',
  DISMISS_MANDATED_BREAK_FAILURE: 'TIMESHEETS/DISMISS_MANDATED_BREAK_FAILURE',

  CHANGE_LOCATION_SETTINGS_REQUEST:
    'TIMESHEETS/CHANGE_LOCATION_SETTINGS_REQUEST',
  CHANGE_LOCATION_SETTINGS_SUCCESS:
    'TIMESHEETS/CHANGE_LOCATION_SETTINGS_SUCCESS',
  CHANGE_LOCATION_SETTINGS_FAILURE:
    'TIMESHEETS/CHANGE_LOCATION_SETTINGS_FAILURE',

  COLLAPSE_CARD: 'TIMESHEETS/COLLAPSE_CARD',
  TOGGLE_ALL_CARDS: 'TIMESHEETS/TOGGLE_ALL_CARDS',

  CHANGE_EMPLOYEE_SORT_BY: 'TIMESHEETS/CHANGE_EMPLOYEE_SORT_BY',
  REORDER_COLUMNS: 'TIMESHEETS/REORDER_COLUMNS',
  TOGGLE_GROUP_BY_TOOLTIP: 'TIMESHEETS/TOGGLE_GROUP_BY_TOOLTIP',
  TOGGLE_SHOW_TOOLS: 'TIMESHEETS/TOGGLE_SHOW_TOOLS',

  TOGGLE_PRINT_DRAWER: 'TIMESHEETS/TOGGLE_PRINT_DRAWER',

  ADD_LOCATION_ROLE_REQUEST: 'TIMESHEETS/ADD_LOCATION_ROLE_REQUEST',
  ADD_LOCATION_ROLE_SUCCESS: 'TIMESHEETS/ADD_LOCATION_ROLE_SUCCESS',
  ADD_LOCATION_ROLE_FAILURE: 'TIMESHEETS/ADD_LOCATION_ROLE_FAILURE',
  SET_TIME_SHEETS_GUIDED_SETUP_MAYBE_LATER_IN_SESSION:
    'SET_TIME_SHEETS_GUIDED_SETUP_MAYBE_LATER_IN_SESSION',
  UPDATE_HAS_TIMECARDS: 'TIMESHEETS/UPDATE_HAS_TIMECARDS',
  UPDATE_TIP_ENGINE_ALERT: 'TIMESHEETS/UPDATE_TIP_ENGINE_ALERT',
  UPDATE_REFRESH_TIP_OUTS: 'TIMESHEETS/UPDATE_REFRESH_TIP_OUTS',
  HIDE_PPR_NAV: 'TIMESHEETS/HIDE_PPR_NAV',
  SHOW_PPR_NAV: 'TIMESHEETS/SHOW_PPR_NAV',
  SHOW_TIP_CHANGES_ALERT: 'TIMESHEETS/SHOW_TIP_CHANGES_ALERT',
  DISMISS_TIP_CHANGES_ALERT: 'TIMESHEETS/DISMISS_TIP_CHANGES_ALERT',
};

export const hidePPRNav = () => ({
  type: actionTypes.HIDE_PPR_NAV,
});

export const showPPRNav = () => ({
  type: actionTypes.SHOW_PPR_NAV,
});
export const updateTipEngineAlert = payload => ({
  type: actionTypes.UPDATE_TIP_ENGINE_ALERT,
  payload,
});

export const addLocationRole = (name, callback) =>
  withAlerts(
    createAsyncPostAction(
      '/roles',
      [
        { type: actionTypes.ADD_LOCATION_ROLE_REQUEST },
        { type: actionTypes.ADD_LOCATION_ROLE_SUCCESS },
        { type: actionTypes.ADD_LOCATION_ROLE_FAILURE },
      ],
      { body: { role: { name } } }
    ),
    {
      onSuccess: ({ payload }) => {
        callback(payload.id, payload.name);
      },
    }
  );
export const updateRefreshTipOuts = () => ({
  type: actionTypes.UPDATE_REFRESH_TIP_OUTS,
});
export const collapseCard = key => ({
  type: actionTypes.COLLAPSE_CARD,
  payload: { key },
});

export const toggleAllCards = keys => ({
  type: actionTypes.TOGGLE_ALL_CARDS,
  payload: { keys },
});

export const changeEmployeeSortBy = employeeSortBy => ({
  type: actionTypes.CHANGE_EMPLOYEE_SORT_BY,
  payload: employeeSortBy,
});

export const updateSearchPeriod = payload => ({
  type: actionTypes.UPDATE_SEARCH_PERIOD,
  payload,
});

export const hideMakeBreaksOptional = () => ({
  type: actionTypes.HIDE_MAKE_BREAKS_OPTIONAL_TOOLTIP,
});

export const hideTipPoolingTooltip = () => ({
  type: actionTypes.HIDE_TIP_POOLING_TOOLTIP,
});

export const fetchLocationData = includeEmployees =>
  createAsyncGetAction(
    `/timesheets/timecards/location_data?${queryString.stringify({
      include_employees: includeEmployees,
    })}`,
    [
      { type: actionTypes.FETCH_LOCATION_DATA_REQUEST },
      { type: actionTypes.FETCH_LOCATION_DATA_SUCCESS },
      { type: actionTypes.FETCH_LOCATION_DATA_FAILURE },
    ],
    {
      bailout: state =>
        getLocationRolesUpdated(state) < getLocationDataFetched(state),
    }
  );

export const updateTotals = payload => ({
  type: actionTypes.UPDATE_TOTALS,
  payload,
});

export const fetchTotals =
  (startDate, endDate, loadingTotals = true) =>
  (dispatch, getState) => {
    dispatch(updateSearchPeriod({ startDate, endDate }));

    dispatch(
      withAlerts(
        createAsyncGetAction(
          routes.timesheetsTotalsRoute({
            start_date: startDate,
            end_date: endDate,
          }),
          [
            {
              type: actionTypes.FETCH_LOCATION_TOTALS_REQUEST,
              meta: { loadingTotals },
            },
            { type: actionTypes.FETCH_LOCATION_TOTALS_SUCCESS },
            { type: actionTypes.FETCH_LOCATION_TOTALS_FAILURE },
          ]
        ),
        {
          onSuccess: response =>
            pollWorkersStatus(response.payload.job_ids)
              .then(() => {
                const importCreditTipsEnabled = (!!importCreditTips(
                  getState()
                )).toString();

                fetchJSON(
                  routes.timesheetsFetchTotalsRoute({
                    cache_key: response.payload.cache_key,
                  })
                ).then(totals => {
                  if (window.Homebase.env === 'production') {
                    window.DD_RUM.onReady(() => {
                      window.DD_RUM.setUserProperty(
                        'import_credit_tips',
                        importCreditTipsEnabled
                      );
                      window.DD_RUM.setUserProperty(
                        'timecard_count',
                        totals.timecards_count
                      );
                      window.DD_RUM.setUserProperty(
                        'jobs_count',
                        totals.jobs_count
                      );
                      window.DD_RUM.setUserProperty(
                        'days_count',
                        totals.days_count
                      );
                    });
                  }
                  dispatch(
                    updateTotals({
                      ...totals,
                      cacheKey: response.payload.cache_key,
                    })
                  );
                });
              })
              .catch(() => {
                flashError(toI18n('errors.generic'));
              }),
        }
      )
    );
  };

export const fetchInitData = () =>
  withAlerts(
    createAsyncGetAction('/timesheets', [
      { type: actionTypes.FETCH_INIT_DATA_REQUEST },
      { type: actionTypes.FETCH_INIT_DATA_SUCCESS },
      { type: actionTypes.FETCH_INIT_DATA_FAILURE },
    ]),
    {
      error: toI18n('timesheets.other_errors.something_went_wrong_reload'),
    }
  );

export const fetchTableRows = (startDate, endDate) =>
  createAsyncGetAction(
    routes.timesheetsTableRowsRoute({
      start_date: startDate,
      end_date: endDate,
    }),
    [
      { type: actionTypes.FETCH_TABLE_ROWS_REQUEST },
      { type: actionTypes.FETCH_TABLE_ROWS_SUCCESS },
      { type: actionTypes.FETCH_TABLE_ROWS_FAILURE },
    ]
  );

export const updateRow = (shiftId, reset) => (dispatch, getState) => {
  const state = getState();
  const startDate = getStartDate(state);
  const endDate = getEndDate(state);

  dispatch(
    createAsyncGetAction(
      routes.timesheetsTableRowRoute({
        id: shiftId,
      }),
      [
        { type: actionTypes.UPDATE_ROW_REQUEST },
        { type: actionTypes.UPDATE_ROW_SUCCESS, meta: { shiftId, reset } },
        { type: actionTypes.UPDATE_ROW_FAILURE },
      ]
    )
  );

  if (startDate && endDate) {
    dispatch(fetchTotals(startDate, endDate, false));
  }
};

export const updateHideWithoutHours = hideWOH => dispatch => {
  dispatch(
    createAsyncPutAction(
      routes.timesheetsSettingsRoute(),
      [
        { type: actionTypes.UPDATE_HIDE_WO_HOURS_REQUEST },
        {
          type: actionTypes.UPDATE_HIDE_WO_HOURS_SUCCESS,
          meta: { hideWOH },
        },
        { type: actionTypes.UPDATE_HIDE_WO_HOURS_FAILURE },
      ],
      {
        body: {
          timesheets_settings: { hide_without_hours: hideWOH },
        },
      }
    )
  );
};

export const deleteRow = (shiftId = null) => ({
  type: actionTypes.DELETE_ROW,
  payload: { shiftId },
});

export const updateHasTimecards = () => ({
  type: actionTypes.UPDATE_HAS_TIMECARDS,
});

export const updateTimecardApprovals = (shiftIds, approval) => dispatch => {
  const filteredIds = shiftIds.filter(id => id);

  dispatch(
    withAlerts(
      createAsyncPostAction(
        routes.timecardApprovalRoute(),
        [
          { type: actionTypes.APPROVE_TIMECARD_REQUEST },
          {
            type: actionTypes.APPROVE_TIMECARD_SUCCESS,
            meta: { shiftIds: filteredIds, approval },
          },
          { type: actionTypes.APPROVE_TIMECARD_FAILURE },
        ],
        {
          body: {
            shift_ids: filteredIds,
            approve: approval,
          },
        }
      )
    )
  );
};

export const updateLocationOnboardingProperties = (props, callback) =>
  withAlerts(
    createAsyncPutAction(
      routes.locationOnboardingPropertiesRoute(),
      [
        {
          type: actionTypes.UPDATE_LOCATION_ONBOARDING_PROPERTIES_REQUEST,
          meta: props,
        },
        {
          type: actionTypes.UPDATE_LOCATION_ONBOARDING_PROPERTIES_SUCCESS,
          meta: props,
        },
        {
          type: actionTypes.UPDATE_LOCATION_ONBOARDING_PROPERTIES_FAILURE,
          meta: props,
        },
      ],
      {
        body: {
          location_onboarding_properties: props,
        },
      }
    ),
    {
      success: toI18n('settings.update_success'),
      error: toI18n('settings.update_error'),
      onSuccess: callback,
    }
  );

export const setSortColumn = (sortColumn, callback) =>
  withAlerts(
    {
      type: actionTypes.SET_SORT_KEY,
      payload: { sortColumn },
    },
    {
      onDone: callback,
    }
  );

export const sendDownloadAppSms = phone =>
  createAsyncPostAction(
    routes.downloadAppSmsRoute(),
    [
      { type: actionTypes.SEND_DOWNLOAD_APP_SMS_REQUEST },
      { type: actionTypes.SEND_DOWNLOAD_APP_SMS_SUCCESS },
      { type: actionTypes.SEND_DOWNLOAD_APP_SMS_FAILURE },
    ],
    {
      body: { phone },
    }
  );

export const sendDownloadAppEmail = () =>
  createAsyncPostAction(routes.downloadAppEmailRoute(), [
    { type: actionTypes.SEND_DOWNLOAD_APP_EMAIL_REQUEST },
    { type: actionTypes.SEND_DOWNLOAD_APP_EMAIL_SUCCESS },
    { type: actionTypes.SEND_DOWNLOAD_APP_EMAIL_FAILURE },
  ]);

export const showInstructionVideoModal = type =>
  showModal(constants.INSTRUCTION_VIDEO_MODAL_TYPE, {
    deprecatedModal: true,
    type,
    noPadding: true,
    auto: true,
  });

export const openMonetizationUpsellExportModal = () => {
  trackMonetizationCtaEventKinesis(
    TRACK_EVENTS.UPSELL_INTERRUPT_TIMESHEET_EXPORT,
    TRACK_ACTIONS.VIEW
  );
  return {
    type: actionTypes.OPEN_MONETIZATION_UPSELL_EXPORT_MODAL,
  };
};

export const closeMonetizationUpsellExportModal = () => dispatch => {
  dispatch(
    createMonetizationCtaEvent(TRACK_EVENTS.UPSELL_INTERRUPT_TIMESHEET_EXPORT)
  );

  return dispatch({
    type: actionTypes.CLOSE_MONETIZATION_UPSELL_EXPORT_MODAL,
  });
};

export const showPhotosModal = (
  group,
  rows,
  timecardPhotosEnabled,
  minTierNameForPhotos
) =>
  showModal(constants.PHOTOS_MODAL_TYPE, {
    deprecatedModal: true,
    group,
    rows,
    timecardPhotosEnabled,
    minTierNameForPhotos,
    noPadding: true,
    auto: true,
  });

export const dismissMissingBreakIssue = (shiftId, mbId) =>
  putJSON(routes.timecardRoute(shiftId, '/update_timebreak_errors'), {
    mandated_break_id: mbId,
    approve: true,
  });

export const changeLocationSettings = () => (dispatch, getState) => {
  const state = getState();
  dispatch(
    withAlerts(
      createAsyncPutAction(
        routes.changeLocationSettingsRoute({
          'timesheets_settings[import_credit_tips]': !importCreditTips(state),
        }),
        [
          { type: actionTypes.CHANGE_LOCATION_SETTINGS_REQUEST },
          { type: actionTypes.CHANGE_LOCATION_SETTINGS_SUCCESS },
          { type: actionTypes.CHANGE_LOCATION_SETTINGS_FAILURE },
        ]
      ),
      {
        onSuccess: () =>
          dispatch(fetchTotals(getStartDate(state), getEndDate(state))),
      }
    )
  );
};

export const removeIssue = row => ({
  type: actionTypes.REMOVE_ISSUE,
  payload: { row },
});

export const turnOffMandatedBreak = mbId =>
  createAsyncPutAction(
    routes.timesheetsTurnOffBreakRoute(),
    [
      { type: actionTypes.TURN_OFF_MANDATED_BREAK_REQUEST },
      { type: actionTypes.TURN_OFF_MANDATED_BREAK_SUCCESS, meta: { mbId } },
      { type: actionTypes.TURN_OFF_MANDATED_BREAK_FAILURE },
    ],
    { body: { mb_id: mbId } }
  );

export const dismissMandatedBreak = mbId =>
  createAsyncPutAction(
    routes.timesheetsDismissBreakRoute(),
    [
      { type: actionTypes.DISMISS_MANDATED_BREAK_REQUEST },
      { type: actionTypes.DISMISS_MANDATED_BREAK_SUCCESS, meta: { mbId } },
      { type: actionTypes.DISMISS_MANDATED_BREAK_FAILURE },
    ],
    { body: { mb_id: mbId } }
  );

export const toggleGroupByTooltip = isOpen => ({
  type: actionTypes.TOGGLE_GROUP_BY_TOOLTIP,
  payload: { isOpen },
});

const deleteTableRows = () => ({ type: actionTypes.DELETE_ROWS });

export const fetchTotalsWithTipOuts = (startDate, endDate) => dispatch => {
  postJSON(
    routes.timesheetsGenerateTipsTotalsRoute({
      start_date: startDate,
      end_date: endDate,
    })
  )
    .then(() => {
      dispatch(fetchTotals(startDate, endDate));
    })
    .catch(() => {
      dispatch(fetchTotals(startDate, endDate)); // Fetch totals even if tips generation fails
      dispatch(
        updateTipEngineAlert([
          toI18n(
            'timesheets.react_page.tip_engine_error_alert.unknown_error_title'
          ),
          toI18n(
            'timesheets.react_page.tip_engine_error_alert.unknown_error_description'
          ),
        ])
      );
    });
};

export const fetchTimesheetData = payload => async (dispatch, getState) => {
  const state = getState();
  const activeTipPolicyId = getActiveTipPolicyId(state);
  const canManageTimecards = getCanManageTimecards(state);

  const payrollPeriod = payload.payrollPeriod;
  const startDate = payload.startDate.replace(/-/g, '/');
  const endDate = payload.endDate.replace(/-/g, '/');

  dispatch(deleteTableRows());
  dispatch(updateSearchPeriod({ startDate, endDate, payrollPeriod }));
  localStorage.setItem('ReactTimesheetsStartDate', startDate);
  localStorage.setItem('ReactTimesheetsEndDate', endDate);
  localStorage.setItem('ReactTimesheetsPayrollPeriod', payrollPeriod);
  addQuery(omit(payload, 'payrollPeriod'));
  dispatch(fetchTableRows(startDate, endDate));

  if (activeTipPolicyId && canManageTimecards) {
    fetchTotalsWithTipOuts(startDate, endDate)(dispatch);
  } else {
    dispatch(fetchTotals(startDate, endDate));
  }

  dispatch(fetchPayrollIneligibleEmployees());
};

export const updatePayrollPeriods = payload => ({
  type: actionTypes.UPDATE_PAYROLL_PERIODS,
  payload,
});

const updateColumns = columns => ({
  type: actionTypes.UPDATE_COLUMNS,
  payload: { columns },
});

const updateAllColumns = columns => ({
  type: actionTypes.UPDATE_ALL_COLUMNS,
  payload: { columns },
});

export const updateColumn = (columnKey, visible) => (dispatch, getState) => {
  let columns = getState().getIn(['timesheets', 'page', 'columns']);
  const index = columns.findIndex(c => c.get('key') === columnKey);
  columns = columns.setIn([index, 'visible'], visible);

  putJSON('/timesheets/update_columns', { columns: columns.toJS() });

  dispatch(updateColumns(columns));
};

export const updateFilters =
  ({ filterBy, groupBy, sortBy, columns, hideWithoutHours }) =>
  dispatch => {
    addQuery({ filter: filterBy, groupBy });
    localStorage.setItem('ReactTimesheetsFilter', filterBy);
    localStorage.setItem('ReactTimesheetsGroupBy', groupBy);
    localStorage.setItem('ReactTimesheetsSortBy', sortBy);
    putJSON('/timesheets/update_columns', {
      columns: columns
        .map(c => ({ key: c.get('key'), visible: c.get('value') }))
        .toJS(),
    });

    batch(() => {
      dispatch(updateAllColumns(columns));
      dispatch(changeEmployeeSortBy(sortBy));
      dispatch(updateHideWithoutHours(hideWithoutHours));
    });
  };

export const updateTimesheetPage =
  ({ locationAttributes, payrollPeriods, timesheetDataSearch }) =>
  dispatch => {
    batch(() => {
      dispatch(updatePayrollPeriods(payrollPeriods));
      dispatch(updateCurrentLocation(locationAttributes));
      dispatch(fetchTimesheetData(timesheetDataSearch));
    });
  };

export const toggleShowTools = () => ({
  type: actionTypes.TOGGLE_SHOW_TOOLS,
});

export const reloadTimesheetData = () => (dispatch, getState) => {
  const state = getState();
  const startDate = getStartDate(state);
  const endDate = getEndDate(state);
  if (startDate && endDate) {
    dispatch(fetchTableRows(startDate, endDate));
    dispatch(fetchTotals(startDate, endDate));
  }
};

export const showImportTimecardsModal = (startDate, endDate) =>
  showModal(constants.IMPORT_TIMECARDS_LOADING_MODAL, {
    deprecatedModal: true,
    forceOpen: true,
    startDate,
    endDate,
  });

export const importTimecards = (startDate, endDate) => dispatch => {
  startDate = moment(startDate).format(df('parsable_date'));
  endDate = moment(endDate).format(df('parsable_date'));

  dispatch(showImportTimecardsModal(startDate, endDate));

  postJSON('/timesheets/import.json', {
    start_date: startDate,
    end_date: endDate,
  }).then(jobId => {
    pollWorkerStatus(jobId)
      .then(() => {
        dispatch(hideModal());
        dispatch(updateRefreshTipOuts());
        dispatch(reloadTimesheetData());
      })
      .catch(() => {
        flashError(toI18n('errors.generic'));
      });
  });
};

export const setTimeSheetsGuidedSetupMaybeLaterInSession = () => {
  window.sessionStorage.setItem('timesheets_guided_setup_maybe_later', true);
  return {
    type: actionTypes.SET_TIME_SHEETS_GUIDED_SETUP_MAYBE_LATER_IN_SESSION,
  };
};

export const showTipChangesAlert = () => ({
  type: actionTypes.SHOW_TIP_CHANGES_ALERT,
});

export const dismissTipChangesAlert = () => ({
  type: actionTypes.DISMISS_TIP_CHANGES_ALERT,
});
