import { createSelector } from 'reselect';

import * as constants from 'features/employeeView/constants';
import * as util from 'features/employeeView/util';
import { getEditingUser } from 'features/payroll/SelfServeSetupView/TasksDashboard/Components/TeamSetup/teamSetupSlice';

import { toEntityId } from 'util/entities';
import { buildFormSelectors } from 'util/forms';
import { filterUsersBySearchTerm } from 'util/user';

import * as entitiesSelectors from './entities';
import * as routeSelectors from './route';
import * as sessionSelectors from './session';
import * as teamViewSelectors from './teamView';

// # General
export const getEmployeeViewSearch = state =>
  state.getIn(['employeeView', 'search']);

export const getLastHireDate = state =>
  state.getIn(['employeeView', 'terminationForm', 'lastHireDate']);

export const getLocations =
  entitiesSelectors.createLocationsForViewSelector('employeeView');
export const getLocationRoles =
  entitiesSelectors.createLocationRolesSelector(getLocations);

const getCurrentEmployeeViewUsers = createSelector(
  teamViewSelectors.getRosterUsers,
  teamViewSelectors.getShowRemoved,
  (users, showRemoved) =>
    showRemoved ? users : users.filter(user => !user.get('archived'))
);

export const getEmployeeViewTeamListUsers = createSelector(
  getCurrentEmployeeViewUsers,
  getEmployeeViewSearch,
  filterUsersBySearchTerm
);

export const getIsEmployeeLoading = (state, props) =>
  state
    .getIn(['employeeView', 'loadedEmployees', 'loadingIds'])
    .has(toEntityId(props.id));

export const getIsEmployeeLoaded = (state, props) =>
  props.id === constants.NEW_USER_ID ||
  state
    .getIn(['employeeView', 'loadedEmployees', 'loadedIds'])
    .has(toEntityId(props.id));

export const getIsEmployeeTabLoading = (state, props) =>
  state
    .getIn(['employeeView', 'loadedEmployees', 'loadingTabs'])
    .has(`${props.id}-${props.tab}`);

export const getIsEmployeeTabLoaded = (state, props) =>
  props.id === constants.NEW_USER_ID ||
  state
    .getIn(['employeeView', 'loadedEmployees', 'loadedTabs'])
    .has(`${props.id}-${props.tab}`);

export const getEmployeeProfileDefaultTab = state =>
  state.getIn(['employeeView', 'loadedEmployees', 'defaultTab']);

export const getIsEmployeeViewLoading = createSelector(
  getIsEmployeeLoaded,
  teamViewSelectors.getTeamDataShowLoading,
  getIsEmployeeLoading,
  (isLoaded, teamDataLoading, employeeLoading) =>
    !isLoaded || teamDataLoading || employeeLoading
);

const findEmployeeViewUser = (users, userId) => {
  const userIdInt = parseInt(userId, 10);
  const partnerId = userId;
  // If the userId is a number, we can assume it's a user ID, otherwise it's a partner ID
  return users.find(
    user =>
      user.get('id') === userIdInt ||
      user.get('jobs').some(job => job.get('partner_id') === partnerId)
  );
};

export const getEmployeeViewUserFromRoute = createSelector(
  teamViewSelectors.getRosterUsers,
  routeSelectors.getRouteParam,
  findEmployeeViewUser
);

export const getEmployeeViewUserFromProps = createSelector(
  teamViewSelectors.getRosterUsers,
  entitiesSelectors.getUserIdFromProps,
  findEmployeeViewUser
);

export const getEmployeeViewUserFromState = state =>
  entitiesSelectors
    .getCompanyUsers(state)
    ?.get(String(getEditingUser(state)?.id));

// # Employee Form-specific selectors
const employeeFormStatePath = ['employeeView', 'form'];
const employeeFormSelectors = buildFormSelectors(employeeFormStatePath);

employeeFormSelectors.getMatchingUser = state =>
  state.getIn([...employeeFormStatePath, 'matchingUser']);

employeeFormSelectors.getInitialData = createSelector(
  getEmployeeViewUserFromProps,
  sessionSelectors.getCurrentLocation,
  (user, currentLocation) =>
    user
      ? util.buildEmployeeFormDataForUser(user, currentLocation)
      : util.buildEmployeeFormDataForNewUser(currentLocation)
);

export { employeeFormSelectors };

// # Termination Form-specific selectors
const terminationFormStatePath = ['employeeView', 'terminationForm'];
export const terminationFormSelectors = buildFormSelectors(
  terminationFormStatePath
);

export const getTerminationFormCurrentView = state =>
  state.getIn([...terminationFormStatePath, 'currentView']);

export const getTerminationFormHireReplacementRedirectPath = state =>
  state.getIn([...terminationFormStatePath, 'hireReplacementRedirectPath']);

export const getAutoScheduleRulesTabEnabledForCurrentLocation = createSelector(
  sessionSelectors.getCurrentLocationEntity,
  location => location.get('auto_scheduling')
);
