import { useCallback } from 'react';

import { PAST_DUE_DIALOG_VIEW_KEY as VIEW_KEY } from './constants';
import { UseHandleClickOnCloseProps } from './types';

export const useHandleClickOnClose = ({
  currentViewKey,
  closePastDueDialog,
  dismissPastDueSubscriptionModal,
}: UseHandleClickOnCloseProps) =>
  // Will run after closing dialog by
  // clicking backdrop, close icon or hitting ESC key
  useCallback(() => {
    closePastDueDialog();

    if (
      currentViewKey === VIEW_KEY.grace_period ||
      currentViewKey === VIEW_KEY.grace_period_non_billing
    ) {
      // Store a cache to avoid showing dialog again
      dismissPastDueSubscriptionModal();
    }
  }, [currentViewKey, closePastDueDialog, dismissPastDueSubscriptionModal]);
