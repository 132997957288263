import { createAsyncThunk } from '@reduxjs/toolkit';
import { postJSON } from 'api/fetch';

import { MODULE_NAME } from './constants';

export const dismissPastDueSubscriptionModal = createAsyncThunk(
  `${MODULE_NAME}/dismissPastDueSubscriptionModal`,
  () => postJSON(`/monetization/dismissPastDueSubscriptionModal`)
);

export const noChange = createAsyncThunk(
  `${MODULE_NAME}/pastDue/noChange`,
  () => postJSON(`/monetization/past_due/no_change`)
);
