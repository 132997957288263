import './Image.scss';

import React, { PureComponent } from 'react';
import Lightbox from 'react-images';
import PropTypes from 'prop-types';

import Box from 'components/Box';
import ImageOverlayBox from 'components/ImageOverlayBox';
import Text from 'components/Text';

import cxHelpers from 'util/className';

@cxHelpers('Image')
export default class Image extends PureComponent {
  static propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    expandable: PropTypes.bool,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    expandedSrc: PropTypes.string,
    overlayI18n: PropTypes.string,
    h: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    growOnHover: PropTypes.bool,
    grayscaled: PropTypes.bool,
    dimmed: PropTypes.bool,
  };

  static defaultProps = {
    h: 'auto',
    grayscaled: false,
  };

  state = {
    open: false,
  };

  handleOpen = () => this.setState({ open: true });
  handleClose = () => this.setState({ open: false });

  render() {
    const {
      expandable,
      containerClassName,
      expandedSrc,
      src,
      alt,
      h,
      overlayI18n,
      growOnHover,
      grayscaled,
      dimmed,
      ...props
    } = this.props;

    let { className } = this.props;

    if (grayscaled) {
      className = `${className || ''} ${
        grayscaled ? this.cx('grayscaled') : ''
      }`;
    }

    if (dimmed) {
      className = `${className || ''} ${dimmed ? this.cx('dimmed') : ''}`;
    }

    const image = (
      <img
        className={className}
        src={src}
        alt={alt}
        style={{ height: h }}
        {...props}
      />
    );

    if (expandable || expandedSrc) {
      return (
        <Box className={this.cx({ growOnHover })} h={h} relative>
          <a className={containerClassName} onClick={this.handleOpen}>
            {image}
            {overlayI18n && (
              <ImageOverlayBox>
                <Text i18n={overlayI18n} />
              </ImageOverlayBox>
            )}
            <Lightbox
              images={[{ src: expandedSrc || src }]}
              isOpen={this.state.open}
              onClose={this.handleClose}
              backdropClosesModal
            />
          </a>
        </Box>
      );
    }

    return image;
  }
}
