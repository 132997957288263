import { useDispatch, useSelector } from 'react-redux';

import {
  openChooseTimeClockModal,
  openUserWebTimeclockModal,
} from 'actions/modals';

import { getCanUseWebTimeClock } from 'selectors/timeClock';

import {
  TIME_CLOCK_TYPE_PERSONAL,
  TIME_CLOCK_TYPE_SHARED,
} from 'features/timeClock/constants';
import {
  getSavedTimeClockType,
  openHubTimeClock,
} from 'features/timeClock/util';

export const useWebTimeClock = () => {
  const dispatch = useDispatch();
  const canUseWebTimeClock = useSelector(getCanUseWebTimeClock);

  return () => {
    if (canUseWebTimeClock) {
      switch (getSavedTimeClockType()) {
        case TIME_CLOCK_TYPE_SHARED:
          openHubTimeClock();
          return;
        case TIME_CLOCK_TYPE_PERSONAL:
          dispatch(openUserWebTimeclockModal());
          return;
        default:
          dispatch(openChooseTimeClockModal());
      }
    } else {
      dispatch(openUserWebTimeclockModal());
    }
  };
};
