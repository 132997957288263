import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchJSON, postJSON } from 'api/fetch';
import { isEmpty } from 'lodash';

import { actionTypes as employeeViewActionTypes } from 'actions/employeeView';

import { addTeamMember } from '../../team/actions';

export const fetchPayrollInfo = createAsyncThunk(
  'payrollInfo/fetchPayrollInfo',
  () => fetchJSON('/payroll/payroll_info.json')
);

export const fetchPayrollPackage = createAsyncThunk(
  'payrollInfo/fetchPayrollPackage',
  () => fetchJSON('/payroll/company/package')
);

export const upsertLinearFlowAnswers = createAsyncThunk(
  'payroll/upsertLinearFlowAnswers',
  async payload => {
    const response = await postJSON(
      '/payroll/linear_flow/save_answers',
      payload
    );

    return Promise.resolve(response);
  }
);

export const fetchTeamReadiness = createAsyncThunk(
  'payrollInfo/fetchTeamReadiness',
  () => fetchJSON('/payroll/implementation/team_readiness')
);

export const fetchTaxParameters = createAsyncThunk(
  'payrollInfo/fetchTaxParameters',
  () => fetchJSON('/payroll/settings/tax_parameters')
);

const handleIncludedInPayrollState = (state, user_id, included_in_payroll) => {
  if (isEmpty(state.teamReadiness.data)) return;

  const toggledIndex = state.teamReadiness.data.findIndex(
    x => x.id === user_id
  );

  state.teamReadiness.data[toggledIndex].included_in_payroll =
    included_in_payroll ||
    !state.teamReadiness.data[toggledIndex].included_in_payroll;
};

// filter users by statuses: missing payroll information, ready to be paid & excluded from payroll.
const filterUsersByStatuses = (users = []) =>
  users.reduce(
    (acc, curr) => {
      if (curr.included_in_payroll) {
        if (curr.ready) {
          return { ...acc, ready: [...acc.ready, curr] };
        }
        return { ...acc, missing: [...acc.missing, curr] };
      }
      return { ...acc, excluded: [...acc.excluded, curr] };
    },
    {
      excluded: [],
      ready: [],
      missing: [],
    }
  );

const payrollInfoSlice = createSlice({
  name: 'payrollInfo',
  initialState: {
    isFetching: false,
    payrollSetupInfo: null,
    showPayrollDenied: false,
    showSelfSetupResume: false,
    nextQuoteStatus: null,
    hasNextInsurancePolicyPurchasedAlertClosedOTE: false,
    showJoinWaitlist: true,
    showFirstPayrollRunWidget: false,
    isCheckBlockingPayroll: false,
    linearFlowStartStepData: {},
    implementationState: {
      payrollSettingsStep: {
        firstPayday: null,
        firstApprovalDeadline: null,
        firstPaydayShort: null,
        firstApprovalDeadlineShort: null,
      },
    },
    implementationSpecialist: {},
    taxParametersLoaded: false,
    taxParameters: {
      federal: [],
      states: [],
    },
    teamReadiness: {
      data: [],
      needsUpdate: true,
      ordered: {
        excluded: [],
        ready: [],
        missing: [],
      },
    },
    payrollPackage: {},
    companyInGoodStanding: false,
    companyIsActive: false,
  },
  extraReducers: {
    [addTeamMember.fulfilled]: state => {
      state.teamReadiness.needsUpdate = true;
    },
    [fetchPayrollInfo.pending]: state => {
      state.isFetching = true;
    },
    [fetchPayrollInfo.fulfilled]: (state, action) => {
      state.isFetching = false;
      state.payrollSetupInfo = action.payload.payroll_setup_info;
      state.showPayrollDenied = action.payload.show_payroll_denied;
      state.nextQuoteStatus = action.payload.next_quote_status;
      state.hasNextInsurancePolicyPurchasedAlertClosedOTE =
        action.payload.has_next_insurance_policy_purchased_alert_closed_ote;
      state.showSelfSetupResume = action.payload.show_self_setup_resume;
      state.showJoinWaitlist = action.payload.show_join_waitlist;
      state.companyInGoodStanding = action.payload.company_in_good_standing;
      state.companyIsActive = action.payload.company_is_active;
      state.implementationState.payrollSettingsStep.firstPayday =
        action.payload.first_payday;
      state.implementationState.payrollSettingsStep.firstApprovalDeadline =
        action.payload.first_approval_deadline;
      state.implementationState.payrollSettingsStep.firstPaydayShort =
        action.payload.first_payday_short;
      state.implementationState.payrollSettingsStep.firstApprovalDeadlineShort =
        action.payload.first_approval_deadline_short;
      state.contactDetails = action.payload.contact_details;
      state.implementationSpecialist = action.payload.implementation_specialist;
      state.isCheckBlockingPayroll = action.payload.is_check_blocking_payroll;
      state.linearFlowStartStepData =
        action.payload.linear_flow_start_step_data;
    },
    [fetchPayrollInfo.rejected]: state => {
      state.isFetching = false;
      state.payrollSetupInfo = null;
      state.showSelfSetupResume = false;
    },
    [fetchPayrollPackage.fulfilled]: (state, action) => {
      state.payrollPackage = action.payload;
    },
    [fetchTeamReadiness.fulfilled]: (state, action) => {
      state.teamReadiness.data = action.payload;
      state.teamReadiness.needsUpdate = false;
      state.teamReadiness.ordered = filterUsersByStatuses(action.payload);
    },
    [fetchTaxParameters.fulfilled]: (state, action) => {
      state.taxParametersLoaded = true;
      state.taxParameters = action.payload;
    },
    [fetchTaxParameters.rejected]: state => {
      state.taxParametersLoaded = true;
    },
    [employeeViewActionTypes.UPDATE_USER_INFO]: (state, action) => {
      const user_id = action.payload.id;
      const included_in_payroll = action.payload.included_in_payroll;

      handleIncludedInPayrollState(state, user_id, included_in_payroll);
    },
  },
});

// selectors
export const getContactDetails = state =>
  state.get('payroll').payrollInfo.contactDetails;
export const getPayrollSettingsStep = state =>
  state.get('payroll').payrollInfo.implementationState.payrollSettingsStep;
export const getIsFetchingPayrollInfo = state =>
  state.get('payroll').payrollInfo.isFetching;
export const getShowSelfSetupResume = state =>
  state.get('payroll').payrollInfo.showSelfSetupResume;
export const getShowJoinWaitlist = state =>
  state.get('payroll').payrollInfo.showJoinWaitlist;
export const getCompanyInGoodStanding = state =>
  state.get('payroll').payrollInfo.companyInGoodStanding;
export const getCompanyIsActive = state =>
  state.get('payroll').payrollInfo.companyIsActive;
export const getImplementationSpecialist = state =>
  state.get('payroll').payrollInfo.implementationSpecialist;
export const getTeamReadiness = state =>
  state.get('payroll').payrollInfo.teamReadiness.data;
export const getTeamReadinessNeedsUpdate = state =>
  state.get('payroll').payrollInfo.teamReadiness.needsUpdate;
export const getTaxParametersLoaded = state =>
  state.get('payroll').payrollInfo.taxParametersLoaded;
export const getTaxParameters = state =>
  state.get('payroll').payrollInfo.taxParameters;
export const getTeamReadinessOrderedByStatus = state =>
  state.get('payroll').payrollInfo.teamReadiness.ordered;
export const getIsCheckBlockingPayroll = state =>
  state.get('payroll').payrollInfo.isCheckBlockingPayroll;
export const getLinearFlowStartStepData = state =>
  state.get('payroll').payrollInfo.linearFlowStartStepData;
export const getPayrollPackage = state =>
  state.get('payroll').payrollInfo.payrollPackage;
export const getPayrollState = state =>
  state.get('payroll').payrollInfo.payrollSetupInfo?.payroll_state;
export const getOnboardType = state =>
  state.get('payroll').payrollInfo.payrollSetupInfo?.sss_onboard_type;
export const getShowPayrollDenied = state =>
  state.get('payroll').payrollInfo.showPayrollDenied;
export const getNextQuoteStatus = state =>
  state.get('payroll').payrollInfo.nextQuoteStatus;
export const getHasNextInsurancePolicyPurchasedAlertClosedOTE = state =>
  state.get('payroll').payrollInfo
    .hasNextInsurancePolicyPurchasedAlertClosedOTE;

export const payrollInfoReducer = payrollInfoSlice.reducer;
export const payrollInfoActions = payrollInfoSlice.actions;
