import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';

import { HeaderProps } from './types';

export const Header = ({
  iconName,
  titleI18n,
  iconColor,
  titleI18nProps,
}: HeaderProps) => (
  <Box row mt={56} vcenter>
    <Icon color={iconColor} iconName={iconName} size="xlarge" mr={24} />
    <Text
      color="mono900"
      i18n={titleI18n}
      variant="heading2"
      i18nProps={titleI18nProps}
    />
  </Box>
);

export default Header;
