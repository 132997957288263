import { CurrentLocationTierType } from 'features/biller/types';

import { PAST_DUE_DIALOG_VIEW_KEY } from './constants';
import {
  afterGracePeriodNonBillingStrategy,
  afterGracePeriodStrategy,
  enforcedLocationStrategy,
  gracePeriodNonBillingStrategy,
  hideStrategy,
} from './viewKeyStrategies';

export type GetInitialViewKeyProps = {
  displayMultilocationEnforcementDialog: boolean;
  shouldShowEnforcementDialogOnNavigation: boolean;
  canManageTier: boolean;
  preSetInitialViewKey: string | null;
  currentLocationTier: CurrentLocationTierType | null;
  isPastDueAfterDeadline: boolean;
  displayDialog: boolean;
  isCurrentUserManagerial: boolean;
};

export type IGetInitialViewKey = (args: GetInitialViewKeyProps) => string;

export const getInitialViewKey: IGetInitialViewKey = ({
  displayMultilocationEnforcementDialog,
  shouldShowEnforcementDialogOnNavigation,
  canManageTier,
  preSetInitialViewKey,
  currentLocationTier,
  isPastDueAfterDeadline,
  displayDialog,
  isCurrentUserManagerial,
}) => {
  if (
    hideStrategy({
      isCurrentUserManagerial,
      displayDialog,
      shouldShowEnforcementDialogOnNavigation,
      currentLocationTier,
    })
  ) {
    return PAST_DUE_DIALOG_VIEW_KEY.hide;
  }

  if (
    gracePeriodNonBillingStrategy({ canManageTier, isPastDueAfterDeadline })
  ) {
    return PAST_DUE_DIALOG_VIEW_KEY.grace_period_non_billing;
  }

  if (
    afterGracePeriodNonBillingStrategy({
      canManageTier,
      isPastDueAfterDeadline,
    })
  ) {
    return PAST_DUE_DIALOG_VIEW_KEY.after_grace_period_non_billing;
  }

  if (preSetInitialViewKey) return preSetInitialViewKey;

  if (
    enforcedLocationStrategy({
      displayMultilocationEnforcementDialog,
      shouldShowEnforcementDialogOnNavigation,
    })
  ) {
    return PAST_DUE_DIALOG_VIEW_KEY.enforced_location;
  }

  if (afterGracePeriodStrategy({ isPastDueAfterDeadline, canManageTier })) {
    return PAST_DUE_DIALOG_VIEW_KEY.after_grace_period;
  }

  return PAST_DUE_DIALOG_VIEW_KEY.grace_period;
};
