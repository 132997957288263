import React, { memo } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';
import PropTypes from 'prop-types';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

import { I18N_BUTTON_PATH } from '../constants';

const { cxEl } = cxHelpers('AddEmployeeSuccessModal');

const ActionButtons = ({
  isPayrollEnrolled,
  messageKey,
  onClickDone,
  onAddAnotherTeamMember,
  onLinkClicked,
  onSetupPayroll,
  isPayrollSetupDone,
}) => {
  const buttonProps =
    isPayrollEnrolled && !isPayrollSetupDone
      ? {
          primaryButtonClass: 'setup-payroll',
          primaryButtonAction: onSetupPayroll,
          primaryButtonText: toI18n(`${I18N_BUTTON_PATH}.setup_payroll`),
          secondaryButtonClass: 'do-it-later',
          secondaryButtonAction: onLinkClicked,
          secondaryButtonText: toI18n(`${I18N_BUTTON_PATH}.do_it_later`),
          secondaryButtonIcon: undefined,
        }
      : {
          primaryButtonClass: 'done',
          primaryButtonAction: onClickDone,
          primaryButtonText: toI18n(`${I18N_BUTTON_PATH}.done`),
          secondaryButtonClass: 'add-team-member',
          secondaryButtonAction: onAddAnotherTeamMember,
          secondaryButtonText: toI18n(`${I18N_BUTTON_PATH}.add`),
          secondaryButtonIcon: 'Add',
        };

  return (
    <Box column mt={messageKey ? 36 : 34} gap={10}>
      <Button
        fullWidth
        variant="primary"
        onClick={buttonProps.primaryButtonAction}
        className={cxEl(buttonProps.primaryButtonClass)}
      >
        {buttonProps.primaryButtonText}
      </Button>

      <Button
        fullWidth
        variant="tertiary"
        onClick={buttonProps.secondaryButtonAction}
        startIcon={buttonProps.secondaryButtonIcon}
        className={cxEl(buttonProps.secondaryButtonClass)}
      >
        <Text variant="bodySmBold">{buttonProps.secondaryButtonText}</Text>
      </Button>
    </Box>
  );
};

ActionButtons.propTypes = {
  isPayrollEnrolled: PropTypes.bool,
  messageKey: PropTypes.string,
  onClickDone: PropTypes.func.isRequired,
  onAddAnotherTeamMember: PropTypes.func.isRequired,
  onLinkClicked: PropTypes.func.isRequired,
  onSetupPayroll: PropTypes.func.isRequired,
};

export default memo(ActionButtons);
