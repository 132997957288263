import React from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@mui/system';
import withCx, { CxProps } from 'fe-core/util/withCx';
import loaderClover from 'fe-design-base/assets/icons/loader/loaderClover.png';
import loaderDark from 'fe-design-base/assets/icons/loader/loaderDark.png';
import loaderDestructive from 'fe-design-base/assets/icons/loader/loaderDestructive.png';
import loaderLight from 'fe-design-base/assets/icons/loader/loaderLight.png';

import { CIRCULAR_INFINITE_SIZES, Size, Variant } from '../constants';

export interface CircularInfiniteProps {
  variant?: Variant;
  size?: Size;
  alt?: string;
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const getVariant = (variant: Variant) => {
  switch (variant) {
    case 'light':
      return loaderLight;

    case 'dark':
      return loaderDark;

    case 'destructive':
      return loaderDestructive;

    case 'clover':
      return loaderClover;

    default:
      return loaderDark;
  }
};

const CircularInfinite = ({
  alt = 'Circular Infinite Loader',
  cx,
  size = 'small',
  variant = 'light',
}: CircularInfiniteProps & CxProps) => {
  const loaderSize = CIRCULAR_INFINITE_SIZES[size];
  const loaderIcon = getVariant(variant);

  return (
    <Box
      sx={{ width: `${loaderSize}px`, height: `${loaderSize}px` }}
      className={cx(variant, size)}
    >
      <Box sx={{ display: 'flex', animation: `${spin} .6s infinite linear` }}>
        <img
          style={{ width: `${loaderSize}px`, height: `${loaderSize}px` }}
          src={loaderIcon}
          alt={alt}
        />
      </Box>
    </Box>
  );
};

export default withCx<CircularInfiniteProps>('FDBCircularInfinite')(
  CircularInfinite
);
