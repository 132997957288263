import { CurrentLocationTierType } from 'features/biller/types';

export type HideStrategyProps = {
  isCurrentUserManagerial: boolean;
  displayDialog: boolean;
  shouldShowEnforcementDialogOnNavigation: boolean;
  currentLocationTier: CurrentLocationTierType | null;
};

export type GracePeriodStrategyProps = {
  canManageTier: boolean;
  isPastDueAfterDeadline: boolean;
};

export type EnforcedLocationStrategyProps = {
  displayMultilocationEnforcementDialog: boolean;
  shouldShowEnforcementDialogOnNavigation: boolean;
};

export type AfterGracePeriodStrategyProps = {
  isPastDueAfterDeadline: boolean;
  canManageTier: boolean;
};

export const hideStrategy = ({
  isCurrentUserManagerial,
  displayDialog,
  shouldShowEnforcementDialogOnNavigation,
  currentLocationTier,
}: HideStrategyProps) =>
  !isCurrentUserManagerial ||
  displayDialog ||
  (shouldShowEnforcementDialogOnNavigation &&
    currentLocationTier?.get('name') === 'basic');

export const gracePeriodNonBillingStrategy = ({
  canManageTier,
  isPastDueAfterDeadline,
}: GracePeriodStrategyProps) => !canManageTier && !isPastDueAfterDeadline;

export const afterGracePeriodNonBillingStrategy = ({
  canManageTier,
  isPastDueAfterDeadline,
}: GracePeriodStrategyProps) => !canManageTier && isPastDueAfterDeadline;

export const enforcedLocationStrategy = ({
  displayMultilocationEnforcementDialog,
  shouldShowEnforcementDialogOnNavigation,
}: EnforcedLocationStrategyProps) =>
  displayMultilocationEnforcementDialog ||
  shouldShowEnforcementDialogOnNavigation;

export const afterGracePeriodStrategy = ({
  isPastDueAfterDeadline,
  canManageTier,
}: AfterGracePeriodStrategyProps) => isPastDueAfterDeadline && canManageTier;
