import { useMemo } from 'react';

import { PAST_DUE_DIALOG_VIEW_KEY as VIEW_KEY } from './constants';
import { UseHideCloseIconProps } from './types';

export const useHideCloseIcon = ({
  currentViewKey,
  isPastDueAfterDeadline,
}: UseHideCloseIconProps) =>
  useMemo(
    () =>
      isPastDueAfterDeadline &&
      currentViewKey !== VIEW_KEY.stay_on_basic &&
      currentViewKey !== VIEW_KEY.restored_to_paid,
    [isPastDueAfterDeadline, currentViewKey]
  );
