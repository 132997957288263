import React, { useCallback, useEffect } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button/Button';

import CalendlyModal from 'components/CalendlyModal/CalendlyModal';
import { useCalendlyModalHandlers } from 'components/CalendlyModal/hooks';

import { toI18n } from 'util/i18n';

import { CLICK_TO_CALL_VIEW_KEY as VIEW_KEY } from '../constants';

import CalendlyLink, { CALENDLY_URL } from './CalendlyLink';
import ModalViewTracker from './ModalViewTracker';
import { ViewType, ViewTypeProps } from './types';

const OutsideBusinessHours: ViewType = ({
  setDialogTitle,
  closeClickToCallDialog,
}: ViewTypeProps) => {
  const { calendlyModalIsOpen, handleCalendlyClick, handleCalendlyClose } =
    useCalendlyModalHandlers();
  useEffect(() => {
    setDialogTitle(
      toI18n('monetization.click_to_call.outside_business_hours.title')
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClickPrimary = useCallback(() => {
    closeClickToCallDialog();
  }, [closeClickToCallDialog]);

  return (
    <ModalViewTracker viewName={VIEW_KEY.outside_business_hours}>
      <Box mb={24}>
        <Text variant="body">
          {toI18n('monetization.click_to_call.outside_business_hours.subtitle')}
        </Text>
      </Box>

      <CalendlyLink onClickLink={handleCalendlyClick} />

      <Box hright>
        <Button onClick={handleOnClickPrimary}>
          {toI18n('monetization.click_to_call.outside_business_hours.cta')}
        </Button>
      </Box>
      <CalendlyModal
        url={CALENDLY_URL}
        open={calendlyModalIsOpen}
        handleCloseModal={handleCalendlyClose}
      />
    </ModalViewTracker>
  );
};

export default OutsideBusinessHours;
