import React from 'react';
import Text from 'fe-design-base/atoms/Text';

import { toI18n } from 'util/i18n';

import Footer from '../components/Footer';
import Header from '../components/Header';
import PastDueDialogBox from '../components/PastDueDialogBox';

import { AfterGracePeriodViewProps } from './types';
import { useHandleOnClickSecondary } from './useHandleOnClickSecondary';

const secondaryCtaText = toI18n(
  'past_due_subscription.dialog.after_grace_period.secondary_cta'
);

export const AfterGracePeriodView = ({
  noChange,
  isLoading,
  setIsLoading,
  onClickPrimary,
  primaryCtaText,
  primaryUxElement,
  previousTierName,
  setCurrentViewKey,
  setDecidedToStayOnBasic,
}: AfterGracePeriodViewProps) => {
  const handleOnClickSecondary = useHandleOnClickSecondary({
    noChange,
    setIsLoading,
    setCurrentViewKey,
    setDecidedToStayOnBasic,
  });

  return (
    <PastDueDialogBox>
      <Header
        titleI18n="past_due_subscription.dialog.after_grace_period.title"
        iconColor="orange300"
        iconName="Warning"
      />
      <Text
        variant="body"
        color="mono900"
        i18n="past_due_subscription.dialog.after_grace_period.description"
        i18nProps={{
          previousTierName,
        }}
        asHTML
      />
      <Footer
        isLoading={isLoading}
        onClickPrimary={onClickPrimary}
        primaryCtaText={primaryCtaText}
        primaryUxElement={primaryUxElement}
        secondaryCtaText={secondaryCtaText}
        secondaryUxElement="stay-on-basic-cta"
        onClickSecondary={handleOnClickSecondary}
      />
    </PastDueDialogBox>
  );
};

export default AfterGracePeriodView;
