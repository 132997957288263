import './StayOnBasicView.scss';

import React, { useCallback } from 'react';
import Text from 'fe-design-base/atoms/Text';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

import Features from '../components/Features';
import Footer from '../components/Footer';
import Header from '../components/Header';
import PastDueDialogBox from '../components/PastDueDialogBox';

import { StayOnBasicViewProps } from './types';
const { cx } = cxHelpers('StayOnBasicView');

const basicFeatures = Object.values(
  toI18n('past_due_subscription.dialog.stay_on_basic.features')
) as string[];
const ctaText = toI18n(
  'past_due_subscription.dialog.stay_on_basic.primary_cta'
);

export const StayOnBasicView = ({
  closePastDueDialog,
}: StayOnBasicViewProps) => {
  const handleOnClick = useCallback(() => {
    closePastDueDialog();
  }, [closePastDueDialog]);

  return (
    <PastDueDialogBox className={cx()}>
      <Header
        iconName="Complete"
        iconColor="green300"
        titleI18n="past_due_subscription.dialog.stay_on_basic.title"
      />
      <Text
        color="mono900"
        variant="bodyBold"
        i18n="past_due_subscription.dialog.stay_on_basic.feature_heading"
      />
      <Features features={basicFeatures} />
      <Text
        asHTML
        color="mono700"
        variant="bodySm"
        i18n="past_due_subscription.dialog.stay_on_basic.contact_information"
      />

      <Footer
        primaryCtaText={ctaText}
        onClickPrimary={handleOnClick}
        primaryUxElement="stay-on-basic-got-it-cta"
      />
    </PastDueDialogBox>
  );
};

export default StayOnBasicView;
