import { createAsyncThunk } from '@reduxjs/toolkit';

import { postDowngradeRequestCallback, postRequestCallback } from './api';
import { SLICE_NAME } from './constants';

export const createRequestCallback = createAsyncThunk(
  `${SLICE_NAME}/requestCallback`,
  (
    { payload, setIsPrimaryLoading, setCurrentViewKey, viewName },
    { rejectWithValue }
  ) =>
    postRequestCallback(payload)
      .then(() => setCurrentViewKey(viewName))
      .catch(error => rejectWithValue(error.body || error.message))
      .finally(() => setIsPrimaryLoading(false))
);

export const createDowngradeRequestCallback = createAsyncThunk(
  `${SLICE_NAME}/downgradeRequestCallback`,
  ({ payload, onSuccess }, { rejectWithValue }) =>
    postDowngradeRequestCallback(payload)
      .then(() => onSuccess())
      .catch(error => rejectWithValue(error.body || error.message))
);
