import '../NBKShared.scss';

import React, { useCallback } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button/Button';

import NBKDialogWrapper from 'features/earlyLife/NewBusinessKit/components/NBKDialogWrapper';
import { NBK_CALENDLY_URL } from 'features/monetization/ClickToCallDialog/constants';
import {
  ViewType,
  ViewTypeProps,
} from 'features/monetization/ClickToCallDialog/views/types';

import Image from 'components/Image';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';
import { EVENT_CATEGORIES } from 'util/tracking_constants';

const { cxEl } = cxHelpers('NBK');

const NBKOutsideBusinessHours: ViewType = ({
  closeDialogWithoutTracking,
}: ViewTypeProps) => {
  const handleOnClickPrimary = useCallback(() => {
    window.open(NBK_CALENDLY_URL, '_blank');
    closeDialogWithoutTracking();
  }, [closeDialogWithoutTracking]);

  return (
    <NBKDialogWrapper
      eventCategory={EVENT_CATEGORIES.MODAL_CALLBACK_FORM}
      duringBusinessHours={false}
    >
      <Box
        mr={24}
        ml={24}
        mt={24}
        mb={4}
        row
        justifyContentCenter
        alignItemsCenter
      >
        <Box mr={16}>
          <Image
            className={cxEl('img')}
            src={require('assets/message-with-phone.png')}
          />
        </Box>
        <Box pt={14}>
          <Text variant="heading2">
            {toI18n('new_business_kit.outside_business_hours.header')}
          </Text>
        </Box>
      </Box>
      <hr />
      <Box mt={4} mb={24}>
        <Text variant="body">
          {toI18n('new_business_kit.outside_business_hours.subheader')}
        </Text>
      </Box>

      <Box hright>
        <Button onClick={handleOnClickPrimary} uxElement="schedule_call">
          {toI18n('new_business_kit.outside_business_hours.primary_cta')}
        </Button>
      </Box>
    </NBKDialogWrapper>
  );
};

export default NBKOutsideBusinessHours;
