import React from 'react';
import Box from 'fe-design-base/atoms/Box';

import { cxHelpers } from 'util/className';

import { PastDueDialogBoxProps } from './types';
const { cx } = cxHelpers('PastDueDialogBox');

export const PastDueDialogBox = ({
  children,
  className,
}: PastDueDialogBoxProps) => (
  <Box
    column
    ph={32}
    pb={32}
    gap={32}
    maxw={576}
    spacebetween
    className={`${cx()} ${className}`}
  >
    {children}
  </Box>
);

export default PastDueDialogBox;
