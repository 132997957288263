import React, { PureComponent } from 'react';

import Box from 'components/Box';
import Icon from 'components/Icon';
import Text from 'components/Text';

import cxHelpers from 'util/className';

@cxHelpers('ImageOverlayBox')
export default class ImageOverlayBox extends PureComponent {
  render() {
    const { children, ...props } = this.props;

    return (
      <Box
        absolute
        bottom={0}
        left={0}
        right={0}
        h={50}
        bg="rgba(0, 0, 0, 0.4)"
        cursor="pointer"
        hcenter
        vcenter
        {...props}
      >
        <Icon type="search" />
        <Box mlxs>
          <Text white>{children}</Text>
        </Box>
      </Box>
    );
  }
}
