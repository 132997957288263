export const CLICK_TO_CALL_VIEW_KEY = {
  outside_business_hours: 'outside_business_hours',
  within_business_hours_request: 'within_business_hours_request',
  within_business_hours_success: 'within_business_hours_success',
  nbk_outside_business_hours: 'nbk_outside_business_hours',
  nbk_within_business_hours_request: 'nbk_within_business_hours_request',
  nbk_within_business_hours_success: 'nbk_within_business_hours_success',
};

export const NBK_CALENDLY_URL =
  'https://calendly.com/homebase-small-business-advisors/chat-with-a-homebase-business-advisor?from=slack&month=2024-02';
