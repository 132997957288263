import { createSelector } from 'reselect';

import { getCurrentCompanyAge, getRolloutEnabled } from 'selectors/session';

const selectPayrollCustomer = state =>
  !!state.getIn(['session', 'payroll_flags', 'payroll_dashboard']) ||
  !!state.getIn(['session', 'payroll_flags', 'implementation_dashboard']);

const selectCustomerInTrial = state =>
  state.getIn(['session', 'currentCompany', 'hasActiveTrialPeriod']);

const selectPayingCustomer = state =>
  state.getIn(['session', 'currentCompany', 'isPaying']);

const selectCompanyCreatedWithinPast90Days = createSelector(
  getCurrentCompanyAge,
  companyAge => companyAge <= 90
);

// Qualifying Customers should be able to chat, email or request a call from the Help page
export const selectCanContactHomebase = createSelector(
  selectPayrollCustomer,
  selectCustomerInTrial,
  selectPayingCustomer,
  selectCompanyCreatedWithinPast90Days,
  (payrollCustomer, customerInTrial, payingCustomer, createdPast90) =>
    payrollCustomer || customerInTrial || payingCustomer || createdPast90
);

/*
  Current time is within Monday-Friday, 8 AM to 7 PM Central
  and is not thanksgiving, new years, or christmas day
 */
export const selectIsWithinBusinessHours = state =>
  state.get('help').withinBusinessHours;

export const selectIsCheckHoursFulfilled = state =>
  state.get('help').isCheckHoursFulfilled;

export const selectClosestBusinessDay = state =>
  state.get('help').closestBusinessDay;

export const selectClickToRequestCallbackActive = state =>
  getRolloutEnabled(state, 'click_to_request_callback');

export const selectRequestCallbackPending = state =>
  state.getIn(['help']).requestCallbackPending;

export const selectRequestCallbackSent = state =>
  state.getIn(['help']).requestCallbackSent;
