import React, { ReactNode, useMemo } from 'react';
import { FormControlLabel } from '@mui/material';
import Box from 'fe-design-base/atoms/Box';
import RadioButton, {
  RadioButtonProps,
} from 'fe-design-base/atoms/RadioButton';
import Text from 'fe-design-base/atoms/Text';
import { Field } from 'formik';

interface RadioButtonFieldProps
  extends Omit<RadioButtonProps, 'onChange' | 'name'> {
  label?: string | ReactNode;
  helperText?: string;
  name?: string; // Not needed when using this in a Radio Button Group
}

const RadioButtonField = ({
  label,
  helperText,
  ...radioButtonProps
}: RadioButtonFieldProps) => {
  const labelNode = useMemo(
    () =>
      typeof label === 'string' ? (
        <>
          <Text
            variant="body"
            color={radioButtonProps.disabled ? 'mono500' : 'mono900'}
          >
            {label}
          </Text>
          {helperText && (
            <Box mt={8}>
              <Text
                variant="captions"
                color={radioButtonProps.disabled ? 'mono500' : 'mono700'}
              >
                {helperText}
              </Text>
            </Box>
          )}
        </>
      ) : (
        label
      ),
    [label, radioButtonProps.disabled, helperText]
  );

  return (
    <FormControlLabel
      className="LeftInput"
      control={<Field as={RadioButton} label={label} {...radioButtonProps} />}
      label={labelNode}
    />
  );
};

export default RadioButtonField;
