import { useCallback } from 'react';

import { PAST_DUE_DIALOG_VIEW_KEY } from '../constants';

import { UseHandleOnClickSecondaryProps } from './types';

export const useHandleOnClickSecondary = ({
  noChange,
  setIsLoading,
  setCurrentViewKey,
  setDecidedToStayOnBasic,
}: UseHandleOnClickSecondaryProps) =>
  useCallback(() => {
    setIsLoading(true);
    noChange()
      .then(() => {
        setCurrentViewKey(PAST_DUE_DIALOG_VIEW_KEY.stay_on_basic);
        setDecidedToStayOnBasic(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [setIsLoading, setDecidedToStayOnBasic, setCurrentViewKey, noChange]);
