import { useMemo } from 'react';

import { labelForPlanName } from '../util';

import { UseCurrentTierNameProps } from './types';

export const useCurrentTierName = ({
  currentLocationTier,
}: UseCurrentTierNameProps) =>
  useMemo(
    () => labelForPlanName(currentLocationTier?.get('name')),
    [currentLocationTier]
  );
