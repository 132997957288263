import React from 'react';
import useId from '@mui/material/utils/useId';
import FormField, { FormFieldProps } from 'fe-design-base/atoms/FormField';
import NumberInput, {
  NumberInputProps,
} from 'fe-design-base/atoms/NumberInput';
import { Field, FormikValues, useFormikContext } from 'formik';

export type NumberFieldProps = Omit<FormFieldProps, 'children'> &
  Omit<NumberInputProps, 'value' | 'onChange'>;

const NumberField = ({
  name,
  label,
  inputId,
  width,
  fullWidth,
  labelPosition,
  labelWidth,
  shrink,
  disabled,
  hasAsterisk,
  helperText,
  successText,
  ...inputProps
}: NumberFieldProps) => {
  const { touched, errors } = useFormikContext<FormikValues>();
  const error = touched?.[name] && errors?.[name] && !disabled;
  const fieldId = useId();

  return (
    <FormField
      label={label}
      name={name}
      inputId={inputId || fieldId}
      width={width}
      fullWidth={fullWidth}
      labelPosition={labelPosition}
      labelWidth={labelWidth}
      shrink={shrink}
      disabled={disabled}
      uxElement={inputProps.uxElement}
      hasAsterisk={hasAsterisk}
      helperText={helperText}
      successText={successText}
    >
      <Field
        as={NumberInput}
        name={name}
        id={inputId || fieldId}
        disabled={disabled}
        fullWidth={fullWidth}
        error={error}
        label={label}
        {...inputProps}
      />
    </FormField>
  );
};

export default NumberField;
