import '../NBKShared.scss';

import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon/Icon';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button/Button';
import PhoneField from 'fe-design-base/molecules/PhoneField';

import { createRequestCallback } from 'features/earlyLife/NewBusinessKit/actions';
import NBKDialogWrapper from 'features/earlyLife/NewBusinessKit/components/NBKDialogWrapper';
import {
  CLICK_TO_CALL_VIEW_KEY as VIEW_KEY,
  NBK_CALENDLY_URL as CALENDLY_URL,
} from 'features/monetization/ClickToCallDialog/constants';
import CalendlyLink from 'features/monetization/ClickToCallDialog/views/CalendlyLink';
import {
  ViewType,
  ViewTypeProps,
} from 'features/monetization/ClickToCallDialog/views/types';
import { isCtcCtaDisabled } from 'features/monetization/util';

import CalendlyModal from 'components/CalendlyModal/CalendlyModal';
import { useCalendlyModalHandlers } from 'components/CalendlyModal/hooks';
import Image from 'components/Image';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';
import { EVENT_CATEGORIES } from 'util/tracking_constants';

const { cxEl } = cxHelpers('NBK');

const NBKWithinBusinessHoursRequest: ViewType = ({
  dirty,
  values,
  isValid,
  onSubmit,
  talkdeskId,
  isSubmitting,
  isPrimaryLoading,
  setCurrentViewKey,
  setIsPrimaryLoading,
}: ViewTypeProps) => {
  const { calendlyModalIsOpen, handleCalendlyClick, handleCalendlyClose } =
    useCalendlyModalHandlers();
  const viewName = VIEW_KEY.nbk_within_business_hours_success;

  const handleOnClickPrimary = useCallback(() => {
    setIsPrimaryLoading(true);

    const payload = {
      phone: values.phone,
      source: 'new_business_kit',
      talkdesk_id: talkdeskId,
    };

    onSubmit?.({ payload, setIsPrimaryLoading, setCurrentViewKey, viewName });
  }, [
    onSubmit,
    viewName,
    talkdeskId,
    values.phone,
    setCurrentViewKey,
    setIsPrimaryLoading,
  ]);

  return (
    <NBKDialogWrapper
      eventCategory={EVENT_CATEGORIES.REQUEST_CALLBACK_MODAL}
      duringBusinessHours
    >
      <Box
        row
        mb={4}
        mr={24}
        ml={24}
        mt={24}
        justifyContentCenter
        alignItemsCenter
      >
        <Box mr={16}>
          <Image
            src={require('assets/message-with-phone.png')}
            className={cxEl('img')}
          />
        </Box>
        <Box pt={14}>
          <Text variant="heading2">
            {toI18n('new_business_kit.within_business_hours_request.header')}
          </Text>
        </Box>
      </Box>
      <hr />
      <Box mb={24}>
        <Text
          variant="bodyBold"
          i18n="new_business_kit.within_business_hours_request.title"
        />
      </Box>
      <Box mb={10}>
        <Box mt={20}>
          <PhoneField
            name="phone"
            width="340px"
            uxElement="phone_number"
            placeholder="e.g. (628) 204-3443"
          />
        </Box>
      </Box>
      <Box mt={48} mb={24} row alignItemsCenter>
        <Icon iconName="TimeClock" size="small" color="mono700" />
        <Box ml={12}>
          <Text
            color="mono900"
            variant="body"
            i18n="new_business_kit.within_business_hours_request.working_hours"
          />
        </Box>
      </Box>
      <CalendlyLink
        url={CALENDLY_URL}
        onClickLink={handleCalendlyClick}
        linkPrefixCopy="new_business_kit.within_business_hours_request.call_later.copy"
      />
      <Box hright>
        <Button
          isLoading={isPrimaryLoading}
          disabled={isCtcCtaDisabled({ dirty, isValid, isSubmitting, values })}
          onClick={handleOnClickPrimary}
          uxElement="request_call"
        >
          {toI18n(
            'new_business_kit.within_business_hours_request.call_later.link'
          )}
        </Button>
      </Box>
      <CalendlyModal
        url={CALENDLY_URL}
        open={calendlyModalIsOpen}
        handleCloseModal={handleCalendlyClose}
      />
    </NBKDialogWrapper>
  );
};

export default connect(null, {
  onSubmit: createRequestCallback,
})(NBKWithinBusinessHoursRequest);
