import React, { useCallback } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Link from 'fe-design-base/molecules/Link';

import { toI18n } from 'util/i18n';
import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

export const CALENDLY_URL =
  'https://calendly.com/homebase-small-business-advisors/chat-with-a-homebase-business-advisor?from=slack&month=2024-02';

export type CalendlyLinkProps = {
  url?: string;
  onClickLink: () => void;
  linkPrefixCopy?: string;
};

const CalendlyLink = ({
  onClickLink,
  url = CALENDLY_URL,
  linkPrefixCopy = 'monetization.click_to_call.schedule_a_call.text',
}: CalendlyLinkProps) => {
  const buttonText = toI18n('monetization.click_to_call.schedule_a_call.link');

  const trackUx = useTrackUx({
    link_url: url,
    button_text: buttonText,
    element: 'schedule_call',
  } as never);

  const handleClickLink = useCallback(() => {
    trackUx(EVENT_ACTIONS.LINK_CLICKED, TRACK_ACTION_TYPES.CLICK);
    onClickLink();
  }, [onClickLink, trackUx]);

  return (
    <Box mb={24}>
      <Text variant="body">
        {toI18n(linkPrefixCopy)}{' '}
        <Link onClick={handleClickLink}>{buttonText}</Link>
      </Text>
    </Box>
  );
};

export default CalendlyLink;
