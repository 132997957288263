import React from 'react';
import { Badge as MuiBadge } from '@mui/material';
import withCx, { CxProps } from 'fe-core/util/withCx';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import withDesignBase from 'fe-design-base/styles/withDesignBaseTheme';
import { ColorProp, PaletteProp } from 'fe-design-base/types';

export type BadgeVariant = 'loud' | 'accent';

export interface BadgeProps {
  text?: number | string;
  variant?: BadgeVariant;
  children?: React.ReactNode;
  disabled?: boolean;
  borderColor?: ColorProp | PaletteProp;
}

const Badge = ({
  cx,
  text,
  variant,
  children,
  disabled,
  borderColor,
}: BadgeProps & CxProps) => {
  const isAccent = variant === 'accent';
  const isLoud = variant === 'loud';

  const bgColor = (() => {
    if (disabled && isAccent) return; // No color specified
    else if (!disabled && isAccent) return 'accent900';
    else if (disabled && isLoud) return 'red100';
    else if (!disabled && isLoud) return 'red300';
    else if (!disabled) return 'purple500'; // default color
    return 'purple300'; // default disabled color
  })();

  return children ? (
    <MuiBadge
      badgeContent={
        <Box
          className={cx({
            text,
            disabled,
            ...(variant ? { [variant]: true } : {}),
          })}
          bradiusfull
          bw={borderColor ? '2px' : undefined}
          bcolor={borderColor}
        >
          {text && (
            <Text variant="captionBold" color="mono0">
              {text}
            </Text>
          )}
        </Box>
      }
    >
      {children}
    </MuiBadge>
  ) : (
    <Box
      className={cx()}
      bgcolor={bgColor}
      w={text ? 'fit-content' : '12px'}
      h={text ? '18px' : '12px'}
      ph={text ? '6px' : undefined}
      bradiusfull
    >
      {text && (
        <Text variant="captionBold" color="mono0">
          {text}
        </Text>
      )}
    </Box>
  );
};

export default withDesignBase(withCx<BadgeProps>('FDBBadge')(Badge));
