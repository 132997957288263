import './SwitchToPayrollBanner.scss';

import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { postJSON } from 'api/fetch';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';
import Divider from 'fe-design-base/molecules/Divider';
import Link from 'fe-design-base/molecules/Link';
import { useIsMediumScreen } from 'fe-design-base/utils/useMediaQuery';
import { useCountDownTimer } from 'hooks/useCountDownTimer';

import { getCurrentLocationId, getCurrentUserId } from 'selectors/session';

import Image from 'components/Image';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';
import { useTrackUxHandler, withUxRoot } from 'util/uxEvents';

import CountDownDigits from './CountDownDigits';

const { cx } = cxHelpers('SwitchToPayrollBanner');

export const SwitchToPayrollBanner = ({ currentUserId, currentLocationId }) => {
  const [showBanner, setShowBanner] = useState(true);

  const isMediumScreen = useIsMediumScreen();
  const mobileView = !isMediumScreen;

  const trackViewEvent = useTrackUxHandler(
    EVENT_ACTIONS.BANNER_VIEWED,
    TRACK_ACTION_TYPES.VIEW
  );

  const trackCloseEvent = useTrackUxHandler(
    EVENT_ACTIONS.DISMISS_CLICKED,
    TRACK_ACTION_TYPES.CLICK
  );

  useEffect(() => {
    if (showBanner) trackViewEvent();
  }, [showBanner, trackViewEvent]);

  const handleClose = useCallback(() => {
    trackCloseEvent();
    setShowBanner(false);
    return postJSON('/monetization/dismiss_eoy_banner', {
      location_id: currentLocationId,
      user_id: currentUserId,
    });
  }, [currentLocationId, currentUserId, trackCloseEvent]);

  const onHideTimer = useCallback(() => setShowBanner(false), []);

  const timeLeft = useCountDownTimer({ onHideTimer, hideTimer: !showBanner });

  if (!showBanner || !timeLeft) {
    return null;
  }

  const { days, hours, minutes } = timeLeft;

  return (
    <Box className={cx()} row bgcolor="primary100" flex alignItemsCenter>
      {!mobileView && (
        <Box>
          <Image h={72} src={require('../assets/doodles.png')} />
        </Box>
      )}

      <Box
        flex
        wrap
        alignItemsCenter
        justifyContentCenter
        m={10}
        gap={6}
        grow={1}
      >
        <Box wrap alignItemsCenter justifyContentCenter gap={6} tcenter>
          <Box flex color="primary900" alignItemsCenter>
            <CountDownDigits label="days" value={days} />

            <Divider
              spacing={8}
              type="strong"
              color="purple900"
              orientation="vertical"
            />

            <CountDownDigits label="hours" value={hours} />

            <Divider
              spacing={8}
              type="strong"
              color="purple900"
              orientation="vertical"
            />

            <CountDownDigits label="minutes" value={minutes} />
          </Box>
          <Text
            color="primary900"
            i18n="switch_to_payroll_banner.till_deadline"
          />
        </Box>

        <Box tcenter>
          <Text
            color="primary900"
            i18n="switch_to_payroll_banner.switch_to_payroll"
          />
        </Box>

        <Link endIcon="ArrowRight" uxElement linkTo="/payroll">
          {toI18n('switch_to_payroll_banner.get_started')}
        </Link>
      </Box>

      <Box mh={16}>
        <Icon
          onClick={handleClose}
          size="small"
          iconName="Close"
          color="primary500"
          dataTestId="dismiss-icon"
        />
      </Box>
    </Box>
  );
};

export default connect(
  state => ({
    currentUserId: getCurrentUserId(state),
    currentLocationId: getCurrentLocationId(state),
  }),
  {}
)(
  withUxRoot({
    productArea: PRODUCT_AREAS.GLOBAL,
    eventCategory: EVENT_CATEGORIES.PAYROLL_EOY_2023_BANNER,
  })(SwitchToPayrollBanner)
);
