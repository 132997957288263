import React, { useEffect, useRef, useState } from 'react';
import { FormHelperText as MuiFormHelperText } from '@mui/material';
import Text from 'fe-design-base/atoms/Text';
import AlertText from 'fe-design-base/molecules/AlertText';
import { ErrorMessage } from 'formik';

import { TOP_STYLE_ADDEND } from './constants';
import { FormHelperTextProps } from './index.type';

const FormHelperText = ({
  name,
  className,
  hasError,
  successText,
  helperText,
  readOnly,
  disabled,
  isComponentResizable,
  topStyleAddend = TOP_STYLE_ADDEND,
  absolutelyPositioned = true,
}: FormHelperTextProps) => {
  const helperRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [top, setTop] = useState<number>(0);

  useEffect(() => {
    let observer: ResizeObserver;

    if (absolutelyPositioned) {
      const parentNode = helperRef.current.parentNode as HTMLElement;

      if (isComponentResizable) {
        observer = new ResizeObserver(([{ target }]: any) => {
          setTop(target.offsetHeight + topStyleAddend);
        });
        observer.observe(parentNode);
      } else {
        setTop(parentNode.offsetHeight + topStyleAddend);
      }
    }

    return () => {
      observer?.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const EmptyElement = <div ref={helperRef} style={{ visibility: 'hidden' }} />;

  if (readOnly || disabled) return EmptyElement;

  if (hasError)
    return (
      <div
        ref={helperRef}
        style={{
          top,
          width: '100%',
          position: absolutelyPositioned ? 'absolute' : 'static',
        }}
      >
        <ErrorMessage name={name}>
          {msg => (
            <MuiFormHelperText
              error
              className={className}
              sx={{ position: 'relative !important' }}
            >
              <AlertText variant="error" message={msg} />
            </MuiFormHelperText>
          )}
        </ErrorMessage>
      </div>
    );

  if (successText)
    return (
      <MuiFormHelperText className={className} sx={{ top }} ref={helperRef}>
        <AlertText variant="success" message={successText} />
      </MuiFormHelperText>
    );

  if (helperText)
    return (
      <MuiFormHelperText className={className} sx={{ top }} ref={helperRef}>
        <Text variant="captions" color="mono700" component="span">
          {helperText}
        </Text>
      </MuiFormHelperText>
    );

  return EmptyElement;
};

export default FormHelperText;
