import './OverlayModal.scss';

import React, { PureComponent } from 'react';
import ReactModal from 'react-modal';
import { omit } from 'lodash';
import PropTypes from 'prop-types';

import cxHelpers from 'util/className';

@cxHelpers('OverlayModal')
export default class OverlayModal extends PureComponent {
  // Props are passed through to underlying react-modal:
  // https://github.com/reactjs/react-modal#usage
  static propTypes = {
    shouldCloseOnEsc: PropTypes.bool,
    shouldCloseOnOverlayClick: PropTypes.bool,
    preventScroll: PropTypes.bool,
    large: PropTypes.bool,
  };

  static defaultProps = {
    shouldCloseOnEsc: false,
    shouldCloseOnOverlayClick: false,
  };

  render() {
    const { preventScroll, large } = this.props;

    return (
      <ReactModal
        {...omit(this.props, ['preventScroll', 'large'])}
        overlayClassName={this.cxEl('overlay')}
        className={this.cx({ preventScroll, large })}
        contentLabel="Modal"
      />
    );
  }
}
