import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import CircularInfinite from 'fe-design-base/molecules/Loaders/CircularInfinite';
import Dialog from 'fe-design-base/organisms/Dialog';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { hideModal } from 'actions/modals';

import { getCurrentUserPhone } from 'selectors/session';

import NBKOutsideBusinessHours from 'features/earlyLife/NewBusinessKit/components/NBKOutsideBusinessHours';
import NBKWithinBusinessHoursRequest from 'features/earlyLife/NewBusinessKit/components/NBKWithinBusinessHoursRequest';
import NBKWithinBusinessHoursSuccess from 'features/earlyLife/NewBusinessKit/components/NBKWithinBusinessHoursSuccess';
import { NBK_TRACKING } from 'features/earlyLife/NewBusinessKit/constants';
import {
  selectIsCheckHoursFulfilled,
  selectIsWithinBusinessHours,
} from 'features/help/selectors';

import Box from 'components/Box';

import { cxHelpers } from 'util/className';
import { trackUxEvent } from 'util/tracking';
import {
  EVENT_ACTIONS,
  EVENT_CATEGORIES,
  PRODUCT_AREAS,
  TRACK_ACTION_TYPES,
} from 'util/tracking_constants';
import { useExtendUx, useTrackUx, withUxRoot } from 'util/uxEvents';
import { phoneSchema } from 'util/yupHelpers';

import { checkHours as getCheckHours } from '../../help/actions';

import OutsideBusinessHours from './views/OutsideBusinessHours';
import { ViewType } from './views/types';
import WithinBusinessHoursRequest from './views/WithinBusinessHoursRequest';
import WithinBusinessHoursSuccess from './views/WithinBusinessHoursSuccess';
import { CLICK_TO_CALL_VIEW_KEY as VIEW_KEY } from './constants';

const { cx } = cxHelpers('ClickToCallDialog');
export const VIEWS = {
  [VIEW_KEY.outside_business_hours]: OutsideBusinessHours,
  [VIEW_KEY.within_business_hours_request]: WithinBusinessHoursRequest,
  [VIEW_KEY.within_business_hours_success]: WithinBusinessHoursSuccess,
  [VIEW_KEY.nbk_outside_business_hours]: NBKOutsideBusinessHours,
  [VIEW_KEY.nbk_within_business_hours_request]: NBKWithinBusinessHoursRequest,
  [VIEW_KEY.nbk_within_business_hours_success]: NBKWithinBusinessHoursSuccess,
};

const FormSchema = Yup.object().shape({ ...phoneSchema });

interface ClickToCallDialogProps {
  onSubmit?: boolean;
  isSubmitting?: boolean;
  viewPrefix?: '' | 'nbk_';
  talkdeskId?: string | null;
  initialPhoneNumber?: string;
  isWithinBusinessHours?: boolean;
  isCheckHoursFulfilled?: boolean;
  openedFromURLParam?: boolean;
  closeClickToCallDialog: () => void;
  checkHours?: (
    props: Record<'nbk_dialog', boolean>
  ) => boolean | void | undefined;
}

const ClickToCallDialog = ({
  checkHours,
  viewPrefix = '',
  talkdeskId = null,
  initialPhoneNumber,
  isWithinBusinessHours,
  isCheckHoursFulfilled,
  openedFromURLParam,
  closeClickToCallDialog,
}: ClickToCallDialogProps) => {
  const [dialogTitle, setDialogTitle] = useState('');
  const [reload, setReload] = useState(true);
  const [isPrimaryLoading, setIsPrimaryLoading] = useState(false);
  const [currentViewKey, setCurrentViewKey] = useState('');
  const [dismissClicked, setDismissClicked] = useState(false);

  const extendUxEvent = useExtendUx();
  extendUxEvent({
    timing: isWithinBusinessHours ? 'business hours' : 'non-business hours',
    element: 'Commandbar',
  });

  const nbkEventCategory = useMemo(() => {
    if (currentViewKey === VIEW_KEY.nbk_within_business_hours_request) {
      return EVENT_CATEGORIES.REQUEST_CALLBACK_MODAL;
    } else if (currentViewKey === VIEW_KEY.nbk_within_business_hours_success) {
      return EVENT_CATEGORIES.REQUEST_CALLBACK_SUCCESS_MODAL;
    } else if (currentViewKey === VIEW_KEY.nbk_outside_business_hours) {
      return EVENT_CATEGORIES.MODAL_CALLBACK_FORM;
    }
  }, [currentViewKey]);

  const trackUx = useTrackUx();

  const handleClose = useCallback(() => {
    if (openedFromURLParam) {
      setDismissClicked(true);
    }

    if (viewPrefix === 'nbk_') {
      trackUxEvent({
        productArea: PRODUCT_AREAS.GLOBAL,
        eventCategory: nbkEventCategory,
        eventAction: EVENT_ACTIONS.DISMISS_CLICKED,
        actionType: TRACK_ACTION_TYPES.CLICK,
        properties: {
          element: 'dismiss',
          modal_source: NBK_TRACKING.MODAL_SOURCE,
          modal_version: NBK_TRACKING.MODAL_VERSION,
          during_business_hours: true,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any);
    } else {
      trackUx(EVENT_ACTIONS.DISMISS_CLICKED, TRACK_ACTION_TYPES.CLICK);
    }
    closeClickToCallDialog();
  }, [
    closeClickToCallDialog,
    nbkEventCategory,
    openedFromURLParam,
    trackUx,
    viewPrefix,
  ]);

  const CurrentView: ViewType = VIEWS[currentViewKey];

  const nbkDialog = viewPrefix === 'nbk_';

  useEffect(() => {
    if (reload) {
      checkHours?.({ nbk_dialog: nbkDialog });
      setReload(false);
    }
  }, [checkHours, nbkDialog, reload]);

  useEffect(() => {
    setCurrentViewKey(
      isWithinBusinessHours
        ? VIEW_KEY[`${viewPrefix}within_business_hours_request`]
        : VIEW_KEY[`${viewPrefix}outside_business_hours`]
    );
  }, [isWithinBusinessHours, viewPrefix]);

  const onlyDigitsPhone = initialPhoneNumber?.replace(/\D/g, '');

  return (
    <Dialog
      open={openedFromURLParam ? !dismissClicked : true}
      width={600}
      className={cx()}
      title={dialogTitle}
      disableBackdropClick
      onClose={handleClose}
    >
      {() => (
        <Formik
          // eslint-disable-next-line max-len
          // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop,@typescript-eslint/no-empty-function
          onSubmit={() => {}}
          validationSchema={FormSchema}
          initialValues={{
            primaryDisabled: true,
            phone: onlyDigitsPhone?.length === 10 ? onlyDigitsPhone : null,
          }}
        >
          {({ isValid, dirty, values, isSubmitting }) =>
            isCheckHoursFulfilled ? (
              <Form>
                {CurrentView ? (
                  <CurrentView
                    dirty={dirty}
                    values={values}
                    isValid={isValid}
                    setReload={setReload}
                    checkHours={checkHours}
                    talkdeskId={talkdeskId}
                    isSubmitting={isSubmitting}
                    setDialogTitle={setDialogTitle}
                    isPrimaryLoading={isPrimaryLoading}
                    closeClickToCallDialog={handleClose}
                    setCurrentViewKey={setCurrentViewKey}
                    setIsPrimaryLoading={setIsPrimaryLoading}
                    closeDialogWithoutTracking={closeClickToCallDialog}
                  />
                ) : null}
              </Form>
            ) : (
              <Box hcenter p={120}>
                <CircularInfinite size="medium" variant="dark" />
              </Box>
            )
          }
        </Formik>
      )}
    </Dialog>
  );
};

export const ComponentWithUxRoot = withUxRoot({
  productArea: PRODUCT_AREAS.MONETIZATION,
  eventCategory: EVENT_CATEGORIES.COMMAND_BAR,
  eventName: 'Click to call',
})(ClickToCallDialog);

export default connect(
  state => ({
    isWithinBusinessHours: selectIsWithinBusinessHours(state),
    isCheckHoursFulfilled: selectIsCheckHoursFulfilled(state),
    initialPhoneNumber: getCurrentUserPhone(state),
  }),
  {
    checkHours: getCheckHours,
    closeClickToCallDialog: hideModal,
  }
)(ComponentWithUxRoot);
