import React from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button';
import PropTypes from 'prop-types';

import { cxFunction } from 'util/className';

const DismissOnlyModal = ({
  cx,
  cxEl,
  title,
  message,
  cta,
  onRequestClose,
}) => (
  <Box w={640} className={cx()} pa={8}>
    <Box>
      <Text variant="heading4" color="mono900">
        {title}
      </Text>
      <Box mb={24} mt={8}>
        <Text variant="body" color="mono900" asHTML>
          {message}
        </Text>
      </Box>
    </Box>

    <Box row hright p="24px 4px 4px 4px">
      <Box ml={20}>
        <Button
          className={cxEl('primary_button')}
          onClick={onRequestClose}
          variant="primary"
        >
          {cta}
        </Button>
      </Box>
    </Box>
  </Box>
);

DismissOnlyModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  message: PropTypes.string,
  cta: PropTypes.string,
};

export default cxFunction('DismissOnlyModal', DismissOnlyModal);
