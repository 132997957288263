import './TextField.scss';

import React, { PureComponent } from 'react';
import NumberFormat from 'react-number-format';
import TextMask from 'react-text-mask-old';
import { TextField as MUITextField } from '@material-ui/core';
import { debounce, isNil, merge, omit } from 'lodash';
import PropTypes from 'prop-types';

import MaterialSelectInput from 'components/forms/MaterialSelectInput';
import GeosuggestInput from 'components/GeosuggestInput';

import cxHelpers from 'util/className';
import { sanitizeString } from 'util/formatter';
import { toI18n } from 'util/i18n';

import {
  DEBOUNCE_DELAY,
  DEFAULT_STYLES,
  NUMERIC_MASK_TYPES,
  THEME_OPTIONS,
  THEME_STYLES,
} from './constants';
import { formatValueFromMask, getMask, isInUS } from './util';

const TextMaskCustom = props => {
  const { type, name, value, mask, placeholder, inputStyle, ...rest } = props;

  return (
    <TextMask
      type={type}
      name={name}
      value={value}
      mask={getMask(mask)}
      placeholder={placeholder}
      // Setting guide to false avoids a breaking bug with Safari. Open Issue with
      // text-mask library: https://github.com/text-mask/text-mask/issues/891
      guide={false}
      style={inputStyle}
      {...omit(rest, ['inputRef'])}
    />
  );
};

@cxHelpers('TextField')
export default class TextField extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    mask: PropTypes.string,
    customInputType: PropTypes.string,
    customInputProps: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    inputRef: PropTypes.func,
    errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    labelI18n: PropTypes.string,
    successText: PropTypes.string,
    placeholder: PropTypes.string,
    placeholderI18n: PropTypes.string,
    debounced: PropTypes.bool,
    centered: PropTypes.bool,
    disabled: PropTypes.bool,
    numeric: PropTypes.bool,
    multiline: PropTypes.bool,
    theme: PropTypes.oneOf(THEME_OPTIONS),
    errorBorder: PropTypes.bool,
    maxLength: PropTypes.number,
    autoFocus: PropTypes.bool,
    isCustomValueApplied: PropTypes.bool,
    setIsCustomValueApplied: PropTypes.func,
    customValue: PropTypes.string,
    formikFieldHelpers: PropTypes.object,
  };

  static defaultProps = {
    debounced: false,
    centered: false,
    errorBorder: false,
  };

  state = {
    value: isNil(this.props.value) ? '' : this.props.value,
    focused: false,
  };

  constructor(props) {
    super(props);
    this.updateDebouncedOnChange();
  }

  // TODO: https://joinhomebase.atlassian.net/browse/HIRING-441
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.value !== nextProps.value) {
      this.setState({ value: nextProps.value });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.onChange !== this.props.onChange) {
      this.updateDebouncedOnChange();
    }

    if (
      this.props.isCustomValueApplied &&
      this.props.customValue &&
      this.props.formikFieldHelpers
    ) {
      this.props.formikFieldHelpers.setValue(this.props.customValue);
      this.props.setIsCustomValueApplied(false);
    }
  }

  maskKey() {
    if (this.isMask('phone')) {
      return isInUS() ? 'phoneUS' : 'phoneOther';
    }

    return this.props.mask;
  }

  isMask(mask) {
    return this.props.mask === mask;
  }

  isCurrencyMask() {
    return this.isMask('currencyUS') || this.isMask('currency');
  }

  isNumeric() {
    return this.props.numeric || NUMERIC_MASK_TYPES.includes(this.props.mask);
  }

  updateDebouncedOnChange() {
    this.debouncedOnChange = debounce(this.props.onChange, DEBOUNCE_DELAY, {
      trailing: true,
    });
  }

  styles = () => {
    const overrides = THEME_STYLES[this.props.theme] || {};
    return merge({}, DEFAULT_STYLES, overrides);
  };

  placeholder() {
    const { placeholderI18n, placeholder } = this.props;
    return placeholderI18n ? toI18n(placeholderI18n) : placeholder;
  }

  handleChange = e => {
    let value = sanitizeString(e.target.value);
    value = formatValueFromMask(value, this.props.mask);
    value = value === undefined || value === null ? '' : value;

    this.setState({ value });

    const onChange = this.props.debounced
      ? this.debouncedOnChange
      : this.props.onChange;
    if (e.target.value !== value) {
      e.target.value = value;
    }
    onChange(value, e);
  };

  handleOnFocus = evt => {
    this.setState({ focused: true });
    if (this.props.onFocus) {
      this.props.onFocus(evt);
    }
  };

  handleOnBlur = evt => {
    this.setState({ focused: false });
    if (this.props.onBlur) {
      if (evt.target) {
        // We are sending value and synthetic event to support FormikTextInput component
        this.props.onBlur(evt.target.value, evt);
      } else {
        this.props.onBlur();
      }
    }
  };

  handleNumberFormatChange = values => {
    const e = { target: { value: values.value, name: this.props.name } };
    this.handleChange(e, false);
  };

  renderWithContainer(textFieldProps) {
    const { customInputType, customInputProps, mask, name } = this.props;

    if (this.isCurrencyMask()) {
      return (
        <NumberFormat
          name={name}
          value={this.state.value}
          onValueChange={this.handleNumberFormatChange}
          thousandSeparator
          prefix={this.isMask('currencyUS') ? '$' : undefined}
          decimalScale={2}
          fixedDecimalScale
          isNumericString
          customInput={MUITextField}
          {...textFieldProps}
          onChange={undefined}
        />
      );
    }

    if (this.maskKey() === 'phoneOther' || this.maskKey() === 'numeric') {
      return (
        <NumberFormat
          name={name}
          value={this.state.value}
          onValueChange={this.handleNumberFormatChange}
          isNumericString
          allowEmptyFormatting
          allowLeadingZeros={this.maskKey() === 'phoneOther'}
          customInput={MUITextField}
          {...textFieldProps}
        />
      );
    }

    if (mask) {
      textFieldProps.InputProps.inputComponent = TextMaskCustom;
      textFieldProps.InputProps.inputProps = {
        mask,
        inputStyle: textFieldProps.inputProps.style,
      };
    }

    if (customInputType && customInputType === 'geo') {
      textFieldProps.InputProps.inputComponent = GeosuggestInput;
      textFieldProps.InputProps.inputProps = customInputProps;
    } else if (customInputType && customInputType === 'select') {
      // TODO: instead of relying on another component
      // TextField has a new feature for acting as select
      textFieldProps.InputLabelProps.shrink = true;
      textFieldProps.InputLabelProps.style.top = -5;
      textFieldProps.InputProps.inputComponent = MaterialSelectInput;
      textFieldProps.InputProps.inputProps = customInputProps;
    }

    return <MUITextField {...textFieldProps} />;
  }

  render() {
    const {
      autoFocus,
      inputRef,
      errorText,
      successText,
      labelI18n,
      multiline,
      ...props
    } = omit(this.props, [
      'debounced',
      'centered',
      'h',
      'theme',
      'placeholder',
      'placeholderI18n',
      'mask',
      'errorBorder',
      'customInputType',
      'customInputProps',
      'formikFieldHelpers',
      'mobile',
      'title',
      'customValue',
      'isCustomValueApplied',
      'setIsCustomValueApplied',
    ]);

    let subText = errorText || successText;
    if (errorText) {
      subText = <span className="js-inline-error">{subText}</span>;
    }
    // Force component to always be controlled
    const value = isNil(this.state.value) ? '' : this.state.value;

    const textFieldProps = {
      fullWidth: true,
      ...this.styles(),
      ...props,
      value,
      multiline,
      // docs says use inputRef but in reality Ref only works so I put both.
      ref: inputRef,
      inputRef,
      autoFocus,
      onChange: this.handleChange,
      onFocus: this.handleOnFocus,
      onBlur: this.handleOnBlur,
      placeholder: this.placeholder(),
      helperText: subText,
      className: this.cx({
        centered: this.props.centered,
        [this.props.theme]: this.props.theme,
        disabled: this.props.disabled,
        'success-text': successText,
        errorborder: this.props.errorBorder,
      }),
    };

    textFieldProps.InputLabelProps.shrink = this.state.focused || !!value;

    if (this.state.focused) {
      textFieldProps.InputLabelProps.focused = true;
    }

    if (errorText) {
      textFieldProps.FormHelperTextProps.error = true;
      textFieldProps.error = true;
    }

    if (this.isNumeric()) {
      // Ensure numeric keyboard triggers on iOS
      textFieldProps.type = 'tel';

      textFieldProps.pattern = '[0-9]*';
    }

    if (labelI18n) {
      textFieldProps.label = toI18n(labelI18n);
    }

    return this.renderWithContainer(textFieldProps);
  }
}
