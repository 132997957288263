import { useState } from 'react';

import { UsePastDueSubscriptionDialogProps } from './types';
import { useCurrentTierName } from './useCurrentTierName';
import { useHandleChangePaymentMethod } from './useHandleChangePaymentMethod';
import { useHandleClickOnClose } from './useHandleClickOnClose';
import { useHideCloseIcon } from './useHideCloseIcon';
import { useSanitizedPreviousTierName } from './useSanitizedPreviousTierName';

export const usePastDueSubscriptionDialog = ({
  VIEW,
  biller,
  previousTierName,
  onChangeToNewTier,
  currentLocationId,
  closePastDueDialog,
  currentLocationTier,
  selectedBillingCycle,
  isSubscribedAnnually,
  onChangePaymentMethod,
  isPastDueAfterDeadline,
  initialPastDueDialogViewKey,
  setIsBillingModalSourcePastDue,
  dismissPastDueSubscriptionModal,
}: UsePastDueSubscriptionDialogProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentViewKey, setCurrentViewKey] = useState(
    initialPastDueDialogViewKey
  );

  const CurrentView = VIEW[currentViewKey];

  const currentTierName = useCurrentTierName({ currentLocationTier });
  const sanitizedPreviousTierName = useSanitizedPreviousTierName({
    previousTierName,
  });
  const hideCloseIcon = useHideCloseIcon({
    currentViewKey,
    isPastDueAfterDeadline,
  });
  const handleClickOnClose = useHandleClickOnClose({
    currentViewKey,
    closePastDueDialog,
    dismissPastDueSubscriptionModal,
  });
  const handleChangePaymentMethod = useHandleChangePaymentMethod({
    biller,
    previousTierName,
    currentLocationId,
    onChangeToNewTier,
    closePastDueDialog,
    isSubscribedAnnually,
    selectedBillingCycle,
    onChangePaymentMethod,
    isPastDueAfterDeadline,
    setIsBillingModalSourcePastDue,
  });

  return {
    isLoading,
    CurrentView,
    setIsLoading,
    hideCloseIcon,
    currentViewKey,
    currentTierName,
    setCurrentViewKey,
    handleClickOnClose,
    handleChangePaymentMethod,
    sanitizedPreviousTierName,
  };
};
