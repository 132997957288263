export const DEFAULT_TIP_POLICY_PARAMS = {
  name: 'Pooligans',
  import_from_pos: true,
  calculation_method: 'hours_worked',
  frequency: 'pay_period',
};

export const MAX_LABEL_LENGTH = 140;

export const TIP_POOLING_TABLE_COLUMNS = [
  {
    name: 'name',
    width: MAX_LABEL_LENGTH,
  },
  {
    name: 'roles',
    width: MAX_LABEL_LENGTH,
  },
  {
    name: 'calculation_method',
    width: MAX_LABEL_LENGTH,
  },
  {
    name: 'frequency',
    width: MAX_LABEL_LENGTH,
  },
  {
    name: 'effective_date',
    width: MAX_LABEL_LENGTH,
  },
  {
    name: 'status',
    width: 60,
  },
];

export const STATUS_PILL_VARIANT = {
  enabled: 'success',
  disabled: 'mono',
  pending: 'warning',
};

export const MAX_LABEL_CONTENT_LENGTH = 15;

export const HOW_IT_WORKS_BASE_KEY =
  'tip_pooling_settings.auto_create_tip_pool.how_it_works';

export const ROLE_RECEIVER_TYPE = 'Organization::Role';

export const TALKDESK_FLOW_ID = '00277ccf1511459c95001240cfd80f09';
