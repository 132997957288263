import { useMemo } from 'react';

import { labelForPlanName } from '../util';

import { UseSanitizedPreviousTierNameProps } from './types';

export const useSanitizedPreviousTierName = ({
  previousTierName,
}: UseSanitizedPreviousTierNameProps) =>
  useMemo(
    () => (previousTierName ? labelForPlanName(previousTierName) : null),
    [previousTierName]
  );
