import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { InputAdornment, TextField } from '@mui/material';
import withCx, { CxProps } from 'fe-core/util/withCx';
import Icon from 'fe-design-base/atoms/Icon';
import Tooltip from 'fe-design-base/molecules/Tooltip';
import { typography } from 'fe-design-base/styles/typography';
import { borderRadius, gutters } from 'fe-design-base/styles/utils';

import { EVENT_ACTIONS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUx } from 'util/uxEvents';

import { shouldRenderTooltip } from './helpers';
import { TextInputProps } from './index.type';

const inputSx = {
  borderRadius: borderRadius.small,
};

const TextInput = forwardRef(
  (
    {
      id,
      name,
      type = 'text',
      inputMode = 'text',
      error,
      success,
      readOnly,
      disabled,
      placeholder,
      tabIndex,
      uxElement,
      onFocus,
      onBlur,
      label,
      CustomInput,
      CustomInputProps,
      CustomInputStyle = {},
      labelPosition,
      startIcon,
      endIcon,
      cx,
      cxEl,
      onKeyDown,
      dataTestId,
      textArea,
      textAreaHeight,
      maxLength,
      noTooltip,
      autoComplete,
      maxRows,
      minRows,
      ...rest
    }: TextInputProps & CxProps,
    ref: ForwardedRef<any>
  ) => {
    const trackUx = useTrackUx(
      useMemo(
        () => ({
          element: uxElement,
          field: label && typeof label === 'string' ? label : name,
        }),
        [label, name, uxElement]
      ) as any
    );

    /**
     handling autoFocus with shouldAutoFocus state allows for
     a single click into the text field
     */
    const [shouldAutoFocus, setShouldAutoFocus] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const textRef = useRef<HTMLInputElement | null>(null);
    const inputFieldRef = useRef<HTMLInputElement | null>(null);

    const textWidth = textRef?.current?.offsetWidth ?? 0;
    useEffect(() => {
      const inputContainerWidth = inputFieldRef?.current?.clientWidth ?? 0;

      if (
        shouldRenderTooltip({
          textWidth,
          startIcon: !!startIcon,
          endIcon: !!endIcon,
          inputContainerWidth,
          isFocused,
          type,
          textArea,
          noTooltip,
        })
      )
        setShowTooltip(true);
    }, [
      type,
      showTooltip,
      isFocused,
      startIcon,
      endIcon,
      textWidth,
      noTooltip,
      textArea,
    ]);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    // TODO: DB https://joinhomebase.atlassian.net/browse/FE-2199
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const handleClickPasswordVisible = useCallback(e => {
      // This prevents formik form submission on hitting enter
      e.preventDefault();

      const isInputFocused = inputFieldRef?.current
        ?.querySelector('input')
        ?.hasAttribute('autofocus');

      // Check is to prevent password visibility from toggling when user
      // hits enter when focused in the field
      if (!isInputFocused) setIsPasswordVisible(currState => !currState);
    }, []);

    const getIconComponent = (icon: string | React.ReactNode) => {
      if (typeof icon === 'string') {
        return <Icon iconName={icon} size="medium" color="mono500" />;
      }
      return icon;
    };

    const startAdornment = useMemo(() => {
      if (startIcon && !readOnly)
        return (
          <InputAdornment sx={{ marginLeft: '14px' }} position="start">
            {getIconComponent(startIcon)}
          </InputAdornment>
        );
    }, [readOnly, startIcon]);

    const endAdornment = useMemo(() => {
      if (type === 'password') {
        return (
          <InputAdornment position="end">
            <button onClick={handleClickPasswordVisible}>
              <Icon
                size="medium"
                className={cxEl('password-icon')}
                iconName={isPasswordVisible ? 'Hide' : 'Show'}
                color="purple500"
              />
            </button>
          </InputAdornment>
        );
      }
      if (endIcon && !readOnly) {
        return (
          <InputAdornment position="end">
            {getIconComponent(endIcon)}
          </InputAdornment>
        );
      }
      return undefined;
    }, [
      cxEl,
      endIcon,
      handleClickPasswordVisible,
      isPasswordVisible,
      type,
      readOnly,
    ]);

    const handleFocus = useCallback(
      // TODO: DB https://joinhomebase.atlassian.net/browse/FE-2199
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      e => {
        if (uxElement)
          trackUx(EVENT_ACTIONS.FIELD_FOCUSED, TRACK_ACTION_TYPES.FOCUS);
        onFocus?.(e);
        setShouldAutoFocus(true);
        setIsFocused(true);
        setShowTooltip(false);
      },
      [onFocus, trackUx, uxElement]
    );

    const handleBlur = useCallback(
      // TODO: DB https://joinhomebase.atlassian.net/browse/FE-2199
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      e => {
        setIsFocused(false);
        setShouldAutoFocus(false);
        onBlur?.(e);
      },
      [onBlur]
    );

    const getInputHeight = () => {
      if (textArea) {
        return `${textAreaHeight}px`;
      }
      return '40px';
    };

    const inputProps = {
      tabIndex,
      maxLength,
      inputMode,
      style: {
        boxSizing: 'border-box',
        padding: textArea
          ? `0px ${gutters[12]}`
          : `${gutters[8]} ${gutters[12]}`,
        width: endAdornment ? `calc(100% - ${gutters[32]})` : undefined,
        ...typography.body,
        paddingLeft: readOnly && !disabled ? '0px' : '12px',
        backgroundColor: readOnly && !disabled && 'transparent',
        textOverflow:
          cx().includes('FDBNumericInput') || cx().includes('FDBPhoneInput')
            ? 'initial'
            : 'ellipsis',
      } as CSSProperties,
      'data-testid': dataTestId,
    };

    let rowProps;
    const showResizeableTextArea = textArea && (minRows || maxRows);

    if (!showResizeableTextArea) inputProps.style.height = getInputHeight();

    if (showResizeableTextArea) rowProps = { maxRows, minRows };
    else rowProps = { rows: textArea ? 2 : undefined };

    return (
      <>
        <Tooltip content={showTooltip ? rest?.value?.toString() : ''}>
          <TextField
            autoComplete={autoComplete}
            autoFocus={shouldAutoFocus}
            id={id}
            onKeyDown={onKeyDown}
            className={cx({ readOnly })}
            aria-describedby={`${id}-helper`}
            variant="outlined"
            name={name}
            error={error}
            placeholder={placeholder}
            inputRef={ref}
            ref={inputFieldRef}
            type={type === 'password' && isPasswordVisible ? 'text' : type}
            fullWidth
            multiline={textArea}
            inputProps={inputProps}
            InputProps={{
              startAdornment: !textArea && startAdornment,
              endAdornment: !textArea && endAdornment,
              readOnly: Boolean(readOnly || disabled),
              inputComponent: CustomInput,
              ...(CustomInputProps ? { inputProps: CustomInputProps } : {}),
            }}
            aria-disabled={disabled}
            sx={{ ...inputSx, ...CustomInputStyle }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...rowProps}
            {...rest}
          />
        </Tooltip>

        {/* Invisible span for measuring the width of text inside the TextInput */}
        <span
          ref={textRef}
          style={{
            border: '1px solid',
            marginLeft: '11px',
            fontSize: '16px',
            position: 'absolute',
            left: 0,
            top: 36,
            whiteSpace: 'nowrap',
            visibility: 'hidden',
          }}
        >
          {rest.value}
        </span>
      </>
    );
  }
);

export default withCx<TextInputProps>('FDBTextInput')(TextInput);
