import '../NBKShared.scss';

import React, { useCallback } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Button from 'fe-design-base/molecules/Button/Button';
import Link from 'fe-design-base/molecules/Link';

import NBKDialogWrapper from 'features/earlyLife/NewBusinessKit/components/NBKDialogWrapper';
import { CLICK_TO_CALL_VIEW_KEY as VIEW_KEY } from 'features/monetization/ClickToCallDialog/constants';
import {
  ViewType,
  ViewTypeProps,
} from 'features/monetization/ClickToCallDialog/views/types';

import Image from 'components/Image';

import { cxHelpers } from 'util/className';
import { formatPhoneNumber } from 'util/formatter';
import { toI18n } from 'util/i18n';
import { EVENT_CATEGORIES } from 'util/tracking_constants';

const { cxEl } = cxHelpers('NBK');

const NBKWithinBusinessHoursSuccess: ViewType = ({
  values,
  setReload,
  setCurrentViewKey,
  closeDialogWithoutTracking,
}: ViewTypeProps) => {
  const handleOnClickPrimary = useCallback(() => {
    closeDialogWithoutTracking();
  }, [closeDialogWithoutTracking]);

  const handleOnClickLink = useCallback(() => {
    setCurrentViewKey(VIEW_KEY.nbk_within_business_hours_request);
    setReload(true);
  }, [setReload, setCurrentViewKey]);

  return (
    <NBKDialogWrapper
      eventCategory={EVENT_CATEGORIES.REQUEST_CALLBACK_SUCCESS_MODAL}
      duringBusinessHours
    >
      <Box
        mr={24}
        ml={24}
        mt={24}
        mb={4}
        row
        justifyContentCenter
        alignItemsCenter
      >
        <Box mr={16}>
          <Image
            className={cxEl('img')}
            src={require('assets/circle_check.svg')}
          />
        </Box>
        <Box pt={14}>
          <Text variant="heading2">
            {toI18n('new_business_kit.within_business_hours_success.header')}
          </Text>
        </Box>
      </Box>
      <hr />
      <Box mb={24}>
        <Text variant="bodyBold" asHTML>
          {toI18n('new_business_kit.within_business_hours_success.title', {
            props: { phoneNumber: formatPhoneNumber(values.phone) },
          })}
        </Text>
      </Box>
      <Box mb={24}>
        <Text variant="body">
          {toI18n(
            'new_business_kit.within_business_hours_success.missed_call.copy'
          )}{' '}
          <Link
            endIcon="ArrowRight"
            onClick={handleOnClickLink}
            uxElement="request_again"
          >
            {toI18n(
              'new_business_kit.within_business_hours_success.missed_call.link'
            )}
          </Link>
        </Text>
      </Box>
      <Box hright>
        <Button onClick={handleOnClickPrimary} uxElement="done">
          {toI18n(
            'monetization.click_to_call.within_business_hours.success.cta'
          )}
        </Button>
      </Box>
    </NBKDialogWrapper>
  );
};

export default NBKWithinBusinessHoursSuccess;
