import React, { useCallback } from 'react';
import useId from '@mui/material/utils/useId';
import FormField, { FormFieldProps } from 'fe-design-base/atoms/FormField';
import FDBDatePicker from 'fe-design-base/organisms/FDBDatePicker';
import { Field, FormikValues, useFormikContext } from 'formik';

export type FDBDatePickerFieldProps = Omit<
  FormFieldProps,
  'children' | 'size'
> & {
  blockDatesBefore?: string;
  blockDatesAfter?: string;
  value?: string | null;
};

const FDBDatePickerField = ({
  name,
  label,
  inputId,
  width,
  fullWidth,
  labelPosition,
  labelWidth,
  shrink,
  disabled,
  readOnly,
  helperText,
  hasAsterisk,
  successText,
  blockDatesBefore,
  blockDatesAfter,
  ...inputProps
}: FDBDatePickerFieldProps) => {
  const fieldId = useId();
  const { touched, errors, setFieldTouched } = useFormikContext<FormikValues>();
  const error = touched?.[name] && errors?.[name] && !disabled;

  const handleBlur = useCallback(() => {
    setFieldTouched(name, true);
  }, [name, setFieldTouched]);

  return (
    <FormField
      label={label}
      labelPosition={labelPosition}
      labelWidth={labelWidth}
      name={name}
      width={width}
      fullWidth={fullWidth}
      shrink={shrink}
      disabled={disabled}
      readOnly={readOnly}
      inputId={inputId || fieldId}
      helperText={helperText}
      successText={successText}
      hasAsterisk={hasAsterisk}
      labelTopShift="-30px"
    >
      <Field
        as={FDBDatePicker}
        name={name}
        label={label}
        disabled={disabled}
        readOnly={readOnly}
        inputId={inputId || fieldId}
        onBlur={handleBlur}
        error={error}
        blockDatesBefore={blockDatesBefore}
        blockDatesAfter={blockDatesAfter}
        {...inputProps}
      />
    </FormField>
  );
};

export default FDBDatePickerField;
