import './Icon.scss';

// TODO: remove ts-ignore https://joinhomebase.atlassian.net/browse/FE-2180
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { FC, useId } from 'react';
import withCx, { CxProps } from 'fe-core/util/withCx';
import * as mainIcons from 'fe-design-base/assets/icons/main';
import Box from 'fe-design-base/atoms/Box';
import { Props as BoxProps } from 'fe-design-base/atoms/Box/interface';
import { PaletteProp } from 'fe-design-base/types';

export const iconNames = [...Object.keys(mainIcons)] as const;
export type IconName = (typeof iconNames)[number];
export type IconSize = keyof typeof sizes;

export type SVGIconProps = {
  alt?: string;
  id: string;
  screenReadable?: boolean;
};

export type IconProps = {
  iconName: IconName;
  size?: IconSize;
  color?: PaletteProp | undefined;
  onClick?: (event: any) => void;
  alt?: string;
  screenReadable?: boolean;
} & Omit<BoxProps, 'children' | 'w' | 'h'>;

export const sizes = {
  xsmall: 12,
  small: 16,
  medium: 20,
  large: 24,
  xlarge: 32,
} as Record<string, number>;

const Icon = ({
  size = 'medium',
  color,
  iconName,
  onClick,
  cx,
  cxEl,
  alt,
  screenReadable,
  ...boxProps
}: IconProps & CxProps) => {
  const icons = mainIcons as Record<string, FC<SVGIconProps>>;
  const IconComponent: React.FC<SVGIconProps> = icons[iconName];
  const id = useId();

  return (
    <Box
      onClick={onClick}
      className={cx({ pointer: !!onClick, [iconName]: true })}
      vcenter
      hcenter
      color={color}
      w={sizes[size]}
      h={sizes[size]}
      {...boxProps}
    >
      <IconComponent alt={alt} id={id} screenReadable={screenReadable} />
    </Box>
  );
};

export default withCx<IconProps>('FDBIcon')(Icon);
