import React, { memo } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';
import PropTypes from 'prop-types';

const Message = ({ title, iconName, description, iconColor }) => (
  <Box column gap={6}>
    <Text variant="bodySmBold">{title}</Text>
    <Box row gap={12}>
      <Icon iconName={iconName} color={iconColor} />
      <Text variant="bodySm">{description}</Text>
    </Box>
  </Box>
);

Message.defaultProps = {
  iconName: 'Complete',
  iconColor: 'success300',
};

Message.propTypes = {
  title: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default memo(Message);
