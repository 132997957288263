import { postJSON } from 'api/fetch';

import * as flashNotice from 'util/flashNotice';
import { toI18n } from 'util/i18n';

import { DOWNGRADE_REQUEST_CALLBACK, REQUEST_CALLBACK } from './constants';

export const postRequestCallback = payload =>
  postJSON(REQUEST_CALLBACK, payload).catch(() =>
    flashNotice.error(toI18n('errors.generic'))
  );

export const postDowngradeRequestCallback = payload =>
  postJSON(DOWNGRADE_REQUEST_CALLBACK, payload).catch(error => {
    flashNotice.error(toI18n('errors.generic'));
    throw error;
  });
