import React, { memo } from 'react';
import Box from 'fe-design-base/atoms/Box';
import Text from 'fe-design-base/atoms/Text';
import Link from 'fe-design-base/molecules/Link';
import PropTypes from 'prop-types';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';

import { I18N_PATH } from '../constants';

const { cxEl } = cxHelpers('AddEmployeeSuccessModal');

const UserInfo = ({ title, userId, onClickUser }) => (
  <Box alignItemsCenter column mt={16} mb={36}>
    <Text textAlign="center" variant="heading2">
      {title}
    </Text>

    <Box mt={8}>
      <Link
        size="small"
        className={cxEl('user')}
        linkTo={`/team/${userId}`}
        onClick={onClickUser}
        endIcon="ArrowRight"
      >
        {toI18n(`${I18N_PATH}.view_profile`)}
      </Link>
    </Box>
  </Box>
);

UserInfo.propTypes = {
  isRehired: PropTypes.bool,
  title: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  onClickUser: PropTypes.func.isRequired,
};

export default memo(UserInfo);
